import React, { useEffect, useState } from 'react';
import DropDownTreeView from "../../../../components/controls/dropDownTreeView.jsx";
import { TextBox } from "@progress/kendo-react-inputs";

export function TemplateName({ template, fields, onEdit }) {
    const [currentTemplate, setCurrentTemplate] = useState();
    const [fieldsList, setFieldsList] = useState([]);

    useEffect(() => {
        setCurrentTemplate(template)
        setFieldsList(fields);
    }, [template]);

    useEffect(() => {
        setFieldsList(fields);
    }, [fieldsList]);

    if (!currentTemplate)
        return;

    const fieldVariableHandler = (item) => {
        setFieldsList(null);

        var template = currentTemplate;
        template.fileNameTemplate += item.innerName;
        setCurrentTemplate(template);
        onEdit(template);
    };

    const templateHandler = (item) => {
        var template = currentTemplate;
        template.fileNameTemplate = item.value;
        setCurrentTemplate(template);
        onEdit(template);
    };

    return <div className="k-d-flex-row">
        <TextBox value={currentTemplate.fileNameTemplate}
            style={{ width: "100%" }}
            onChange={templateHandler} />
        <DropDownTreeView key="fieldTemplatesList"
            selectedId={null}
            style={{ width: "50%" }}
            treeData={fieldsList}
            selectedId={-1}
            selectionChanged={fieldVariableHandler} />
    </div>
}
