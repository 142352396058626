import React from "react";
import { emailValidation } from "../../../utils/systemConstants.jsx";
import { Button, DropDownButton } from "@progress/kendo-react-buttons";
import { Input } from "@progress/kendo-react-inputs";

export default function EmailControl({
    value,
    item,
    field,
    isEdit,
    isContact,
    required,
    access,
    stateList,
    sources,
    isAutocompleted,
    changedValue }) {
    var state;
    var owner;
    let id = 0;
    let email;
    let comment;
    if (value) {
        const values = value.split("&PP;");
        [email, comment, state, owner] = values;
        state = parseInt(state);
        owner = parseInt(owner);

        if (isNaN(state)) state = 0;
        if (isNaN(owner)) owner = 0;
    } else if (item) {
        id = item.id ? item.id : 0;
        state = item.state ? item.state : 0;
        owner = item.owner ? item.owner : 0;
        email = item.email;
        comment = item.comment;
    }

    var accessLocal = access
        ? { ...access }
        : { add: true, change: true, owner: true, state: true, stateMain: true, delete: true, main: true, mainAuto: true };

    const ownerListLocal = sources.clientTypes ? sources.clientTypes : [];
    const emailLink = email ? `mailto:${email}` : "";

    const stateListLocal = stateList
        ? stateList
        : sources.accessibleEmailsStatuses && sources.accessibleEmailsStatuses.length > 0
            ? sources.emailStates.filter((t) => sources.accessibleEmailsStatuses.includes(t.id) || t.id == 0)
            : sources.emailStates;

    const selectedState = stateListLocal.find((e) => e.id === state);
    const selectedOwner = ownerListLocal.find((e) => e.id === owner);

    const selectedStateName = selectedState && state > 0 ? selectedState.name : "";
    const selectedOwnerName = selectedOwner && owner > 0 ? selectedOwner.name : "";

    if (!accessLocal.main && accessLocal.mainAuto && !isAutocompleted) {
        accessLocal.main = true;
        accessLocal.mainAuto = false;
    }

    var valid = !(email != null && email.length > 0 && !emailValidation.test(email.toLowerCase()) ||
        required && (email == null || email.length === 0));
    let clear = null;
    if (isContact &&
        (accessLocal.delete || id <= 0) &&
        (selectedOwner || selectedState || email || comment)) {
        clear = <Button className="dm-m-left" icon="delete" onClick={clearField} />;
    }

    var notEditVaue = null;
    if (!isEdit || !accessLocal.change) {
        notEditVaue = valid
            ? <a key="emailLink" className="dm-break-char" href={emailLink}>{email}</a>
            : <span key="emailText">{email}</span>;
    }

    if (!isEdit) {
        const text = [notEditVaue];
        if (comment)
            text.push(<span key="emailComment">{`, ${comment}`}</span>);
        if (selectedStateName)
            text.push(<span key="emailState" className="badge bg-success dm-m-left">{selectedStateName}</span>);
        if (selectedOwnerName)
            text.push(<span key="emailOwner" className="badge bg-secondary dm-m-left">{selectedOwnerName}</span>);

        return <div>{text}</div>;
    }

    var stateStyle = "dm-m-right";
    if (selectedStateName)
        stateStyle = stateStyle + (selectedState.isPositive ? " dm-positive" : " dm-negative");
    if (item && item.isMain)
        stateStyle = stateStyle + " dm-field-main";

    const ownerStyle = selectedOwnerName
        ? "dm-m-right dm-positive"
        : "dm-m-right";

    let stateControl = null;
    if (accessLocal.state) {
        stateControl = <DropDownButton
            onItemClick={e => stateChanged(e)}
            title={selectedStateName}
            icon="stop"
            items={stateListLocal.map((item) => ({ ...item, selected: item.id === state }))}
            textField="name"
            className={stateStyle} />;
    }

    let ownerControl = null;
    if (accessLocal.owner) {
        ownerControl = <DropDownButton
            onItemClick={e => ownerChanged(e)}
            title={selectedOwnerName}
            icon="user"
            items={ownerListLocal.map((item) => ({ ...item, selected: item.id === owner }))}
            textField="name"
            className={ownerStyle} />;
    }

    let mainDisabled = !accessLocal.main && (id > 0 || accessLocal.mainAuto);
    return <div className="dm-size-100">
        <div className="dm-wrapper">
            {stateControl}
            {ownerControl}
            {accessLocal.change
                ? <Input
                    className="dm-wrapper-content"
                    valid={valid}
                    disabled={mainDisabled}
                    onChange={e => valueChanged(e, "email")}
                    defaultValue={email} />
                : <div className="dm-wrapper-content">{notEditVaue}</div>}
            {clear}
        </div>
        <Input
            style={{ width: "100%" }}
            className="dm-m-top"
            placeholder={window.captions.Comment}
            onChange={e => valueChanged(e, "comment")}
            defaultValue={comment} />
    </div>;


    function valueChanged(e, property) {
        changedValue({
            fieldId: field,
            item: id,
            property: property,
            value: e.value
        });
    }

    function stateChanged(e) {
        if (!accessLocal.stateMain && ((e.item.id == sources.emailState || state == sources.emailState))) {
            alert(window.captions.ChangeMainStateInPhoneAddres);
            return;
        }

        changedValue({
            fieldId: field,
            item: id,
            property: "state",
            value: e.item.id,
            valueName: e.item.name
        })
    }

    function ownerChanged(e) {
        changedValue({
            fieldId: field,
            item: id,
            property: "owner",
            value: e.item.id,
            valueName: e.item.name
        })
    }

    function clearField() {
        changedValue({
            fieldId: field,
            item: id,
            isClear: true,
            value: null
        });
    }
}