import React, { useState, useEffect, useRef } from 'react';
import LoadingMask from "../../../../../components/loadingMask.jsx";
import WizardFunction from "../../wizardFunctions.jsx";
import { fetchPost } from '../../../../../utils/requestsHelper.jsx';
import { useLocation } from "react-router-dom";
import { Sortable } from '@progress/kendo-react-sortable';
import { SvgIcon } from "@progress/kendo-react-common";
import { arrowsMoveIcon } from "@progress/kendo-svg-icons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

export function VariableSortingTab({ variable, onEdit }) {
    const location = useLocation();
    const isReports = location.pathname.includes("reports");

    const [currentVariable, setCurrentVariable] = useState();
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(true);
    const [fields, setFields] = useState([]);

    const getTemplatesRequest = () => {
        return {
            sourceType: currentVariable.sourceType,
            fieldSourceType: currentVariable.fieldSourceType,
            selected: currentVariable.sourceItems,
            isReport: isReports,
            isOnlySelected: true
        }
    }

    useEffect(() => {
        setCurrentVariable(variable.item);
        if (currentVariable)
            getVariablesTemplates(getTemplatesRequest());
    }, [variable.item, currentVariable]);

    const getBaseItemStyle = (isActive) => ({
        height: 40,
        lineHeight: "38px",
        textAlign: "start",
        fontSize: "14px",
        outline: "none",
        border: "1px solid #dcdcdc",
        cursor: "move",
        display: "block",
        borderColor: isActive ? "var(--kendo-color-success)" : "transparent",
    });

    const onCloseError = () => {
        setError(null);
        setCurrentVariable(template);
    };

    if (error) {
        return <Dialog width={400}>
            <MessageMask inline error={error} />
            <DialogActionsBar>
                <Button onClick={() => { onCloseError() }}>{window.captions.Close}</Button>
            </DialogActionsBar>
        </Dialog>;
    }


    const sortableItemUI = (props) => {
        const { attributes, dataItem, forwardRef, isActive, style } = props;

        return (
            <div ref={forwardRef} {...attributes}
                className="k-card k-card-type-rich"
                style={{ ...getBaseItemStyle(isActive), ...style }}>
                <SvgIcon icon={arrowsMoveIcon} size="large" className="dm-m-right" />
                {dataItem.text}
            </div>
        );
    }

    if (isLoaded)
        return <div style={{ height: "300px" }}><LoadingMask /></div>;

    const onDragOver = (event) => {
        setFields(event.newState);

        var fieldIds = event.newState.map((field) => {
            return field.id
        });

        var newVariable = currentVariable;
        newVariable.sourceItems = fieldIds;
        setCurrentVariable(newVariable);
        onEdit({
            index: variable.index,
            item: newVariable
        });
    };

    return (
        <Sortable idField="id"
            data={fields}
            itemUI={sortableItemUI}
            onDragOver={onDragOver}
        />);

    function getVariablesTemplates(request) {
        setIsLoaded(true);
        fetchPost(`${window.constants.retrieveSourceItems}`,
            request,
            model => {
                if (model) {
                    var data = model.map((field) => {
                        return {
                            id: field.id,
                            text: field.name,
                        }
                    })

                    setFields(WizardFunction.sortObjectsById(data, variable.item.sourceItems))
                    setIsLoaded(false);
                } else {
                    showError(window.captions.LoadDataError);
                }
            },
            ex => {
                showError(ex);
            }
        );
    }

    function showError(ex) {
        setError(ex);
        setIsLoaded(false);
    }
}