import React from "react";
import { createPortal } from 'react-dom';

export default function GridLoadingPanel({ gridId }) {
    const loadingPanel = <div className="k-loading-mask">
        <span className="k-loading-text">{window.captions.LoadingData}</span>
        <div className="k-loading-image"></div>
        <div className="k-loading-color"></div>
    </div>;

    const gridContent = gridId && document && window[gridId].querySelector(`.k-grid-content`);
    return gridContent ? createPortal(loadingPanel, gridContent) : loadingPanel;
}