import React from "react";
import { useState, useEffect } from 'react';
import { Button } from "@progress/kendo-react-buttons";
import MessageMask from "../../components/messageMask.jsx";
import CardSaveFunctions from "../cards/cardSaveFunctions.jsx";
import FieldsControl from "../cards/controls/fieldsControl.jsx";
import { DateTimePicker } from "@progress/kendo-react-dateinputs";
import { fetchGet, fetchPost } from "../../utils/requestsHelper.jsx";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import DropDownTreeView from "../../components/controls/dropDownTreeView.jsx";
import MultilineTextArea from "../../components/controls/multilineTextArea.jsx";
import ValidationMessage from "../../components/validationMessage.jsx";
import { renderPair } from "../cards/cardRenderFunctions.jsx";
import { getFormatDateTime, dateTimeWidth } from "../../utils/systemConstants.jsx";

export default function DialogRecordRating({ canEdit, ratingId, cardId, onClose }) {
    const [sources, setSources] = useState(null);
    const [questionnaires, setQuestionnaires] = useState([]);
    const [questionnaireGroups, setQuestionnaireGroups] = useState([]);
    const [selectedQuestionnaire, setSelectedQuestionnaire] = useState(null);
    const [ratingDate, setRatingDate] = useState(new Date());
    const [executors, setExecutors] = useState([]);
    const [executor, setExecutor] = useState(null);
    const [comment, setComment] = useState("");
    const [canSave, setCanSave] = useState(false);
    const [pending, setPending] = useState(null);
    const [error, setError] = useState(null);
    const [validate, setValidate] = useState(false);

    useEffect(() => fetchData(), []);

    const title = window.captions.QuestionnaireQualityControl;
    if (pending || error) {
        return <Dialog title={title} onClose={onClose} width={550}>
            <MessageMask inline text={pending} error={error} />
        </Dialog>;
    }
    const items = [];

    renderPair(items,
        window.captions.MainTabAdministrationQuestionnaireQC,
        "questionnaire",
        () => <DropDownTreeView
            treeData={questionnaires}
            selectedId={selectedQuestionnaire}
            selectionChanged={changeQuestionnaire}
            style={{ width: "100%" }} />);

    if (selectedQuestionnaire) {
        const questionnaireFields =
            questionnaireGroups.map((item, g) => <PanelBarItem title={item.name} key={g} expanded={false}>
                <div className="dm-filter-group dm-container">
                    <FieldsControl id={item.id}
                        fields={item.fields}
                        sources={sources}
                        isEditMode={true}
                        setFields={setEntityFields} />
                </div>
            </PanelBarItem>
            );

        items.push(
            <div key="questionnaireFields" style={{ padding: "15px 0", width: "100%" }}>
                <PanelBar expandMode="multiple">
                    {questionnaireFields}
                </PanelBar>
            </div>);

        renderPair(items,
            window.captions.Executor,
            "executor",
            () => <DropDownTreeView
                treeData={executors}
                selectedId={executor}
                selectionChanged={changeExecutor}
                style={{ width: "100%" }} />);

        renderPair(items,
            window.captions.RatinglDateTime,
            "ratingDate",
            () => <DateTimePicker
                onChange={changeRatingDate}
                value={ratingDate ? new Date(ratingDate) : ""}
                width={dateTimeWidth}
                formatPlaceholder={getFormatDateTime()} />);

        renderPair(items,
            window.captions.Comment,
            "comment",
            () => <MultilineTextArea changeText={changeComment} text={comment} />);
    }

    const saveButton = [];
    if (canSave) {
        saveButton.push(
            <Button key="setMaxScore" onClick={() => setMaxScore(selectedQuestionnaire)}>{window.captions
                .SetMaxScore}</Button>);
        saveButton.push(
            <Button key="saveClose" onClick={() => save(true)}>{window.captions.SaveNstop}</Button>);
        if (canEdit)
            saveButton.push(
                <Button themeColor="primary" key="save" iconClass="dm-i dm-i-save" onClick={() => save(false)
                }>
                    {window.captions.Save}
                </Button>);
    }

    let modal = "";
    if (validate) {
        modal = <ValidationMessage key="validateDialog" close={() => setValidate(false)} text={validate} />;
    }

    return [
        <Dialog title={title} key="dialogRecordRaiting" onClose={onClose} width={700} style={{
            maxHeight: 650
        }}>
            <div className="dm-container dm-no-padding">
                {items}
            </div>
            <DialogActionsBar>
                {saveButton}
            </DialogActionsBar>
        </Dialog>,
        modal
    ];

    function fetchData() {
        setPending(window.captions.LoadingData);
        setError(null);
        fetchGet(`${window.constants.getRecordRatingModel}/${ratingId}`,
            (data) => {
                setQuestionnaires(data.questionnaires);
                setSelectedQuestionnaire(data.questionnaireId > 0
                    ? data.questionnaireId
                    : null);
                setSources(data.sources);
                setExecutors(data.sources.executors);
                setExecutor(data.authorId);
                setQuestionnaireGroups(data.questionnaireGroups);
                setRatingDate(data.ratingDateTime ? data.ratingDateTime : new Date());
                setComment(data.comment);
                setPending(null);
                setError(null);
            },
            (ex) => {
                setError(ex);
                setPending(null);
            });
    }

    function fetchQuestionnaire(questId) {
        if (isNaN(questId) || questId <= 0)
            return;

        setPending(window.captions.LoadingData);
        fetchGet(`${window.constants.getRatingQuestionnaire}/${questId}/${false}`,
            (data) => {
                setQuestionnaireGroups(data);
                setPending(null);
                setError(null);
            },
            (ex) => {
                setError(ex);
                setPending(null);
            });
    }

    function changeQuestionnaire(e) {
        fetchQuestionnaire(e.value);
        setSelectedQuestionnaire(e.value);
        setCanSave(true);
    }

    function setEntityFields(fields, id) {
        const groups = questionnaireGroups;
        const group = groups.find(i => i.id === id);
        if (!group)
            return;
        group.fields = fields;
        setQuestionnaireGroups(groups);
        setCanSave(true);
    }

    function changeExecutor(e) {
        setExecutor(e.value);
        setCanSave(true);
    }

    function changeRatingDate(e) {
        setRatingDate(e.value);
        setCanSave(true);
    }

    function changeComment(e) {
        setComment(e.value);
        setCanSave(true);
    }

    function save(isSaveClose) {
        if (!comment || comment === "") {
            alert(window.captions.CommentRequired);
            return;
        }

        if (!ratingDate) {
            alert(window.captions.NotificationEnterRatingDate);
            return;
        }

        if (!selectedQuestionnaire) {
            alert(window.captions.QuestionnaireRequired);
            return;
        }

        if (!executor) {
            alert(window.captions.FillAllRequiredFields);
            return;
        }

        const saveModel = {
            id: ratingId,
            callRecordsId: cardId,
            questionnaireId: selectedQuestionnaire,
            questionnaireGroups: [],
            ratingDateTime: ratingDate,
            authorId: executor,
            comment: comment,
            canEdit: !isSaveClose
        };

        var errorFields = [];
        for (let item of questionnaireGroups) {
            errorFields = CardSaveFunctions.validateRequired(item.fields, null, {});
            if (errorFields.length > 0)
                break;

            const group = CardSaveFunctions.renderValues(item.fields);
            group.id = item.id;
            group.ignoreFieldsWeight = item.ignoreFieldsWeight;
            saveModel.questionnaireGroups.push(group);
        }

        if (errorFields.length > 0) {
            setPending(null);
            setValidate(errorFields);
            return;
        }

        setPending(window.captions.SavingData);
        setError(null);
        fetchPost(`${window.constants.saveRecordRating}`,
            saveModel,
            () => onClose(),
            () => onClose());
    }

    function fetchSetMaxScore(questId) {
        if (isNaN(questId) || questId <= 0)
            return;

        setPending(window.captions.LoadingData);
        setError(null);
        fetchGet(`${window.constants.getRatingQuestionnaire}/${questId}/${true}`,
            (data) => {
                setQuestionnaireGroups(data);
                setSelectedQuestionnaire(questId);
                setCanSave(true);
                setPending(null);
            },
            (ex) => {
                setError(ex);
                setPending(null);
            });
    }

    function setMaxScore(questId) {
        fetchSetMaxScore(questId);
    }
}