import React, { useState, useEffect } from 'react';
import ItemSelector from "../../../components/controls/itemSelector.jsx";
import LoadingMask from "../../../components/loadingMask.jsx";
import WizardFunction from "../sharedUi/wizardFunctions.jsx";

export function FieldFilteredTab({ fields, template, onEdit }) {
    const [currentTemplate, setCurrentTemplate] = useState();

    useEffect(() => {
        setCurrentTemplate(template);
    }, [template]);

    if (!currentTemplate) {
        return <div style={{ height: "300px" }}><LoadingMask /></div>;
    }

    const selectedItemsChanged = (selectedItems) => {
        var newTemplate = {
            ...currentTemplate,
            fields: selectedItems ? selectedItems.map(i => i.id) : []
        }
        setCurrentTemplate(newTemplate);
        onEdit(newTemplate);
    }

    return <div className="dm-container dm-no-padding">
        <ItemSelector
            items={WizardFunction.getFilteredFields(currentTemplate.fields, fields)}
            selectedItemsChanged={selectedItemsChanged} />
    </div>;
}