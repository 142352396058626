import React from "react";
import { Button, DropDownButton } from "@progress/kendo-react-buttons";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Input } from "@progress/kendo-react-inputs";
import { fetchPost } from "../../../utils/requestsHelper.jsx";
import ContactContextMenuControl from "./contactContextMenuControl.jsx";

export default function AccountControl({
    item,
    field,
    isEdit,
    isContact,
    required,
    canVerify,
    access,
    stateList,
    currencyList,
    isAutocompleted,
    changedValue,
    entityItem,
    verificationNewPhone,
    dialogConfirmVerification }) {
    var state;
    var currency;
    let id = 0;
    let number = "";
    let numberWithMask = "";
    let itemValid = true;
    if (item) {
        id = item.id ? item.id : 0;
        state = item.state ? item.state : 0;
        currency = item.currency ? item.currency : 0;
        number = item.eNumber;
        numberWithMask = item.numberWithMask;
        itemValid = item.valid;
    }

    var accessLocal = access
        ? { ...access }
        : { owner: true, state: true, delete: true, main: true, mainAuto: true };

    const displayCurrenty = window.systemConfig.hasAccountCurrency;
    const stateListLocal = stateList ? stateList : [];
    const currencyListLocal = currencyList ? currencyList : [];

    const selectedState = stateListLocal.find((e) => e.id === state);
    const selectedCurrency = currencyListLocal.find((e) => e.id === currency);

    const selectedStateName = selectedState && state > 0 ? selectedState.name : "";
    const selectedCurrencyName = selectedCurrency && currency > 0 ? selectedCurrency.name : "";

    if (!accessLocal.main && accessLocal.mainAuto && !isAutocompleted) {
        accessLocal.main = true;
        accessLocal.mainAuto = false;
    }

    if (!isEdit) {
        const text = [
            <span key="accountNumber">{numberWithMask}</span>
        ];
        if (selectedCurrencyName && displayCurrenty)
            text.push(<span key="accountCurrency">, {selectedCurrencyName}</span>);
        if (selectedStateName)
            text.push(<span key="accountState" className="badge bg-success dm-m-left">{selectedStateName}</span>);

        if (item && item.verified)
            text.push(<span key="verification" className="badge bg-info dm-m-left">{window.captions.Verified}</span>);

        return <div>{text}</div>;
    }

    var verifyControl = null;
    if (item && canVerify) {
        verifyControl = <ContactContextMenuControl item={item}
            entityItem={entityItem}
            field={field}
            verificationNewPhone={verificationNewPhone}
            dialogConfirmVerification={dialogConfirmVerification} />;
    }

    var stateStyle = "dm-m-right";
    if (selectedStateName)
        stateStyle = stateStyle + (selectedState.isPositive ? " dm-positive" : " dm-negative");
    if (item && item.isMain)
        stateStyle = stateStyle + " dm-field-main";

    let valid = true;
    if (required) {
        valid = number.length > 0;
    }
    if (valid)
        valid = itemValid;

    let clear = null;
    if (isContact &&
        (accessLocal.delete || id <= 0) &&
        (selectedCurrency || selectedState || number)) {
        clear = <Button className="dm-m-left" icon="delete" onClick={clearField} />;
    }

    let stateControl = null;
    if (accessLocal.state) {
        stateControl = <DropDownButton
            onItemClick={e => valueDropChanged(e, "state")}
            title={selectedStateName}
            icon="stop"
            items={stateListLocal.map((st) => ({ ...st, selected: st.id === state }))}
            textField="name"
            className={stateStyle} />;
    }

    let mainDisabled = !accessLocal.main && (id > 0 || accessLocal.mainAuto);
    let currencyControl = null;
    if (displayCurrenty) {
        currencyControl = <DropDownList data={currencyListLocal}
            disabled={mainDisabled}
            textField="name"
            dataItemKey="id"
            popupSettings={{ animate: false }}
            value={selectedCurrency}
            className="dm-m-right"
            style={{ width: "100px" }}
            onChange={e => valueListChanged(e, "currency")} />;
    }

    return <div className="dm-wrapper dm-size-100">
        {stateControl}
        {currencyControl}
        <Input
            className="dm-wrapper-content"
            item={id}
            field={field}
            valid={valid}
            disabled={mainDisabled}
            property="eNumber"
            onChange={numberChanged}
            defaultValue={number} />
        {clear}
        {verifyControl}
    </div>;

    function valueChanged(value) {
        changedValue({
            fieldId: field,
            item: id,
            property: "eNumber",
            value: value
        });
    }

    function valueDropChanged(e, property) {
        changedValue({
            fieldId: field,
            item: id,
            property: property,
            value: e.item.id,
            valueName: e.item.name
        })
    }

    function valueListChanged(e, property) {
        changedValue({
            fieldId: field,
            item: id,
            property: property,
            value: e.target.value.id,
            valueName: e.target.value.name
        })
    }

    function clearField() {
        changedValue({
            fieldId: field,
            item: id,
            isClear: true,
            value: null
        });
    }

    function numberChanged(e) {
        var number = e.value;
        if (item && window.systemConfig.accountNumberValidateFieldId > 0) {
            fetchPost(`${window.constants.validateField}/${window.systemConfig.accountNumberValidateFieldId}`,
                {
                    value: number,
                    optionalValues: {
                        contactType: item.type,
                        contactState: item.state
                    }
                },
                (success) => {
                    item.valid = success;
                    if (!success) {
                        e.value = "";
                    }

                    valueChanged(success ? e.value : "");
                },
                () => valueChanged(""));
        }
        else
            valueChanged(e.value);
    }
}