import { createSlice } from '@reduxjs/toolkit'
import searchReducerFunctions from "./searchReducerFunctions.jsx";
import { isMobileSize, getLayoutSize } from "../../utils/systemConstants.jsx";
import { fetchGet, fetchPost, fetchDelete } from '../../utils/requestsHelper.jsx';

export const searchFormSlice = createSlice({
    name: 'searchForm',
    initialState: {
        selected: null,
        groups: [],
        filters: [],
        isLoaded: false,
        errors: "",
        entity: "",
        isProcessing: false,
        isNameEdit: false,
        savedId: 0,
        reserveId: 0,
        validate: false,
        canSearchAllFields: false,
        denyEmptySearchFilter: false,
        blockCreateSearch: false,
        isMobile: isMobileSize(window.innerWidth, window.innerHeight),
        sizeMode: getLayoutSize(window.innerWidth, true)
    },
    reducers: {
        windowSizeChanged: (state) => {
            state.sizeMode = getLayoutSize(window.innerWidth, true);
            state.isMobile = isMobileSize(window.innerWidth, window.innerHeight);
        },
        filterProcessing: (state) => {
            state.isProcessing = true;
        },
        filterSaved: (state, action) => {
            state.isProcessing = false;
            state.savedId = action.payload;
            state.selected = null;
        },
        reserveSaved: (state, action) => {
            state.isProcessing = false;
            state.reserveId = action.payload;
        },
        renameMode: (state) => {
            state.isNameEdit = !state.isNameEdit;
        },
        rename: (state, action) => {
            searchReducerFunctions.rename(state, action.payload.value);
        },
        changeSearchAllValue: (state, action) => {
            searchReducerFunctions.changeSearchAllValue(state, action.payload.value);
        },
        filterSelected: (state, action) => {
            state.selected = action.payload;
        },
        comparerSelected: (state, action) => {
            searchReducerFunctions.changeFieldComparer(state,
                action.payload.field,
                action.payload.value.id);
        },
        changedValue: (state, action) => {
            const e = action.payload;
            searchReducerFunctions.changeFieldValue(state,
                e.field,
                e.value === undefined ? e.target.value : e.value);
        },
        changedValueFrom: (state, action) => {
            const e = action.payload;
            searchReducerFunctions.changeFieldValueFrom(state, e.field, e.value);
        },
        changedValueTo: (state, action) => {
            const e = action.payload;
            searchReducerFunctions.changeFieldValueTo(state, e.field, e.value);
        },
        changedWaterMarkValue: (state, action) => {
            const e = action.payload;
            searchReducerFunctions.changeFieldWaterMarkValue(state, e.field, e.value);
        },
        changedPeriodValueFrom: (state, action) => {
            const e = action.payload;
            searchReducerFunctions.changePeriodFilterValueFrom(state, e.field, e.value);
        },
        changedPeriodValueTo: (state, action) => {
            const e = action.payload;
            searchReducerFunctions.changePeriodFilterValueTo(state, e.field, e.value);
        },
        filterEditChildren: (state, action) => {
            const e = action.payload;
            searchReducerFunctions.changeFieldChildren(state, e.fieldId, e.value);
        },
        clearField: (state, action) => {
            searchReducerFunctions.clear(state, action.payload);
        },
        hideValidate: (state, action) => {
            state.validate = action.payload;
        },
        filterRemove: (state, action) => {
            var removeId = action.payload;
            if (removeId > 0) {
                const filters = state.filters;
                const index = filters.findIndex((e) => e.id === removeId);
                if (index >= 0) {
                    filters.splice(index, 1);
                    state.filters = filters;
                    state.selected = filters[0];
                }
            }
            state.isProcessing = false;
        },
        formLoaded: (state, action) => {
            state.savedId = 0;
            state.isLoaded = true;
            state.groups = action.payload.groups;
            state.filters = action.payload.filters;
            state.selected = action.payload.selected;
            state.entity = action.payload.entity;
            state.canSearchAllFields = action.payload.canSearchAllFields;
            state.denyEmptySearchFilter = action.payload.denyEmptySearchFilter;
            state.blockCreateSearch = action.payload.blockCreateSearch;
            state.errors = action.payload.errors;
        },
        formLoadedError: (state, action) => {
            state.errors = action.payload;
        }
    }
})

export const {
    windowSizeChanged,
    filterProcessing,
    filterSaved,
    reserveSaved,
    renameMode,
    rename,
    changeSearchAllValue,
    filterSelected,
    comparerSelected,
    changedValue,
    changedValueFrom,
    changedValueTo,
    changedWaterMarkValue,
    changedPeriodValueFrom,
    changedPeriodValueTo,
    filterEditChildren,
    clearField,
    hideValidate,
    filterRemove,
    formLoaded,
    formLoadedError
} = searchFormSlice.actions

export const formLoad = (id, entity, state) => (dispatch) => {
    if (!id)
        id = 0;

    if (state.selected && state.entity === entity) {
        var selected = state.filters.find((e) => e.id == id);
        if (!selected)
            selected = state.filters[0];

        dispatch(filterSelected(selected));
        return;
    }

    fetchGet(`${window.constants.searchForm}/${entity}/${id}`,
        data => {
            var selected = data.userFilters.find((e) => e.id === data.id);
            if (selected)
                selected.searchAllFieldsValue = "";
            else
                selected = data.userFilters[0];

            dispatch(formLoaded({
                groups: data.filterGroups,
                filters: data.userFilters,
                selected: selected,
                entity: entity,
                canSearchAllFields: data.canSearchAllFields,
                denyEmptySearchFilter: data.denyEmptySearchFilter,
                blockCreateSearch: data.blockCreateSearch,
                errors: data.accessError
            }));
        },
        ex => dispatch(formLoadedError(ex))
    );
}

export const save = (model, denyEmpty) => (dispatch) => {
    if (denyEmpty && !model.searchAllFieldsValue && (!model.items || model.items.length === 0)) {
        dispatch(hideValidate(window.captions.AddEmpryRowError));
        return;
    }

    if (!model.name || model.name.trim() === "") {
        dispatch(hideValidate(window.captions.MessageBoxEmptyNameDeny));
        return;
    }

    dispatch(filterProcessing());
    fetchPost(
        window.constants.filterSave,
        model,
        id => dispatch(filterSaved(id)),
        ex => dispatch(formLoadedError(ex))
    );
}

export const start = (model, isReserve, denyEmpty) => (dispatch) => {
    if (model.isPublic && model.id > 0 && !isReserve) {
        dispatch(filterSaved(model.id));
        return;
    }

    if (denyEmpty && !model.searchAllFieldsValue && (!model.items || model.items.length === 0)) {
        dispatch(hideValidate(window.captions.AddEmpryRowError));
        return;
    }

    if (model.id > 0 && !isReserve) {
        dispatch(filterSaved(model.id));
        return;
    }

    dispatch(filterProcessing());

    fetchPost(
        window.constants.filterSaveDefault,
        model,
        (id) => {
            if (isReserve)
                dispatch(reserveSaved(id));
            else
                dispatch(filterSaved(id));
        },
        ex => dispatch(formLoadedError(ex))
    );
}

export const remove = (id) => (dispatch) => {
    const approved = confirm(window.captions.RemoveFilterQuesion) === true;
    if (!approved)
        return dispatch(filterRemove(0));

    dispatch(filterProcessing());
    fetchDelete(
        window.constants.filterDelete,
        id,
        () => dispatch(filterRemove(id)),
        (ex) => dispatch(formLoadedError(ex))
    );
}

export default searchFormSlice.reducer