import React, { useState, useEffect } from 'react';
import WizardFunction from "./wizardFunctions.jsx";
import { renderPair } from "../../cards/cardRenderFunctions.jsx";
import LoadingMask from "../../../components/loadingMask.jsx";
import ViewEditTreeView from "../../../components/controls/viewEditTreeView.jsx";
import { TextBox, Checkbox } from "@progress/kendo-react-inputs";
import { PanelBar, PanelBarUtils } from "@progress/kendo-react-layout";
import { Button, ChipList, Chip } from "@progress/kendo-react-buttons";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { checkCircleIcon } from "@progress/kendo-svg-icons";
import { statGeneralTypeEnum, dateTimeWidth, getFormatDateTime } from "../../../utils/systemConstants.jsx";
import { AttachFileType } from "./controls/attachFileType.jsx";
import { ParametersSelector } from "./controls/parameterSelector.jsx";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { fetchGet, fetchPost, headersFile } from "../../../utils/requestsHelper.jsx";
import { Upload } from "@progress/kendo-react-upload";
import { DateTimePicker } from "@progress/kendo-react-dateinputs";
import sharedUiRenderFunctions from "./controls/sharedUiRenderFunctions.jsx";
import { RadioGroup } from "@progress/kendo-react-inputs";
import { useLocation } from "react-router-dom";
import MessageMask from "../../../components/messageMask.jsx";
import ExportQueues from "../../exportQueue/exportQueues.jsx";
import { TemplateName } from "./controls/templatedName.jsx";

export function StatGeneralItemComponent({ template, templateFields, onEdit }) {
    const location = useLocation();
    const isReports = location.pathname.includes("reports");
    const [error, setError] = useState(null);
    const [currentTemplate, setCurrentTemplate] = useState(null);
    const [visible, setVisible] = React.useState(false);
    const [editAttachment, setEditAttachment] = useState(false);
    const [attachments, setAttachments] = useState([]);
    const [types, setTypes] = useState([]);
    const [users, setUsers] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [exportStarted, setExportStarted] = useState(false);
    const [hasQueue, setHasQueue] = useState(false);

    useEffect(() => {
        fetchData();
        setCurrentTemplate(template);
        loadUsers();

        if ("attachments" in template)
            setAttachments(template.attachments);

        setEditAttachment(template.attachment ? true : false)
    }, [template]);

    useEffect(() => {

        if ("attachments" in template) {
            setAttachments(template.attachments);
        }
        setEditAttachment(template.attachment ? true : false)
    }, [template.attachment, template.attachments]);

    const toggleDialog = () => {
        setVisible(!visible);
    };

    if (!template)
        return;

    const titleContent = [];
    const content = [];
    const contentAlt = [];
    const panelItems = [];
    const style = { width: "100%" };
    const sources = WizardFunction.getSources();
    const mergeCells = WizardFunction.getMergeCells();
    const documentFormats = WizardFunction.getDocumentFormats();
    const pdfVersions = WizardFunction.getPdfVersions();

    if (!currentTemplate ||
        currentTemplate.id != template.id ||
        currentTemplate.isLoaded != template.isLoaded) {
        setCurrentTemplate(template);
    }

    if (!currentTemplate || isLoaded)
        return <div style={{ height: "300px" }}><LoadingMask /></div>;

    const getChip = (item) => {
    	return <Chip {...item} selectedSvgIcon={checkCircleIcon} />;
    }

    const getRestriction = (type) => {
        return (type === statGeneralTypeEnum.report || type === statGeneralTypeEnum.reportSql)
            ? [".xls", ".xlsx", ".xlsm", ".txt", ".csv"]
            : [".docx", ".txt"];
    }

    const onEditFilters = (item) => {
        var template = currentTemplate;
        template.mainFilter = item;
        setCurrentTemplate(template);
        onEdit(template);
    };

    const changeNameHandler = (e) => {
        var template = currentTemplate;
        template.name = e.value;
        setCurrentTemplate(template);
        onEdit(template);
    };

    const changeSourceHandler = (e) => {
        var newTemplate = currentTemplate;
        newTemplate.source = e.value.id;
        setCurrentTemplate(template);
        onEdit(newTemplate);
    };

    const changeHeadersOrientationHandler = (e) => {
        var newTemplate = currentTemplate;
        newTemplate.headersOrientation = e.value.id;
        setCurrentTemplate(newTemplate);
        onEdit(newTemplate);
    };

    const generateHandler = (e) => {
        var request = {
            id: currentTemplate.id,
            isReport: isReports,
            type: currentTemplate.type,
            filters: currentTemplate.mainFilter.selectedFilters
        }

        generateTemplate(request);
    };

    const changeParameterHandler = (e, type) => {
        var newTemplate = currentTemplate;
        switch (type) {
            case 0:
                newTemplate.downloadReportToFolder = e.value
                break;
            case 1:
                newTemplate.createOneFilePerEntity = e.value
                break;
            case 2:
                setCurrentTemplate(e);
                onEdit(e);
                break;
            case 3:
                newTemplate.onFiltersWhenLoading = e.value
                break;
            case 4:
                newTemplate.mergeCells = e.value
                break;
            case 5:
                newTemplate.saveFormating = e.value
                break;
            case 6:
                setCurrentTemplate(e);
                onEdit(e);
                break;
            case 7:
                newTemplate.allowedUsers = WizardFunction.getAllowedUserModels(currentTemplate.allowedUsers, e)
                break;
            case 8:
                newTemplate.documentFormat = e.value
                break;
            case 9:
                newTemplate.generateAllLoansByClient = e.value
                break;
            case 10:
                newTemplate.pdfVersion = e.value.value
                break;
        }

        if (type !== 2 && type != 6) {
            setCurrentTemplate(template);
            onEdit(template);
        }
    };

    const changeAskedFieldsHandler = (e) => {
        var newTemplate = currentTemplate;
        newTemplate.askedFields.selected = e.value;
        setCurrentTemplate(newTemplate);
        onEdit(newTemplate);
    };

    const onCloseError = () => {
        setError(null);
        setCurrentTemplate(template);
    };

    const onClose = () => {
        setCurrentTemplate(template);
    };

    if (error) {
        return <Dialog width={400}>
            <MessageMask inline error={error} />
            <DialogActionsBar>
                <Button onClick={() => { onCloseError() }}>{window.captions.Close}</Button>
            </DialogActionsBar>
        </Dialog>;
    }

    renderPair(titleContent,
    	window.captions.Identifier,
        `StatIdentifier_${currentTemplate.id}`,
        () => <TextBox key="ReportIdentifier" value={currentTemplate.id} readOnly={true} />
    );

    renderPair(titleContent,
        window.captions.Author,
        `StatAuthor_${currentTemplate.id}`,
        () => <TextBox key="ReportAuthor" value={currentTemplate.author} readOnly={true} />
    );

    if ("created" in currentTemplate) {
        renderPair(titleContent,
            window.captions.Created,
            `StatCreated_${currentTemplate.id}`,
            () => <DateTimePicker key="Created"
                value={new Date(currentTemplate.created)}
                width={dateTimeWidth}
                disabled
                formatPlaceholder={getFormatDateTime()}
                readOnly={true} />
        );
    }

    if (currentTemplate.id !== 0) {
        renderPair(titleContent,
            window.captions.Execute,
            `StatGenerateButton_${currentTemplate.id}`,
            () => <Button
                disabled={currentTemplate.id === 0 || currentTemplate.isEdited}
                icon="arrow-chevron-right"
                onClick={generateHandler}>{isReports ? window.captions.MakeReport : window.captions.MakeDocument }</Button>
        );
    }
    
    renderPair(content,
    	window.captions.Caption,
        `StatName_${currentTemplate.id}`,
        () => <TextBox key="ReportName" value={currentTemplate.name} onChange={changeNameHandler}/>
    );

    renderPair(content,
        isReports
            ? window.captions.FileCaptionTemplateReport
            : window.captions.FileCaptionTemplate,
        `StatTemplateName_${currentTemplate.id}`,
        () => <TemplateName template={currentTemplate}
            fields={templateFields}
            onEdit={onEdit} />
    );

    renderPair(content,
    	window.captions.Source,
        `StatSource_${currentTemplate.id}`,
    	() => <DropDownList style={style}
    	                    data={sources}
    	                    textField="name"
    						disabled={currentTemplate.id > 0}
    	                    dataItemKey="id"
                            value={WizardFunction.getSelectedSource(sources, currentTemplate.source)}
                            onChange={changeSourceHandler}/>
    );

    renderPair(content,
        window.captions.TemplateFile,
        `StatUploadTemplateFile_${currentTemplate.id}`,
        () => editAttachment
            ? <Upload
                batch={false}
                multiple={false}
                defaultFiles={attachments}
                onStatusChange={uploadStatusChange}
                onRemove={remove}
                restrictions={{
                    allowedExtensions: getRestriction(currentTemplate.type),
                }}
                saveHeaders={headersFile()}
                removeHeaders={headersFile()}
                saveUrl={window.constants.fileUpload}
                removeUrl={window.constants.fileRemove} />
            : <div className="k-d-flex-row">
                <TextBox key="Template"
                    readOnly={true}
                    value={currentTemplate.template} />
                <Button icon="edit" onClick={() => setEditAttachment(true)} />
            </div>
    );

    if ("headersOrientation" in currentTemplate && isReports) {
        renderPair(content,
            window.captions.HeadersOrientation,
            `StatHeadersOrientation_${currentTemplate.id}`,
            () => <DropDownList style={style}
                data={WizardFunction.getOrienrations()}
                textField="name"
                dataItemKey="id"
                value={WizardFunction.getOrienrations(currentTemplate.headersOrientation)}
                onChange={changeHeadersOrientationHandler} />
        );
    }

    renderPair(contentAlt,
    	window.captions.DisplayFilterForFormingInSuchFields,
        `ExportFilter_${currentTemplate.id}`,
    	() => <ChipList
    			  defaultData={WizardFunction.getDefaultAskedFields(currentTemplate.askedFields)}
    		      defaultValue={currentTemplate.askedFields.selected}
    			  chip={getChip}
    			  onChange={changeAskedFieldsHandler}
    		      selection="multiple"
    	      />
    );

    let parameters = [];

    if (currentTemplate.type === statGeneralTypeEnum.report ||
        currentTemplate.type === statGeneralTypeEnum.document) {
        if ("documentFormat" in currentTemplate && !isReports) {
            renderPair(parameters,
                window.captions.SelectDocumentFormat,
                "DocumentFormat",
                () => <RadioGroup key="documentFormat"
                    data={documentFormats}
                    layout="horizontal"
                    value={currentTemplate.documentFormat}
                    onChange={(e) => changeParameterHandler(e, 8)}/>,
                true);
        }

        if ("pdfVersion" in currentTemplate && !isReports && currentTemplate.documentFormat === 1) {
            renderPair(parameters,
                window.captions.PdfVersion,
                "PdfVersion",
                () =>
                <DropDownList style={style}
    	                    data={pdfVersions}
    	                    textField="label"
    	                    dataItemKey="value"
                            value={pdfVersions.find(type => type.value === currentTemplate.pdfVersion) }
                            onChange={(e) => changeParameterHandler(e, 10)}/>,
                true);
        }

        if ("downloadReportToFolder" in currentTemplate && isReports) {
            renderPair(parameters,
                "",
                "DownloadReportToFolder",
                () => <Checkbox key="downloadReportToFolder"
                    value={currentTemplate.downloadReportToFolder}
                    onChange={(e) => changeParameterHandler(e, 0)}
                    label={window.captions.DownloadReportToFolder} />,
                true);
        }

        if ("createOneFilePerEntity" in currentTemplate) {
            renderPair(parameters,
                "",
                "CreateOneFilePerLoan",
                () => <Checkbox key="createOneFilePerLoan"
                    value={currentTemplate.createOneFilePerEntity}
                    onChange={(e) => changeParameterHandler(e, 1)}
                    label={window.captionsDynamic.CreateOneFilePerLoan} />,
                true);
        }

        if ("generateAllLoansByClient" in currentTemplate && !isReports) {
            renderPair(parameters,
                "",
                "GenerateAllLoansByClient",
                () => <Checkbox key="generateAllLoansByClient"
                    value={currentTemplate.generateAllLoansByClient}
                    onChange={(e) => changeParameterHandler(e, 9)}
                    label={window.captionsDynamic.GenerateAllLoansByClient} />,
                true);
        }

        if ("attachToLoan" in currentTemplate) {
            renderPair(parameters,
                "",
                "AttachToLoan",
                () => <AttachFileType template={currentTemplate} types={types} onEdit={(e) => changeParameterHandler(e, 2)} />,
                true);
        }

        if ("onFiltersWhenLoading" in currentTemplate) {
            renderPair(parameters,
                "",
                "OnFiltersWhenLoading",
                () => <Checkbox key="onFiltersWhenLoading" value={currentTemplate.onFiltersWhenLoading}
                    onChange={(e) => changeParameterHandler(e, 3)}
                    label={window.captions.OnFiltersWhenLoading} />,
                true);
        }

        if ("mergeCells" in currentTemplate && isReports) {
            renderPair(parameters,
                window.captions.SameValueCells,
                "MergeCells",
                () => <RadioGroup key="mergeCells"
                    layout="horizontal"
                    data={mergeCells}
                    value={currentTemplate.mergeCells}
                    onChange={(e) => changeParameterHandler(e, 4)} />,
                true);
        }

        if ("saveFormating" in currentTemplate && isReports) {
            renderPair(parameters,
                "",
                "SaveFormating",
                () => <Checkbox key="saveFormating" value={currentTemplate.saveFormating}
                    onChange={(e) => changeParameterHandler(e, 5)}
                    label={window.captions.SaveFormating} />,
                true);
        }

        if ("varDateDivider" in currentTemplate) {
            renderPair(parameters,
                "",
                "VarDateDivider",
                () => <ParametersSelector key="paramsSelector"
                    template={currentTemplate}
                    onEdit={(e) => changeParameterHandler(e, 6)} />,
                true);
        }
    }

    if ("allowedUsers" in currentTemplate) {
        renderPair(contentAlt,
            "",
            "AllowedUsers",
            () =>
                <div>
                    <Button type="button" onClick={toggleDialog} id="open-dialog">
                        {window.captions.UsersAccess}
                    </Button>
                    {visible && (
                        <Dialog title={currentTemplate.name}
                            onClose={toggleDialog}
                            minWidth={500}
                            maxWidth={700}
                            height={700}>
                            <ViewEditTreeView
                                key="usersEditTreeView"
                                dataTree={WizardFunction.setAllowedUserModels(users, currentTemplate.allowedUsers)}
                                viewField="isView"
                                editField="isEdit"
                                onCheckChange={(e) => changeParameterHandler(e, 7)} />
                        </Dialog>
                    )}
                </div>,
            true);
    }

    var entity = WizardFunction.findEntitySourceByValue(currentTemplate.source);

    const filterGroups = sharedUiRenderFunctions.renderForm(currentTemplate.mainFilter, onEditFilters, entity, false);
    filterGroups.map((group) => {
        panelItems.push(group);
    });
    if (currentTemplate.type === statGeneralTypeEnum.report || currentTemplate.type === statGeneralTypeEnum.document) {
        panelItems.push({
            title: window.captions.Parameters,
            content: <div className="k-m-3">{parameters}</div>
        });
    }

    if (panelItems.length > 0)
        content.push(<PanelBar key={`PanelBarParameters${currentTemplate.id}`} className="k-panelbar dm-m-bottom">
    		             {PanelBarUtils.mapItemsToComponents(panelItems)}
    	             </PanelBar>);

    return <div className="k-m-1">
        <div className="dm-container dm-container-two">{titleContent}</div>
        <div className="dm-menu-divider" />
        <div className="dm-container dm-container-two">{content}</div>
        <div className="dm-container">{contentAlt}</div>
        {exportStarted
            ? <Dialog width={400}>
                <div style={{ minHeight: "100px", maxHeight: "700px", overflow: "auto" }}>
                    <ExportQueues
                        inDialog
                        onCheckQueue={(e) => setHasQueue(e)}
                        isStarted={true} />
                    {hasQueue ? null : window.captions.EmptyQueue}
                </div>
                <DialogActionsBar>
                    <Button onClick={() => { setExportStarted(false) }}>{window.captions.Close}</Button>
                </DialogActionsBar>
            </Dialog>
            : null}
    </div>;

    function uploadStatusChange(e) {
        if (!e.response)
            return;

        var files = e.response.response;
        var length = attachments.length;
        var items = [];

        if (files && files.length > 0) {

            for (let index in files) {
                const file = files[index];
                if (!file || !file.fileName)
                    continue;

                var attachment = {
                    index: length + items.length,
                    fileName: file.fileName,
                    name: file.name,
                    extension: file.fileType,
                    size: file.size
                };

                items.push(attachment);
            }

            var attachmentTemp = files[0];

            var newTemplate = currentTemplate;
            newTemplate.attachment = attachmentTemp.fileName;
            newTemplate.attachments = [...attachments, ...items];
            setCurrentTemplate(newTemplate);
            onEdit(newTemplate);
        }
    }

    function remove(e) {
        var newTemplate = currentTemplate;
        newTemplate.attachment = "";
        newTemplate.attachments = [];
        setCurrentTemplate(newTemplate);
        onEdit(newTemplate);
    }

    function fetchData() {
        setError(null);
        fetchGet(window.constants.getDocumentModel,
            (data) => {
                var types = data.types;
                setTypes(types);
                setError(null);
            },
            (ex) => {
                setTypes([]);
                setError(ex.stack ? ex.stack : ex);
            });
    }

    function loadUsers() {
        setError(null);
        setIsLoaded(true);
        fetchPost(window.constants.loadWizardItem,
            { type: 2 },
            (data) => {
                setUsers(data);
                setIsLoaded(false);
            },
            (ex) => {
                setUsers([]);
                showError(ex);
            });
    }

    function generateTemplate(request) {
        fetchPost(`${window.constants.statisticRender}/${request.type}/${request.id}/${0}`,
            [],
            () => {
                setExportStarted(true);
                setHasQueue(true);
            },
            ex => {
                showError(ex);
                setIsLoaded(true);
            }
        );

        onClose();
    };

    function showError(ex) {
        setError(ex);
        setIsLoaded(false);
    }
};