import React, { useState, useEffect } from 'react';
import LoadingMask from "../../../../../components/loadingMask.jsx";
import { renderPair } from "../../../../cards/cardRenderFunctions.jsx";
import WizardFunction from "../../wizardFunctions.jsx";
import { TextBox, RadioGroup } from "@progress/kendo-react-inputs";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { fetchPost } from '../../../../../utils/requestsHelper.jsx';
import { useLocation } from "react-router-dom";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

export function MergingVariableTab({ variable, onEdit }) {
    const location = useLocation();
    const isReports = location.pathname.includes("reports");

    const [currentVariable, setCurrentVariable] = useState();
    const [error, setError] = useState(null);
    const [enableDivider, setEnableDivider] = useState(true);
    const [templatesVariables, setTemplatesVariables] = useState();
    const [isLoaded, setIsLoaded] = useState(true);

    const getTemplatesRequest = () => {
        return {
            sourceType: currentVariable.sourceType,
            fieldSourceType: currentVariable.fieldSourceType,
            selected: currentVariable.sourceItems,
            isReport: isReports,
            isOnlySelected: true
        }
    }

    useEffect(() => {
        setCurrentVariable(variable.item);
        setEnableDivider(!("mergeRowDivider" in variable.item))
    }, [variable.item, variable.item.sourceItems]);

    if (!currentVariable) {
        return <div style={{ height: "300px" }}><LoadingMask /></div>;
    }

    const changeDividerTypeHandler = (e) => {
        setEnableDivider(e.value);
        var newVariable = currentVariable;
        if (e.value) {
            delete newVariable.mergeRowDivider;
        }
        else {
            newVariable.mergeRowDivider = ", ";
        }

        setCurrentVariable(newVariable);
        onEdit({
            index: variable.index,
            item: newVariable
        });
    }

    const changeDividerHandler = (e) => {
        var newVariable = currentVariable;
        newVariable.mergeRowDivider = e.value;
        setCurrentVariable(newVariable);
        onEdit({
            index: variable.index,
            item: newVariable
        });
    }

    const changeStringFormatHandler = (e) => {
        var newVariable = currentVariable;
        newVariable.mergeFormatString = e.value;
        setCurrentVariable(newVariable);
        onEdit({
            index: variable.index,
            item: newVariable
        });
    };

    const loadVariableTemplateHandler = (e) => {
        if (!templatesVariables) {
            getVariablesTemplates(getTemplatesRequest());
        }
    };

    const onCloseError = () => {
        setError(null);
        setCurrentVariable(template);
    };

    if (error) {
        return <Dialog width={400}>
            <MessageMask inline error={error} />
            <DialogActionsBar>
                <Button onClick={() => { onCloseError() }}>{window.captions.Close}</Button>
            </DialogActionsBar>
        </Dialog>;
    }

    var content = [];
    var boolParamContent = [];
    const boolData = [{ value: true, label: window.captions.MergingNewLine },
        { value: false, label: window.captions.MergingCustomDivider }]

    renderPair(boolParamContent,
        "",
        "MergeRowDividerEnabler",
        () => <RadioGroup data={boolData}
            key={`MergeRowDividerEnabler_${currentVariable.id}`}
            onChange={changeDividerTypeHandler}
            value={enableDivider}
            layout="horizontal" />,
        true);

    renderPair(boolParamContent,
        "",
        `VariableMergeRowDivider_${currentVariable.id}`,
        () => <TextBox key="VariableMergeRowDivider"
            disabled={enableDivider}
            style={{width: "300px"}}
            value={currentVariable.mergeRowDivider ?? ""}
            onChange={changeDividerHandler} />
    );

    renderPair(content,
        window.captions.MergingRowDivider,
        `VariableGroupAffects_${currentVariable.id}`,
        () => boolParamContent
    );

    renderPair(content,
        window.captions.MergingParsingTemplate,
        `VariableMergeFormatString_${currentVariable.id}`,
        () => <TextBox key="VariableMergeFormatString"
            value={currentVariable.mergeFormatString ?? ""}
            onChange={changeStringFormatHandler} />
    );

    if (templatesVariables) {
        content.push(<PanelBar key={`PanelBarTemplates${currentVariable.id}`}
            onSelect={loadVariableTemplateHandler}
            className="k-panelbar k-m-1 dm-m-bottom">
            <PanelBarItem key="templatesItem"
                title={window.captions.MergingVariables}
                style={{ maxHeight: "500px", overflow: "hidden" }}>
                {isLoaded
                    ? <div style={{ height: "300px" }}><LoadingMask /></div>
                    : <div className="k-m-1" style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                        gap: "8px",
                        maxHeight: "300px",
                        overflow: "auto"
                    }}>
                        {WizardFunction.getAvailableVariableFields(templatesVariables)}
                    </div>
                }
            </PanelBarItem>
        </PanelBar>);
    }

    return <div className="k-m-1">{content}</div>;

    function getVariablesTemplates(request) {
        setIsLoaded(true);
        fetchPost(`${window.constants.retrieveSourceItems}`,
            request,
            model => {
                if (model) {
                    setTemplatesVariables(model)
                    setIsLoaded(false);
                } else {
                    showError(window.captions.LoadDataError);
                }
            },
            ex => {
                showError(ex);
            }
        );
    }

    function showError(ex) {
        setError(ex);
        setIsLoaded(false);
    }
}