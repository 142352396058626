import React, { useState, useEffect } from 'react';
import { statGeneralTypeEnum } from "../../../utils/systemConstants.jsx";
import { fetchGet, fetchPost } from '../../../utils/requestsHelper.jsx';
import LoadingMask from "../../../components/loadingMask.jsx";
import { WizardComponent } from "../sharedUi/wizardUi.jsx";
import { ItemsTreeView } from "../sharedUi/controls/itemsTreeView.jsx";
import { Button } from "@progress/kendo-react-buttons";
import { fileWordIcon, folderIcon } from "@progress/kendo-svg-icons";
import { useSelector } from 'react-redux';
import WizardFunctions from '../sharedUi/wizardFunctions.jsx';
import MessageMask from "../../../components/messageMask.jsx";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { useBeforeUnload } from "react-router-dom";
import ErrorMask from "../../../components/emptyMask.jsx";

export default function AdminDocumentsSettings() {
    const [isLoaded, setIsLoaded] = useState(false);
    const [isSettingsLoaded, setIsSettingsLoaded] = useState(false);
    const [isEdited, setIsEdited] = useState(false);
    const [remowedItemsFolders, setRemowedItemsFolders] = useState([]);
    const [remowedList, setRemowedList] = useState([]);
    const [editList, setEditList] = useState([]);
    const [title, setTitle] = useState(null);
    const [error, setError] = useState(null);
    const [languages, setLanguages] = useState([]);
    const [usersGroups, setUsersGroups] = useState([]);
    const [filterFields, setFilterFields] = useState([]);
    const [templates, setTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const access = useSelector(state => state.header.model.access);
    const user = useSelector(state => state.header.model);

    useEffect(() => {
        document.title = `Delta M. Crm. ${window.captions.Documents}`;
        documentSettingsLoad();
        getTitle();
    }, []);

    useBeforeUnload((event) => {
        if (isEdited) {
            event.preventDefault();
            event.returnValue = "";
        }
    });

    if (!access.adminReports)
        return <ErrorMask error={window.captions.AccessDenied} />;

    const handleSelectTemplate = (template) => {
        setSelectedTemplate(null);

        if (template.item.id === 0) {
            setSelectedTemplate(template);
        }
        else {
            if (template.item.isLoaded)
                setSelectedTemplate(template);
            else
                selectedTemplateLoad(template);
        }

        getTitle(template.item.name);
    };

    const addFolderHandler = () => {
        addFolderItem(templates);
    };

    const addItemHandler = (request) => {
        addItem(request);
        getTitle(window.captions.Documents);
    };

    const copyHandler = () => {
        if (!selectedTemplate)
            return;

        var parent = WizardFunctions.findItemById(templates, selectedTemplate.item.id, true);

        var names = [];
        names.push({ id: -1, name: selectedTemplate.item.name });
        parent.items.forEach(item => names.push({ id: names.length, name: item.name }));

        createCopy(names, parent);
    }

    const deleteItemHandler = (item) => {
        if (!selectedTemplate)
            return;
        var newRemowedList = remowedList;

        if ("id" in item) {
            if (!remowedList.find((removedItem) => removedItem.id === item.id))
                newRemowedList.push(item);
            setRemowedList(newRemowedList);
            return;
        }

        if (selectedTemplate && !confirm(window.captions.DeleteItemConfirm))
            return;

        var deletedItemRequest = WizardFunctions.createDeletedModel(selectedTemplate.item.id, 0)
        deletedItemRequest.type = selectedTemplate.item.type;

        getAllDeletedIdList(deletedItemRequest, selectedTemplate.item);

        setSelectedTemplate(null);
        setIsEdited(true);

        getTitle(window.captions.Documents);
    };

    const handleEdit = (model) => {
        getTitle(model.name);

        if (!editList.includes(model.id)) {
            var newEditList = editList;
            newEditList.push(model.id);
            setEditList(newEditList);
        }

        setIsEdited(true);
        updateItem(model, selectedTemplate.index);
    };


    const handleSave = () => {
        var items = [];

        templates.map((template) => {
            if (template.id === 0)
                items.push(template)
            else {
                if (template.items.some((item) => item.id === 0)) {
                    var item = items.find((item) => item.id === template.id);
                    if (!item)
                        items.push(template)
                }
            }
        });

        remowedItemsFolders.map((template) => items.push(template));

        editList.map((item) => {
            var selected = WizardFunctions.findItemById(templates, item, true);

            var isRemoved = false;
            if (remowedList && remowedList.length > 0)
                isRemoved = remowedList.some((removedItem) => removedItem.id === selected.id);

            if (selected && !isRemoved)
                items.push({ ...selected });
        });

        items.map((templateItem) => {
            templateItem.items.map((item) => {
                if (!item.isLoaded)
                    templateItem.items = WizardFunctions.removeItemByObject(templateItem.items, item)
            });
        });

        var request = {
            items: WizardFunctions.removeDuplicatesById(items),
            deletedItemRequests: remowedList,
            isReport: false
        }

        saveModel(request);

        getTitle(window.captions.Documents);
    };

    const onCloseError = () => {
        setError(null);

        setEditList([]);
        setIsEdited(false);
        setRemowedList([]);

        setTemplates([]);
        setSelectedTemplate(null);

        setUsersGroups([]);
        setFilterFields([]);
        setLanguages([]);

        documentSettingsLoad();

        getTitle(window.captions.Documents);
    };

    const onClose = () => {
        setSelectedTemplate(null);
    };

    if (error) {
        return <Dialog width={400}>
            <MessageMask inline error={error} />
            <DialogActionsBar>
                <Button onClick={() => { onCloseError() }}>{window.captions.Close}</Button>
            </DialogActionsBar>
        </Dialog>;
    }

    const updateItem = (model, selectedIndex) => {
        const separator = "_";
        let data = templates;
        const indices = selectedIndex.split(separator);

        if (indices.length === 1) {
            // A root item is selected.
            copyAndUpdateItem(Number(indices[0]), data, model);
        } else if (indices.length === 2) {
            // A child item is selected.
            const parentIndex = Number(indices[0]);
            const childIndex = Number(indices[1]);

            data[parentIndex] = { ...data[parentIndex] };
            data[parentIndex].items = data[parentIndex].items.slice();
            copyAndUpdateItem(childIndex, data[parentIndex].items, model);
        } else if (indices.length === 3) {
            // A grandchild item is selected.
            const parentIndex = Number(indices[0]);
            const childIndex = Number(indices[1]);
            const grandChildIndex = Number(indices[2]);

            data[parentIndex] = { ...data[parentIndex] };
            data[parentIndex].items = data[parentIndex].items.slice();
            data[parentIndex].items[childIndex] = { ...data[parentIndex].items[childIndex] };
            data[parentIndex].items[childIndex].items = data[parentIndex].items[childIndex].items.slice();
            copyAndUpdateItem(grandChildIndex, data[parentIndex].items[childIndex].items, model);
        }

        setTemplates([...data]);
    };

    const copyAndUpdateItem = (itemIndex, siblings, newData) => {
        siblings[itemIndex] = { ...siblings[itemIndex] };
        siblings[itemIndex] = newData;
    };

    if (isLoaded)
        return <LoadingMask />;

    const paddingY = "dm-m-bottom dm-m-top";
    const mainButtons = [
        <Button key="addItem"
            icon="add"
            className={`dm-m-right ${paddingY}`}
            onClick={addFolderHandler} />,
        <Button
            key="saveItem"
            icon="save"
            disabled={!isEdited}
            className={`dm-m-right dm-positive-filled ${paddingY}`}
            onClick={handleSave} />,
        <Button
            key="copyItem"
            icon="copy"
            disabled={!selectedTemplate ||
                selectedTemplate.item.type === statGeneralTypeEnum.folder ||
                selectedTemplate.item.type == statGeneralTypeEnum.documentGroup}
            onClick={copyHandler}
            className={`dm-m-right ${paddingY}`} />,
        <Button
            key="deleteItem"
            icon="delete"
            disabled={!selectedTemplate}
            className={`dm-m-right dm-negative-filled ${paddingY}`}
            onClick={deleteItemHandler} />
    ];

    const fullWidth = { width: "100%" };
    return <div className="dm-full-height" key="wizardView">
        <div className="dm-full-height">
            <h1 className="dm-title">{title}</h1>
            <div className="dm-under-title-content k-d-flex-row">
                <div>
                    <div className="dm-m-left">{mainButtons}</div>
                    <ItemsTreeView key="DocumentTemplates"
                        items={templates}
                        onSelect={handleSelectTemplate}
                        iconsLevel={{
                            "0": folderIcon,
                            "1": fileWordIcon,
                            "2": fileWordIcon,
                            "-1": folderIcon
                        }}
                        differentIcon={{
                            parameter: "type",
                            value: 4
                        }}
                        selectedItem={selectedTemplate} />
                </div>
                <div style={fullWidth}>
                    {selectedTemplate
                        ? selectedTemplate.item.isLoaded || selectedTemplate.item.id === 0
                            ? <WizardComponent
                                users={usersGroups}
                                fields={filterFields}
                                languages={languages}
                                className="dm-full-height"
                                template={selectedTemplate.item}
                                onAddItem={(model) => addItemHandler(model)}
                                onEdit={(model) => handleEdit(model)}
                                onDelete={(model) => deleteItemHandler(model)}
                            />
                            : <LoadingMask />
                        : isSettingsLoaded
                            ? <LoadingMask />
                            : <div className="dm-rules-users">
                                <div className="select-request">
                                    {window.captions.ChoseOrAddTemplate}
                                </div>
                            </div>
                    }
                </div>
            </div>
        </div>
    </div>

    function documentSettingsLoad() {
        setIsLoaded(true);
        fetchGet(`${window.constants.getWizardSettings}/documents`,
            model => {
                if (model) {
                    setTemplates(model);
                    setError(null);
                    setIsLoaded(false);
                } else {
                    showError(window.captions.LoadDataError);
                }

            },
            ex => showError(ex)
        );
    }

    function selectedTemplateLoad(request) {
        setIsSettingsLoaded(true);
        var formattedRequest = {
            item: request.item,
            isReport: false
        }
        fetchPost(`${window.constants.selectedWizardSettings}`,
            formattedRequest,
            model => {
                if (model) {
                    request.item = model;
                    model.isLoaded = true;

                    updateItem(model, request.index);

                    setSelectedTemplate(request);
                    setError(null);
                    setIsSettingsLoaded(false);
                } else {
                    showError(window.captions.LoadDataError);
                }
            },
            ex => {
                showError(ex);
            }
        );
    }

    function getAllDeletedIdList(request, template) {
        setIsSettingsLoaded(true);
        request.isReport = false;

        fetchPost(`${window.constants.deleteWizardItemList}`,
            request,
            model => {
                if (model) {
                    if (template && template.type !== statGeneralTypeEnum.folder) {
                        var folder = WizardFunctions.findItemById(templates, template.id, true);
                        if (folder) {
                            var item = remowedItemsFolders.find((item) => item.id === folder.id);
                            if (!item) {
                                remowedItemsFolders.push(folder);
                                setRemowedItemsFolders(remowedItemsFolders);
                            }
                        }
                    }

                    if (selectedTemplate.item.id === 0)
                        setTemplates(WizardFunctions.removeItemByObject(templates, selectedTemplate.item));
                    else
                        setTemplates(WizardFunctions.removeItemById(templates, selectedTemplate.item.id));

                    if (remowedList && remowedList.length <= 0)
                        setRemowedList(model);
                    else {
                        var newList = remowedList;
                        model.map((item) => {
                            var seleectedItem = newList.find((listItem) => listItem.id === item.id && listItem.type === item.type)
                            if (!seleectedItem)
                                newList.push(item)
                        })
                        setRemowedList(newList);
                    }

                    setIsSettingsLoaded(false);
                } else {
                    showError(window.captions.LoadDataError);
                }
            },
            ex => {
                showError(ex);
            }
        );
    }

    function saveModel(request) {
        setIsLoaded(true);
        request.isReport = false;

        fetchPost(`${window.constants.saveWizardSettings}`,
            request,
            model => {
                if (model) {
                    if (model.isSuccess) {
                        setEditList([]);
                        setIsEdited(false);
                        setRemowedList([]);

                        setTemplates([]);
                        setSelectedTemplate(null);

                        setLanguages([]);

                        reportSettingsLoad();
                    }
                    else {
                        showError(`${window.captions.ImportMulti}: ${model.duplicateNames}`);
                    }

                    getTitle(window.captions.MainTabStatisticReports);
                } else {
                    showError(window.captions.LoadDataError);
                }
            },
            ex => {
                showError(ex);
            }
        );
    }

    function addFolderItem(model) {
        setIsLoaded(true);

        let names = [];

        if (model.names)
            model.names.forEach(item => names.push({ id: names.length, name: item.name }));
        else
            templates.forEach(item => names.push({ id: names.length, name: item.name }));

        let request = {
            names: names,
            type: model.name ? model.type : 0,
            isReport: false
        };

        fetchPost(`${window.constants.addWizardSettings}`,
            request,
            model => {
                var newTemplates = templates;
                newTemplates.push(model.item);
                setTemplates(newTemplates);
                setIsEdited(true);
                setIsLoaded(false);
            },
            ex => showError(ex)
        );

        onClose();
    };

    function addItem(request) {
        setIsLoaded(true);
        fetchPost(`${window.constants.addWizardSettings}`,
            request,
            model => {
                if (model) {
                    var newTemplates = templates;
                    var item = WizardFunctions.findItemById(newTemplates, model.id);
                    if (!item) {
                        showError("Not found template");
                        return;
                    }

                    item.items.push(model.item);
                    setTemplates(newTemplates);

                    setIsEdited(true);
                    setIsLoaded(false);
                }
            },
            ex => showError(ex)
        );

        onClose();
    };

    function createCopy(request, parent) {
        setIsLoaded(true);
        fetchPost(`${window.constants.loadWizardItem}`,
            {
                type: 4,
                names: request
            },
            model => {
                if (model) {

                    if (parent) {
                        const newItem = JSON.parse(JSON.stringify(selectedTemplate.item));
                        newItem.id = 0;
                        newItem.name = model;
                        newItem.authorId = user.id;
                        newItem.author = user.name;
                        newItem.created = new Date();

                        newItem.variables.map((variablegroups) => {
                            variablegroups.id = 0;

                            variablegroups.items.map((variable) => {
                                variable.id = 0;
                            });
                        });

                        newItem.scripts.map((script) => { script.id = 0; });

                        parent.items.push(newItem);
                    }

                    setIsEdited(true);
                    setIsLoaded(false);
                }
            },
            ex => showError(ex)
        );

        onClose();
    };

    function showError(ex) {
        setError(ex);
        setIsLoaded(false);
        setIsSettingsLoaded(false);
    }

    function getTitle(text) {
        var title;
        if (text)
            title = text;
        else
            title = selectedTemplate
                ? selectedTemplate.item.name
                : window.captions.Documents;

        setTitle(title);
    };
}