import React from "react";
import { formatDate } from "@progress/kendo-intl";
import { GridColumn, GridColumnMenuCheckboxFilter } from "@progress/kendo-react-grid";
import { groupBy } from "@progress/kendo-data-query";
import { setGroupIds } from "@progress/kendo-react-data-tools";
import { fieldTypes, dateTimeFormat, dateFormat } from "../utils/systemConstants.jsx";
import { getUiCulture } from "../utils/authHelper.jsx";
import GridFilterColumnMenu from "./controls/gridFilterColumnMenu.jsx";
import GridImageCell from "../components/cells/gridImageCell.jsx";
import GridDateCell from "../components/cells/gridDateCell.jsx";
import GridDateTimeCell from "../components/cells/gridDateTimeCell.jsx";
import GridTimeCell from "../components/cells/gridTimeCell.jsx";
import GridEmailCell from "../components/cells/gridEmailCell.jsx";
import GridUrlCell from "../components/cells/gridUrlCell.jsx";
import GridPhoneCell from "../components/cells/gridPhoneCell.jsx";
import GridTextCell from "../components/cells/gridTextCell.jsx";
import GridBoolCell from "../components/cells/gridBoolCell.jsx";
import GridDoubleCell from "../components/cells/gridDoubleCell.jsx";
import GridIntegerCell from "../components/cells/gridIntegerCell.jsx";
import GridColorCell from "../components/cells/gridColorCell.jsx";

export default {
    correctGroupValues(data, uiCulture) {
        for (let key in data) {
            var item = data[key];
            if (!item.groupId)
                return;

            if (typeof item.value === "object")
                item.value = "";
            else if (typeof item.value === "boolean")
                item.value = item.value === true ? window.captions.WordYes : window.captions.WordNo;
            else if (typeof item.value === "string" && item.value.length === 19 && !isNaN(Date.parse(item.value)))
                item.value = formatDate(new Date(item.value), dateTimeFormat, uiCulture);
            else if (typeof item.value === "string" && item.value.length === 10 && !isNaN(Date.parse(item.value)))
                item.value = formatDate(new Date(item.value), dateFormat, uiCulture);

            this.correctGroupValues(item.items, uiCulture);
        }
    },

    processWithGroups(data, group) {
        const newDataState = groupBy(data, group);
        setGroupIds({
            data: newDataState,
            group: group,
        });
        this.correctGroupValues(newDataState, getUiCulture());
        return newDataState;
    },

    renderGridColumns(columns, filterDictionaries, data) {
        if (!columns)
            return <GridColumn />;

        const columnsToShow =
            columns.map((item, i) => this.renderGridColumn(item, i, filterDictionaries, data));

        return columnsToShow;
    },

    renderGridColumn(item, i, filterDictionaries, data) {
        if (item.type === fieldTypes.image)
            return <GridColumn field={item.column}
                cells={{ data: GridImageCell }}
                title={item.name} width={item.width} key={i}
                groupable={false} filterable={false} sortable={false}
                reorderable={false} resizable={false} />;

        if (item.type === fieldTypes.color)
            return <GridColumn field={item.column}
                cells={{ data: GridColorCell }}
                title={item.name} width={item.width} key={i}
                groupable={false} filterable={false} sortable={false}
                reorderable={false} resizable={false} />;

        if (item.type === fieldTypes.email)
            return <GridColumn field={item.column}
                cells={{ data: GridEmailCell }}
                title={item.name} width={item.width} key={i}
                groupable={false} filterable={false} sortable={item.sortable}
                reorderable={item.reorderable} resizable={item.resizable} />;

        if (item.type === fieldTypes.url)
            return <GridColumn field={item.column}
                cells={{ data: GridUrlCell }}
                title={item.name} width={item.width} key={i}
                groupable={false} filterable={false} sortable={item.sortable}
                reorderable={item.reorderable} resizable={item.resizable} />;

        if (item.type === fieldTypes.phone)
            return <GridColumn field={item.column}
                cells={{ data: GridPhoneCell }}
                title={item.name} width={item.width} key={i}
                groupable={false} filterable={false} sortable={item.sortable}
                reorderable={item.reorderable} resizable={item.resizable} />;

        if (item.type === fieldTypes.date)
            return <GridColumn field={item.column}
                cells={{ data: GridDateCell }}
                title={item.name} width={item.width} key={i} filter="date"
                groupable={item.groupable} filterable={false}
                columnMenu={item.filterable ? GridFilterColumnMenu : null}
                sortable={item.sortable}
                reorderable={item.reorderable} resizable={item.resizable} />;

        if (item.type === fieldTypes.dateTime)
            return <GridColumn field={item.column}
                cells={{ data: GridDateTimeCell }}
                title={item.name} width={item.width} key={i} filter="date"
                groupable={item.groupable} filterable={false}
                columnMenu={item.filterable ? GridFilterColumnMenu : null}
                sortable={item.sortable}
                reorderable={item.reorderable} resizable={item.resizable} />;

        if (item.type === fieldTypes.time)
            return <GridColumn field={item.column}
                cells={{ data: GridTimeCell }}
                title={item.name} width={item.width} key={i}
                groupable={item.groupable} filterable={false}
                columnMenu={null}
                sortable={item.sortable}
                reorderable={item.reorderable} resizable={item.resizable} />;

        if (item.type === fieldTypes.boolean)
            return <GridColumn field={item.column}
                cells={{ data: GridBoolCell }}
                title={item.name} width={item.width} key={i} filter="boolean"
                groupable={item.groupable} filterable={false}
                columnMenu={item.filterable ? GridFilterColumnMenu : null}
                sortable={item.sortable}
                reorderable={item.reorderable} resizable={item.resizable} />;

        if (item.type === fieldTypes.integer)
            return <GridColumn field={item.column}
                cells={{ data: GridIntegerCell }}
                title={item.name} width={item.width} key={i} filter="numeric"
                groupable={item.groupable} filterable={false}
                columnMenu={item.filterable ? GridFilterColumnMenu : null}
                sortable={item.sortable}
                reorderable={item.reorderable} resizable={item.resizable} />;

        if (item.type === fieldTypes.double)
            return <GridColumn field={item.column}
                cells={{ data: GridDoubleCell }}
                title={item.name} width={item.width} key={i} filter="numeric"
                groupable={item.groupable} filterable={false}
                columnMenu={item.filterable ? GridFilterColumnMenu : null}
                sortable={item.sortable}
                reorderable={item.reorderable} resizable={item.resizable} />;

        var columnsMenu = null;
        if (item.filterable && filterDictionaries && filterDictionaries[item.column]) {
            var filters = filterDictionaries[item.column];
            columnsMenu = (p) => {
                return <div>
                    <GridColumnMenuCheckboxFilter
                        {...p}
                        data={filters}
                        expanded={true} />
                </div>;
            };
        } else if (item.filterable) {
            columnsMenu = GridFilterColumnMenu;
        }

        return <GridColumn field={item.column}
            cells={{ data: GridTextCell }}
            title={item.name} width={item.width} key={i}
            groupable={item.groupable} filterable={false}
            columnMenu={columnsMenu}
            sortable={item.sortable}
            reorderable={item.reorderable} resizable={item.resizable} />;
    },

    hasDuplicatesGroups(items) {
        for (let i = 0; i < items.length; i++) {
            for (let j = 0; j < items.length; j++) {
                if (i !== j) {
                    if (items[i].field === items[j].field) {
                        return true;
                    }
                }
            }
        }
        return false;
    }
}