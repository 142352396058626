import React from "react";
import { useState, useRef } from 'react';
import { Button, DropDownButton } from "@progress/kendo-react-buttons";
import { Input } from "@progress/kendo-react-inputs";
import { getAddressText } from "../cardRenderFunctions.jsx";
import { AutoComplete } from "@progress/kendo-react-dropdowns";
import { fetchPost } from "../../../utils/requestsHelper.jsx";
const delay = 300;

export default function AddressControl({
    value,
    item,
    field,
    isEdit,
    isContact,
    required,
    access,
    stateList,
    sources,
    isAutocompleted,
    autoFillType,
    changedValue }) {
    let timeout = useRef(null);

    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);

    var state;
    var owner;
    let id = 0;
    let country = "";
    let postCode = "";
    let region = "";
    let district = "";
    let typeCity = "";
    let city = "";
    let typeStreet = "";
    let street = "";
    let typeHouse = "";
    let house = "";
    let corpus = "";
    let flat = "";
    let comment = "";
    if (value) {
        const values = value.split("&PP;");
        [country, postCode, region, district, typeCity, city, typeStreet, street,
            typeHouse, house, flat, state, owner, corpus, comment] = values;
        state = parseInt(state);
        owner = parseInt(owner);

        if (isNaN(state)) state = 0;
        if (isNaN(owner)) owner = 0;
    } else if (item) {
        id = item.id ? item.id : 0;
        state = item.state ? item.state : 0;
        owner = item.owner ? item.owner : 0;
        country = item.country;
        postCode = item.postCode;
        region = item.region;
        district = item.district;
        typeCity = item.typeCity;
        city = item.city;
        typeStreet = item.typeStreet;
        street = item.street;
        typeHouse = item.typeHouse;
        house = item.house;
        corpus = item.corpus;
        flat = item.flat;
        comment = item.comment;
    }

    var accessLocal = access
        ? { ...access }
        : { add: true, change: true, owner: true, state: true, stateMain: true, delete: true, main: true, mainAuto: true };

    const ownerListLocal = sources.clientTypes ? sources.clientTypes : [];

    const stateListLocal = stateList
        ? stateList
        : sources.accessibleAddressesStatuses && sources.accessibleAddressesStatuses.length > 0
            ? sources.addressStates.filter((t) => sources.accessibleAddressesStatuses.includes(t.id) || t.id == 0)
            : sources.addressStates;

    const selectedState = stateListLocal.find((e) => e.id === state);
    const selectedOwner = ownerListLocal.find((e) => e.id === owner);

    const selectedStateName = selectedState && state > 0 ? selectedState.name : "";
    const selectedOwnerName = selectedOwner && owner > 0 ? selectedOwner.name : "";

    if (!accessLocal.main && accessLocal.mainAuto && !isAutocompleted) {
        accessLocal.main = true;
        accessLocal.mainAuto = false;
    }

    let clear = null;
    if (isContact && (accessLocal.delete || id <= 0) &&
        (selectedState || selectedOwner || country || postCode ||
            region || district || typeCity || city ||
            typeStreet || street || typeHouse || house ||
            corpus || flat || comment)) {
        clear = <Button className="dm-m-left" icon="delete" onClick={clearField} />;
    }

    var notEditVaue = null;
    if (!isEdit || !accessLocal.change) {
        const address = getAddressText(country,
            postCode,
            region,
            district,
            city,
            typeCity,
            street,
            typeStreet,
            house,
            typeHouse,
            corpus,
            flat);
        notEditVaue = <span key="addressText">{address}</span>;
    }

    if (!isEdit) {
        const text = [notEditVaue];
        if (comment)
            text.push(<span key="addressComment">{`, ${comment}`}</span>);
        if (selectedStateName)
            text.push(<span key="addressState" className="badge bg-success dm-m-left">{selectedStateName}</span>);
        if (selectedOwnerName)
            text.push(<span key="addressOwner" className="badge bg-secondary dm-m-left">{selectedOwnerName}</span>);

        return <div>{text}</div>;
    }

    let autocompleteBox = "";
    let mainDisabled = !accessLocal.main && (id > 0 || accessLocal.mainAuto);
    if (isAutocompleted) {
        let mainAutoDisabled = !accessLocal.mainAuto && id > 0;
        autocompleteBox = <AutoComplete data={items}
            suggest={true}
            disabled={mainAutoDisabled}
            textField="name"
            placeholder={window.systemConfig.addressSearchPlaceholder}
            onChange={changeAutocomplete}
            loading={loading}
            style={{ width: "100%", height: "40px" }} />;
    }

    var stateStyle = "dm-m-right";
    if (selectedStateName)
        stateStyle = stateStyle + (selectedState.isPositive ? " dm-positive" : " dm-negative");
    if (item && item.isMain)
        stateStyle = stateStyle + " dm-field-main";

    const ownerStyle = selectedOwnerName
        ? "dm-m-right dm-positive"
        : "dm-m-right";

    var config = window.systemConfig.address;

    var forceRequired = false;
    if (required && !country && !postCode && !region && !district && !typeCity && !typeStreet && !street && !typeHouse && !house && !corpus && !flat)
        forceRequired = true;

    var editors = [];
    var firstEditor;
    if (accessLocal.change) {
        const editorClass = "dm-size-50 dm-size-big-33";
        const inlineClass = "dm-wrapper-content";

        if (config.Country.VisibilityBool === true) {
            editors.push(<Input
                key="AddressPartCountry"
                className={editors.length === 0 ? inlineClass : editorClass}
                disabled={mainDisabled}
                required={forceRequired ? true : config.Country.RequiredBool}
                placeholder={window.captions.Country}
                onChange={e => valueChanged(e, "country")}
                value={country} />);
        }

        if (config.PostCode.VisibilityBool === true) {
            editors.push(<Input
                key="AddressPartPostCode"
                className={editors.length === 0 ? inlineClass : editorClass}
                disabled={mainDisabled}
                required={forceRequired ? true : config.PostCode.RequiredBool}
                placeholder={window.captions.AddressIndex}
                onChange={e => valueChanged(e, "postCode")}
                value={postCode} />);
        }

        if (config.Region.VisibilityBool === true) {
            editors.push(<Input
                key="AddressPartRegion"
                className={editors.length === 0 ? inlineClass : editorClass}
                disabled={mainDisabled}
                required={forceRequired ? true : config.Region.RequiredBool}
                placeholder={window.captions.AddressRegion}
                onChange={e => valueChanged(e, "region")}
                value={region} />);
        }

        if (config.District.VisibilityBool === true) {
            editors.push(<Input
                key="AddressPartDistrict"
                className={editors.length === 0 ? inlineClass : editorClass}
                disabled={mainDisabled}
                required={forceRequired ? true : config.District.RequiredBool}
                placeholder={window.captions.AddressDistrict}
                onChange={e => valueChanged(e, "district")}
                value={district} />);
        }

        if (config.TypeCity.VisibilityBool === true) {
            editors.push(<Input
                key="AddressPartTypeCity"
                className={editors.length === 0 ? inlineClass : editorClass}
                disabled={mainDisabled}
                required={forceRequired ? true : config.TypeCity.RequiredBool}
                placeholder={window.captions.AddressTypeCity}
                onChange={e => valueChanged(e, "typeCity")}
                value={typeCity} />);
        }

        if (config.City.VisibilityBool === true) {
            editors.push(<Input
                key="AddressPartCity"
                className="dm-size-50 dm-size-big-66"
                disabled={mainDisabled}
                required={forceRequired ? true : config.City.RequiredBool}
                placeholder={window.captions.AddressCity}
                onChange={e => valueChanged(e, "city")}
                value={city} />);
        }

        if (config.TypeStreet.VisibilityBool === true) {
            editors.push(<Input
                key="AddressPartTypeStreet"
                className={editors.length === 0 ? inlineClass : editorClass}
                disabled={mainDisabled}
                required={forceRequired ? true : config.TypeStreet.RequiredBool}
                placeholder={window.captions.AddressTypeStreet}
                onChange={e => valueChanged(e, "typeStreet")}
                value={typeStreet} />);
        }

        if (config.Street.VisibilityBool === true) {
            editors.push(<Input
                key="AddressPartStreet"
                className="dm-size-50 dm-size-big-66"
                disabled={mainDisabled}
                required={forceRequired ? true : config.Street.RequiredBool}
                placeholder={window.captions.AddressStreet}
                onChange={e => valueChanged(e, "street")}
                value={street} />);
        }

        if (config.TypeHouse.VisibilityBool === true) {
            editors.push(<Input
                key="AddressPartTypeHouse"
                className={editors.length === 0 ? inlineClass : editorClass}
                disabled={mainDisabled}
                required={forceRequired ? true : config.TypeHouse.RequiredBool}
                placeholder={window.captions.AddressTypeHouse}
                onChange={e => valueChanged(e, "typeHouse")}
                value={typeHouse} />);
        }


        if (config.House.VisibilityBool === true) {
            editors.push(<Input
                key="AddressPartCHouse"
                className={editors.length === 0 ? inlineClass : editorClass}
                disabled={mainDisabled}
                required={forceRequired ? true : config.House.RequiredBool}
                placeholder={window.captions.AddressHouse}
                onChange={e => valueChanged(e, "house")}
                value={house} />);
        }

        if (config.Corpus.VisibilityBool === true) {
            editors.push(<Input
                key="AddressPartCorpus"
                className={editors.length === 0 ? inlineClass : editorClass}
                disabled={mainDisabled}
                required={forceRequired ? true : config.Corpus.RequiredBool}
                placeholder={window.captions.AddressCorpus}
                onChange={e => valueChanged(e, "corpus")}
                value={corpus} />);
        }

        if (config.Flat.VisibilityBool === true) {
            editors.push(<Input
                key="AddressPartFlat"
                className={editors.length === 0 ? inlineClass : editorClass}
                disabled={mainDisabled}
                required={forceRequired ? true : config.Flat.RequiredBool}
                placeholder={window.captions.AddressFlat}
                onChange={e => valueChanged(e, "flat")}
                value={flat} />);
        }

        if (config.Comment.VisibilityBool === true) {
            editors.push(<Input
                key="AddressPartComment"
                className={editorClass}
                required={forceRequired ? true : config.Comment.RequiredBool}
                placeholder={window.captions.Comment}
                onChange={e => valueChanged(e, "comment")}
                value={comment} />);
        }

        firstEditor = editors.shift();
    } else {
        firstEditor = <div className="dm-wrapper-content">{notEditVaue}</div>;
    }

    let stateControl = null;
    if (accessLocal.state) {
        stateControl = <DropDownButton
            onItemClick={e => stateChanged(e)}
            title={selectedStateName}
            icon="stop"
            items={stateListLocal.map((item) => ({ ...item, selected: item.id === state }))}
            textField="name"
            className={stateStyle} />;
    }

    let ownerControl = null;
    if (accessLocal.owner) {
        ownerControl = <DropDownButton
            onItemClick={e => ownerChanged(e)}
            title={selectedOwnerName}
            icon="user"
            items={ownerListLocal.map((item) => ({ ...item, selected: item.id === owner }))}
            textField="name"
            className={ownerStyle} />;
    }

    return <div id="addressContainer" className="dm-container">
        {autocompleteBox}
        <div className="dm-wrapper">
            {stateControl}
            {ownerControl}
            {firstEditor}
            {clear}
        </div>
        {editors}
    </div>;

    function setAutocomplete(items) {
        setItems(items);
        setLoading(false);
    }

    function refreshAutocomplete(field, autoFillType, value) {
        if (!field || !value || value.length <= 2 || isNaN(autoFillType)) {
            setAutocomplete([]);
            return;
        }

        fetchPost(`${window.constants.autocomplete}/${autoFillType}`,
            value,
            (data) => setAutocomplete(data),
            () => setAutocomplete([]));
    }

    function changeAutocomplete(e) {
        const values = e.value.split(",");
        if (!values || values.length < 9) {
            clearTimeout(timeout.current);
            timeout.current = setTimeout(() => {
                refreshAutocomplete(field, autoFillType, e.value);
                valueChanged(e.value, "search");
            },
                delay);

            setLoading(true);

        } else {
            const setValue = (property, value) => {
                if (!value || value.trim() === "")
                    return;

                valueChanged({ value: value.trim() }, property);
            };
            setValue("country", values[0]);
            setValue("postCode", values[1]);
            setValue("region", values[2]);
            setValue("district", values[3]);
            setValue("typeCity", values[4]);
            setValue("city", values[5]);
            setValue("typeStreet", values[6]);
            setValue("street", values[7]);
            setValue("typeHouse", values[8]);
            setValue("house", values.length > 9 ? values[9] : "");
            setValue("flat", values.length > 10 ? values[10] : "");
        }
    }

    function valueChanged(e, property) {
        changedValue({
            fieldId: field,
            item: id,
            property: property,
            value: e.value
        });
    }

    function stateChanged(e) {
        if (!accessLocal.stateMain && ((e.item.id == sources.addressState || state == sources.addressState))) {
            alert(window.captions.ChangeMainStateInPhoneAddres);
            return;
        }

        changedValue({
            fieldId: field,
            item: id,
            property: "state",
            value: e.item.id,
            valueName: e.item.name
        })
    }

    function ownerChanged(e) {
        changedValue({
            fieldId: field,
            item: id,
            property: "owner",
            value: e.item.id,
            valueName: e.item.name
        })
    }

    function clearField() {
        changedValue({
            fieldId: field,
            item: id,
            isClear: true,
            value: null
        });
    }
}