import React from "react";
import { useState, useEffect, useRef } from 'react';
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { TreeView } from "@progress/kendo-react-treeview";
import { itemFinder as nestedItemFinder } from "../../utils/arrayHelper.jsx";

export default function DropDownTreeView({ treeData, selectedId, required, style, className, field, selectionChanged, isHierarchical }) {
    const [value, setValue] = useState(null);
    const [open, setOpen] = useState(false);
    const [id, setId] = useState(0);
    const [allSource, setAllSource] = useState([]);
    const [source, setSource] = useState([]);
    const [loading, setLoading] = useState(true);

    let timeout = useRef(null);
    let selectedItem = useRef(undefined);

    useEffect(() => {
        if (treeData)
            mountProperties();
    }, [selectedId, treeData]);

    let valid = true;
    if (required && (!id || id === 0)) {
        valid = false;
    }

    const sources = allSource;
    const hasChild = sources.find((i) => i.items && i.items.length > 0) != null;
    const filterable = sources.length > 7 || hasChild;
    const props = {};
    if (className)
        props.className = className;
    if (style)
        props.style = style;

    if (hasChild)
        return <DropDownList
            data={["single"]}
            filterable={filterable}
            onFilterChange={filterChange}
            value={value}
            opened={open}
            {...props}
            valid={valid}
            popupSettings={{ animate: false }}
            onBlur={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            loading={loading}
            itemRender={itemRender} />;

    var selectedModel = source.find((i) => i.id === id);
    if (!selectedModel)
        selectedModel = { id: 0, name: "" };

    return <DropDownList
        data={source}
        filterable={filterable}
        onFilterChange={filterChange}
        onChange={onItemChange}
        value={selectedModel}
        {...props}
        textField="name"
        dataItemKey="id"
        valid={valid}
        popupSettings={{ animate: false }}
        loading={loading} />;

    function mountProperties() {
        const id = selectedId ? selectedId : 0;
        const data = treeData === undefined || !treeData
            ? []
            : JSON.parse(JSON.stringify(treeData));

        selectedItem = undefined;
        let name = "";
        if (data) {
            const findElement = itemFinder(id, data);
            if (findElement) {
                findElement.selected = true;
                selectedItem = findElement;
                name = findElement.name;
            }
        }

        setValue(name);
        setOpen(false);
        setId(id);
        setAllSource(data);
        setSource(data);
        setLoading(false);
    }

    function onItemChange(event) {
        const item = event.value;
        if (!item)
            return;

        if (selectionChanged)
            selectionChanged({ ...item, fieldId: field, value: item.id, valueText: item.name });

        selectedItem = item;
        setValue(item.name);
        setId(item.id);
    }

    function onItemClick(event) {
        const item = event.item;
        if (!item)
            return;

        if (!isHierarchical && item.items && item.items.length > 0) {
            item.expanded = !item.expanded;
            return;
        }

        if (selectedItem) {
            selectedItem.selected = false;
        }

        if (selectionChanged)
            selectionChanged({ ...item, fieldId: field, value: item.id, valueText: item.name });

        item.selected = true;
        selectedItem = item;
        setValue(item.name);
        setId(item.id);
        setOpen(false);
    }

    function onExpandChange(event) {
        event.item.expanded = !event.item.expanded;
    }

    function itemRender(li) {
        const itemChildren = <TreeView
            data={source}
            onItemClick={onItemClick}
            onExpandChange={onExpandChange}
            expandIcons={true}
            animate={false}
            textField="name"
            className="dm-drop-tree"
            style={{ width: "100%" }} />;
        const props = li.props;
        return React.cloneElement(li, { ...props, className: "dm-drop-tree-container" }, itemChildren);
    }

    function itemFinder(id, items) {
        return isHierarchical
            ? nestedItemFinder(items, (i) => i.id === id)
            : nestedItemFinder(items, (i) => i.id === id && !(i.items && i.items.length > 0));
    }

    function filterChange(event) {
        clearTimeout(timeout.current);
        timeout.current = setTimeout(() => {
            setSource(event.filter.value
                ? filterData(event.filter.value, JSON.parse(JSON.stringify(allSource)))
                : allSource);
            setLoading(false);
        },
            300);

        setLoading(true);
    }

    function filterData(filter, items) {
        return items.filter(i => {
            if (i.name.toLowerCase().includes(filter.toLowerCase())) {
                i.expanded = true;
                return true;
            }

            var subItems = i.items;
            if (!(subItems && subItems.length > 0))
                return false;

            i.expanded = false;
            subItems = filterData(filter, i.items);
            if (subItems && subItems.length > 0) {
                i.items = subItems;
                i.expanded = true;
                return true;
            }
            return false;
        });
    }


}