import { filterFieldTypes, entitySourcesNames } from "../../../../utils/systemConstants.jsx";

export default {
    getFilterItem(state, fieldId) {
        fieldId = parseInt(fieldId);
        const selected = state.selectedFilters;
        let existed = selected.find((e) => e.id === fieldId);
        if (existed)
            return existed;

        const group = state.filterGroups.find((g) => g.items.find((i) => i.id === fieldId));
        if (!group)
            return null;

        const field = group.items.find((i) => i.id === fieldId);
        existed = {
            id: fieldId,
            values: null,
            type: field.type,
            compareType: field.defaultComparer,
            children: [],
            periodFilter: null
        };
        if (state.entity === entitySourcesNames.collateral && field.fieldAlias === "State") {
            existed.periodFilter = {
                id: fieldId,
                values: null,
                type: field.periodFilter.type,
                compareType: field.periodFilter.defaultComparer,
                children: [],
                periodFilter: null
            };
        }

        selected.push(existed);
        return existed;
    },

    clear(state, fieldId, onEdit) {
        fieldId = parseInt(fieldId);
        let selected = state.selectedFilters;
        if (fieldId === 0) {
            selected = selected.filter((item) => item.id != fieldId);
            state.selectedFilters = selected;

            onEdit(state);
            return;
        }

        const existed = selected.find((e) => e.id === fieldId);
        if (!existed)
            return;

        const index = selected.indexOf(existed);
        if (index < 0)
            return;

        selected.splice(index, 1);
        state.selectedFilters = selected;

        onEdit(state);
    },

    changeFieldChildren(state, fieldId, value, onEdit) {
        const existed = this.getFilterItem(state, fieldId);
        existed.children = value;
        state.selectedFilters = state.selectedFilters;

        onEdit(state)
    },

    changeFieldValue(state, fieldId, value, onEdit) {
        const existed = this.getFilterItem(state, fieldId);
        existed.values = value;
        onEdit(state);
    },

    changeFieldValueFrom(state, fieldId, value, onEdit) {
        const existed = this.getFilterItem(state, fieldId);
        if (existed.values)
            existed.values[0] = value;
        else
            existed.values = [value, null];
        onEdit(state)
    },

    changeFieldValueTo(state, fieldId, value, onEdit) {
        const existed = this.getFilterItem(state, fieldId);
        if (existed.values)
            existed.values[1] = value;
        else
            existed.values = [null, value];
        onEdit(state);
    },

    changeFieldWaterMarkValue(state, fieldId, value, onEdit) {
        const existed = this.getFilterItem(state, fieldId);
        existed.values = value.split(" ").join().replace(/[^0-9\.,]/g, "");
        onEdit(state);
    },

    changeFieldComparer(state, fieldId, value, onEdit) {
        const existed = this.getFilterItem(state, fieldId);
        existed.compareType = value;
        state.selectedFilters = state.selectedFilters;

        onEdit(state);
    },

    changePeriodFilterValueFrom(state, fieldId, value) {
        const existed = this.getFilterItem(state, fieldId);
        if (value != null && existed.type === filterFieldTypes.time)
            value = value.toLocaleTimeString("ru-RU");

        if (existed.periodFilter.values)
            existed.periodFilter.values[0] = value;
        else
            existed.periodFilter.values = [value, null];

        onEdit(state);
    },

    changePeriodFilterValueTo(state, fieldId, value) {
        const existed = this.getFilterItem(state, fieldId);
        if (value != null && existed.type === filterFieldTypes.time)
            value = value.toLocaleTimeString("ru-RU");

        if (existed.periodFilter.values)
            existed.periodFilter.values[1] = value;
        else
            existed.periodFilter.values = [null, value];

        onEdit(state);
    }
}