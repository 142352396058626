import React, { useState, useEffect } from "react";
import { ListBox, ListBoxToolbar, processListBoxData, processListBoxDragAndDrop } from "@progress/kendo-react-listbox";
import { Input } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";

export default function ItemSelector({
    items,
    sourceTypes,
    textField,
    itemKeyField,
    isSelectedField,
    indexField,
    sourceTypeField,
    availableItemRender,
    selectedItemRender,
    selectedItemsChanged,
}) {
    const selectedField = "selected";

    const defaultProps = {
        textField: "name",
        itemKeyField: "id",
        isSelectedField: "isSelected",
        indexField: "index",
        sourceTypeField: "sourceType",
    };

    const initialTools = [
        "moveUp",
        "moveDown",
        "transferTo",
        "transferFrom",
        "transferAllTo",
        "transferAllFrom",
    ];

    const [textFieldSafe, setTextFieldSafe] = useState(defaultProps.textField);
    const [itemKeyFieldSafe, setItemKeyFieldSafe] = useState(defaultProps.itemKeyField);
    const [isSelectedFieldSafe, setIsSelectedFieldSafe] = useState(defaultProps.isSelectedField);
    const [indexFieldSafe, setIndexFieldSafe] = useState(defaultProps.indexField);
    const [sourceTypeFieldSafe, setSourceTypeFieldSafe] = useState(defaultProps.sourceTypeField);

    const [draggedItem, setDraggedItem] = useState({});
    const [itemsFilter, setItemsFilter] = useState("");
    const [itemsSelectedFilter, setItemsSelectedFilter] = useState("");
    const [selectedSourceType, setSelectedSourceType] = useState(null);

    const [selected, setSelected] = useState([]);
    const [unSelected, setUnSelected] = useState([]);

    useEffect(() => mountProperties(), [
        items,
        sourceTypes,
        textField,
        itemKeyField,
        isSelectedField,
        indexField,
        sourceTypeField,
    ]);

    let selectedListProps = { onDragStart: handleDragStart, onDrop: handleDrop, draggable: true };
    let toolsSafe = [...initialTools];

    if (itemsSelectedFilter) {
        selectedListProps = { draggable: false };
        toolsSafe.splice(0, 2);
    }

    return (
        <div className="container">
            <div className="dm-wrapper">
                <div className="dm-wrapper-content">
                    <h4 style={{ width: "100%" }}>{window.captions.Available}</h4>
                    <h4 style={{ width: "100%" }}>{window.captions.Selected}</h4>
                </div>
            </div>
            <div className="dm-wrapper">
                <div className="dm-wrapper-content">
                    {sourceTypes && sourceTypes.length ? (
                        <DropDownList
                            style={{ width: "100%", margin: "5px" }}
                            dataItemKey="id"
                            data={sourceTypes}
                            textField="name"
                            value={selectedSourceType}
                            onChange={onSourceType}
                        />
                    ) : null}
                    <Input
                        style={{ margin: "5px" }}
                        onChange={(e) => onItemsSearch(e)}
                        placeholder={window.captions.Search}
                    />
                    <Input
                        style={{ margin: "5px" }}
                        onChange={(e) => onSelectedSearch(e)}
                        placeholder={window.captions.Search}
                    />
                </div>
            </div>
            <div className="dm-wrapper">
                <div className="dm-wrapper-content">
                    <ListBox
                        draggable={false}
                        style={{
                            height: 350,
                            width: "100%",
                            margin: "5px",
                        }}
                        data={unSelected}
                        textField={textFieldSafe}
                        selectedField={selectedField}
                        item={availableItemRender}
                        toolbarPosition={"right"}
                        onItemClick={(e) => handleItemAvailableClick(e)}
                        toolbar={() => {
                            return (
                                <ListBoxToolbar
                                    tools={toolsSafe}
                                    data={unSelected}
                                    dataConnected={selected}
                                    onToolClick={handleToolBarClick}
                                />
                            );
                        }}
                    />
                    <ListBox
                        style={{
                            height: 350,
                            width: "100%",
                            margin: "5px",
                        }}
                        data={selected}
                        textField={textFieldSafe}
                        selectedField={selectedField}
                        item={selectedItemRender}
                        onItemClick={(e) => handleItemSelectedClick(e)}
                        {...selectedListProps}
                    />
                </div>
            </div>
        </div>
    );

    function mountProperties() {
        const textFieldMount = textField ?? defaultProps.textField;
        const itemKeyFieldMount = itemKeyField ?? defaultProps.itemKeyField;
        const isSelectedFieldMount = isSelectedField ?? defaultProps.isSelectedField;
        const indexFieldMount = indexField ?? defaultProps.indexField;
        const sourceTypeFieldMount = sourceTypeField ?? defaultProps.sourceTypeField;

        const selectedSourceTypeMount =
            sourceTypes && sourceTypes.length
                ? sourceTypes.includes(selectedSourceType)
                    ? selectedSourceType
                    : sourceTypes[0]
                : null;

        setTextFieldSafe(textFieldMount);
        setItemKeyFieldSafe(itemKeyFieldMount);
        setIsSelectedFieldSafe(isSelectedFieldMount);
        setIndexFieldSafe(indexFieldMount);
        setSourceTypeFieldSafe(sourceTypeFieldMount);

        setSelectedSourceType(selectedSourceTypeMount);
        setSelected(
            items
                .filter(
                    (i) =>
                        i[isSelectedFieldMount] &&
                        (!itemsSelectedFilter || i[textFieldMount].match(new RegExp(itemsSelectedFilter, "i")))
                )
                .sort((x, y) => x[indexFieldMount] - y[indexFieldMount])
        );
        setUnSelected(
            items
                .filter(
                    (i) =>
                        !i[isSelectedFieldMount] &&
                        (!selectedSourceTypeMount || i[sourceTypeFieldMount] === selectedSourceTypeMount.id) &&
                        (!itemsFilter || i[textFieldMount].match(new RegExp(itemsFilter, "i")))
                )
                .sort((a, b) => a[textFieldMount].localeCompare(b[textFieldMount]))
        );
    }

    function handleItemAvailableClick(event) {
        handleItemClick(event, unSelected, setUnSelected, selected, setSelected);
    }

    function handleItemSelectedClick(event) {
        handleItemClick(event, selected, setSelected, unSelected, setUnSelected);
    }

    function handleItemClick(event, data, setData, connectedData, setConnectedData) {
        setData(
            data.map((item) => {
                if (item[itemKeyFieldSafe] === event.dataItem[itemKeyFieldSafe]) {
                    return { ...item, [selectedField]: !item[selectedField] };
                } else if (!event.nativeEvent.ctrlKey) {
                    return { ...item, [selectedField]: false };
                }
                return item;
            })
        );
        setConnectedData(
            connectedData.map((item) => {
                return { ...item, [selectedField]: false };
            })
        );
    }

    function handleToolBarClick(e) {
        let toolName = e.toolName || "";
        let result = processListBoxData(unSelected, selected, toolName, selectedField);

        let newUnSelected = [];
        let newSelected = [];

        if (toolName === "moveUp" || toolName === "moveDown") {
            newUnSelected = result.listBoxOneData;
            newSelected = result.listBoxTwoData.map((item, index) => {
                return { ...item, [indexFieldSafe]: index };
            });
            setSelectedItemsChanged(newSelected, newUnSelected, newSelected);
        } else {
            newUnSelected = result.listBoxOneData
                .map((item) => ({
                    ...item,
                    [isSelectedFieldSafe]: false,
                    [indexFieldSafe]: null,
                }))
                .filter(
                    (item) =>
                        (!selectedSourceType || item[sourceTypeFieldSafe] == selectedSourceType.id) &&
                        (!itemsFilter || item[textFieldSafe].match(new RegExp(itemsFilter, "i")))
                )
                .sort((a, b) => a[textFieldSafe].localeCompare(b[textFieldSafe]));

            if (itemsSelectedFilter) {
                let maxIndex = Math.max(...items.map((i) => i[indexFieldSafe] || 0));
                newSelected = result.listBoxTwoData
                    .map((item) => {
                        const updatedItem = { ...item, [isSelectedFieldSafe]: true };
                        if (updatedItem[indexFieldSafe] == null) {
                            updatedItem[indexFieldSafe] = maxIndex++;
                        }
                        return updatedItem;
                    })
                    .filter((item) => item[textFieldSafe].match(new RegExp(itemsSelectedFilter, "i")));

                let globalSelected = items
                    .filter((i) => i[isSelectedFieldSafe])
                    .sort((x, y) => x[indexFieldSafe] - y[indexFieldSafe]);
                setSelectedItemsChanged(newSelected, newUnSelected, globalSelected);
            } else {
                newSelected = result.listBoxTwoData.map((item, index) => ({
                    ...item,
                    [isSelectedFieldSafe]: true,
                    [indexFieldSafe]: index,
                }));

                setSelectedItemsChanged(newSelected, newUnSelected, newSelected);
            }
        }
    }

    function setSelectedItemsChanged(selected, unSelected, selectedGlobal) {
        setUnSelected(unSelected);
        setSelected(selected);

        if (selectedItemsChanged) selectedItemsChanged(selectedGlobal);
    }

    function handleDragStart(e) {
        setDraggedItem(e.dataItem);
    }

    function handleDrop(e) {
        let result = processListBoxDragAndDrop(
            unSelected,
            selected,
            draggedItem,
            e.dataItem,
            itemKeyFieldSafe
        );

        const newSelected = result.listBoxTwoData.map((item, index) => ({
            ...item,
            [isSelectedFieldSafe]: true,
            [indexFieldSafe]: index,
        }));

        setSelected(newSelected);

        if (selectedItemsChanged) selectedItemsChanged(newSelected);
    }

    function onItemsSearch(e) {
        const value = e.value;
        setItemsFilter(value);
        setUnSelected(
            items
                .filter(
                    (i) =>
                        !i[isSelectedFieldSafe] &&
                        (!selectedSourceType || i[sourceTypeFieldSafe] == selectedSourceType.id) &&
                        (!value || i[textFieldSafe].match(new RegExp(value, "i")))
                )
                .sort((a, b) => a[textFieldSafe].localeCompare(b[textFieldSafe]))
        );
    }

    function onSelectedSearch(e) {
        const value = e.value;
        setItemsSelectedFilter(value);
        setSelected(
            items
                .filter(
                    (i) =>
                        i[isSelectedFieldSafe] &&
                        (!value || i[textFieldSafe].match(new RegExp(value, "i")))
                )
                .map((item) => ({ ...item, [selectedField]: false }))
        );
    }

    function onSourceType(e) {
        const value = e.value;
        setSelectedSourceType(value);
        setUnSelected(
            items
                .filter(
                    (i) =>
                        !i[isSelectedFieldSafe] &&
                        (!value || i[sourceTypeFieldSafe] === value.id) &&
                        (!itemsFilter || i[textFieldSafe].match(new RegExp(itemsFilter, "i")))
                )
                .sort((a, b) => a[textFieldSafe].localeCompare(b[textFieldSafe]))
        );
    }
}
