import React from "react";
import { useState, useEffect, useRef } from 'react';
import CardReducerFunctions from "../cardReducerFunctions.jsx";
import { renderField } from "../cardRenderFunctions.jsx";

export default function FieldsControl({ fields, isEditMode, entity, id, sources,
    phones, addresses, emails, urls, accounts,
    verificationNewPhone, dialogConfirmVerification, setFields }) {

    const [renderFields, setRenderFields] = useState(null);

    useEffect(() => {
        if (!fields)
            return;

        if (!renderFields) {
            for (let key in fields) {
                const field = fields[key];
                if (field && field.isEditable && !field.isContact)
                    fields[key].oValue = field.value;
            }
            setRenderFields(fields);
            return;
        }

        if (Object.keys(fields).length !== Object.keys(renderFields).length)
            setRenderFields(fields);
    }, [fields]);

    if (!renderFields || Object.keys(renderFields).length <= 0)
        return <div />;

    let localEntity = entity;
    if (!localEntity)
        localEntity = "Entity";

    const items = [];
    for (let key in renderFields) {
        if (!Object.prototype.hasOwnProperty.call(renderFields, key))
            continue;

        const fieldModel = renderFields[key];
        if (!fieldModel)
            continue;

        items.push(renderField(fieldModel,
            `${localEntity}${key}`,
            sources,
            phones,
            addresses,
            emails,
            urls,
            accounts,
            isEditMode,
            { entity: entity, entityId: id },
            {
                verificationNewPhone,
                dialogConfirmVerification,
                addContact,
                changedValue: (e) => {
                    if (e.item !== undefined) {
                        changeContact(e);
                    } else if (e.isPassport) {
                        changePassportField(e);
                        return;
                    } else {
                        changeField(e);
                    }
                },
                fileUpload
            }));
    }

    return items;

    function shallowFields() {
        var updatedFields = [];
        for (let key in renderFields) {
            updatedFields[key] = renderFields[key];
        }
        return updatedFields;
    }

    function changeField(payload) {
        if (CardReducerFunctions.fieldEdited(renderFields, payload) && setFields) {
            setFields(shallowFields(), id, entity);
        }
    }

    function fileUpload(e) {
        if (!e.response)
            return;

        const files = e.response.response;
        if (!files || files.length === 0)
            return;

        const file = files[0];
        e.affectedFiles[0].fileName = file.fileName;
        changeField({ fieldId: e.fieldId, value: file.fileName });
    }

    function changeContact(payload) {
        if (CardReducerFunctions.contactEdited(false,
            sources,
            renderFields,
            phones,
            addresses,
            emails,
            urls,
            accounts,
            payload) &&
            setFields) {
            setFields(renderFields, id, entity);
        }
    }

    function addContact(fieldId, typeItem) {
        if (CardReducerFunctions.contactAdded(renderFields,
            sources,
            phones,
            addresses,
            emails,
            urls,
            accounts,
            fieldId,
            typeItem) &&
            setFields) {
            setFields(renderFields, id, entity);
        }
    }

    function changePassportField(payload) {
        if (CardReducerFunctions.passportEdited(renderFields, payload) &&
            setFields) {
            setFields(renderFields, id, entity); // TODO clone fields
        }
    }
}