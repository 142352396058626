import React, { useState, useEffect } from 'react';
import LoadingMask from "../../../components/loadingMask.jsx";
import { Button } from "@progress/kendo-react-buttons";
import { ItemsTreeView } from "../sharedUi/controls/itemsTreeView.jsx";
import { VariableComponent } from "./controls/variables/variableUi.jsx";
import { folderIcon, eyeIcon, eyeSlashIcon } from "@progress/kendo-svg-icons";
import WizardFunctions from '../sharedUi/wizardFunctions.jsx';
import { fetchPost } from '../../../utils/requestsHelper.jsx';
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

export function VariablesTab({ template, languages, onEdit, onDelete }) {
    const [currentTemplate, setCurrentTemplate] = useState();
    const [selectedVariable, setSelectedVariable] = React.useState();
    const [isLoaded, setIsLoaded] = useState(false);
    const [names, setNames] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        setCurrentTemplate(template);
    }, [template]);

    useEffect(() => {
        var list = [];
        template.variables.map((group) => {
            group.items.map((variable) => {
                list.push({ groupId: group.id, id: variable.id, name: variable.caption });
            })
        })

        setNames(list);
    }, [template.variables]);

    if (!currentTemplate || currentTemplate != template) {
        setCurrentTemplate(template);
    }

    if (!currentTemplate || isLoaded) {
        return <div style={{ height: "300px" }}><LoadingMask /></div>;
    }

    const updateItem = (model, selectedIndex) => {
        let data = currentTemplate.variables;
        const separator = "_";

        if (selectedIndex.indexOf(separator) < 0) {
            copyAndUpdateItem(Number(selectedIndex), data, model.item);
        } else {
            const parentIndex = Number(selectedIndex.split(separator)[0]);
            const childIndex = Number(selectedIndex.split(separator)[1]);

            data[parentIndex] = { ...data[parentIndex] };

            data[parentIndex].items = data[parentIndex].items.slice();
            copyAndUpdateItem(childIndex, data[parentIndex].items, model.item);
        }

        var newTemplate = currentTemplate;
        newTemplate.variables = data;
        setCurrentTemplate(newTemplate);
        onEdit(newTemplate);
    };

    const copyAndUpdateItem = (itemIndex, siblings, newData) => {
        siblings[itemIndex] = { ...siblings[itemIndex] };
        siblings[itemIndex] = newData;
    };

    const handleEdit = (model) => {
        updateItem(model, model.index);
    };

    const handleAdd = (item) => {
        if (item) {
            addVariableItem(item);
            return;
        }

        let variableNames = [];
        currentTemplate.variables.forEach(item => variableNames.push({ id: variableNames.length, name: "caption" in item ? item.caption : item.name }));
        var request = {
            names: variableNames,
            type: template.type,
            isReport: true,
            id: currentTemplate.id,
            isGroup: true
        };

        addVariableItem(request);
    };

    const handleDelete = () => {
        if (!selectedVariable)
            return;

        if (!confirm(window.captions.DeleteItemConfirm))
            return;

        if (selectedVariable.item.id !== 0) {
            onDelete(WizardFunctions.createDeletedModel(selectedVariable.item.id, "items" in selectedVariable.item ? 3 : 4));
            if ("items" in selectedVariable.item) {
                selectedVariable.item.items.map((item) => {
                    onDelete(WizardFunctions.createDeletedModel(item.id, 4));
                });
            }
        }

        var newTemplates = currentTemplate;
        newTemplates.variables = WizardFunctions.removeItemByObject(currentTemplate.variables, selectedVariable.item);

        onEdit(newTemplates);
        setSelectedVariable(null)
    };

    const handleSelectScript = (model) => {
        setSelectedVariable(model)
    };
    const paddingY = "dm-m-bottom dm-m-top";
    const fullWidth = { width: "100%" };

    const onCloseError = () => {
        setError(null);
        setCurrentTemplate(template);
    };

    if (error) {
        return <Dialog width={400}>
            <MessageMask inline error={error} />
            <DialogActionsBar>
                <Button onClick={() => { onCloseError() }}>{window.captions.Close}</Button>
            </DialogActionsBar>
        </Dialog>;
    }

    return <div className="k-d-flex-row" style={{ minHeight: "300px" }} key="reportSqlView">
        <div>
            <div>
                <Button
                    key="addItem"
                    icon="add"
                    className={`dm-m-right ${paddingY}`}
                    onClick={() => handleAdd()} />
                <Button
                    key="deleteItem"
                    icon="delete"
                    disabled={!selectedVariable}
                    className={`dm-m-right dm-negative-filled ${paddingY}`}
                    onClick={handleDelete} />
            </div>
            <ItemsTreeView key="variablesItems"
                items={currentTemplate.variables}
                differentIcon={{
                    parameter: "isHidden",
                    value: true
                }}
                iconsLevel={{ 0: folderIcon, 1: eyeIcon, "-1": eyeSlashIcon }}
                onSelect={handleSelectScript}
                useAddItem={true}
                addItemHandler={handleAdd}
                selectedItem={selectedVariable} />
        </div>
        <div style={fullWidth}>
            {selectedVariable
                ? <VariableComponent variable={selectedVariable}
                    languages={languages}
                    source={template.source}
                    names={names}
                    onEdit={handleEdit} />
                : <div className="dm-rules-users">
                    <div className="select-request">
                        {window.captions.ChoseOrAddElement}
                    </div>
                </div>}
        </div>
    </div>

    function addVariableItem(request) {
        var parent = request.parent;

        setIsLoaded(true);
        fetchPost(`${window.constants.addWizardVariableItem}`,
            request,
            model => {
                if (model) {
                    var newTemplate = currentTemplate;

                    if (request.isGroup) {
                        newTemplate.variables.push(model);
                    }
                    else {
                        var item = WizardFunctions.findObject(newTemplate.variables, parent);
                        if (!item)
                            return;

                        item.items.push(model);
                    }
                    
                    setCurrentTemplate(newTemplate);
                    onEdit(newTemplate);

                    setIsLoaded(false);
                }
            },
            ex => showError(ex)
        );
    };

    function showError(ex) {
        setError(ex);
        setIsLoaded(false);
    }
}