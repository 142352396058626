import React from 'react';
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { statGeneralTypeEnum } from "../../../utils/systemConstants.jsx";
import { Button } from "@progress/kendo-react-buttons";
import { StackLayout } from "@progress/kendo-react-layout";
import { useLocation } from "react-router-dom";

export function FolderUI({ handleSelectTab, currentTemplate, folderContent, onAddItem, getRequest }) {
    const location = useLocation();
    const isReports = location.pathname.includes("reports");

    var type = null;
    var typeAlt = null;

    var buttonAdd = "";
    var buttonAddAlt = "";
    if (isReports) {
        buttonAdd = window.captions.AddReport;
        buttonAddAlt = window.captions.AddSqlReport;

        type = statGeneralTypeEnum.report;
        typeAlt = statGeneralTypeEnum.reportSql;
    }
    else {
        buttonAdd = window.captions.AddDocument;
        buttonAddAlt = window.captions.AddGroupDocument;

        type = statGeneralTypeEnum.document;
        typeAlt = statGeneralTypeEnum.documentGroup;
    }

    return <TabStrip selected={0}
        key="folderUi"
        style={{ maxWidth: "1500px" }}
        onSelect={handleSelectTab} >
        <TabStripTab title={currentTemplate.name}>
            <div className="dm-full-height">
                {folderContent}
                <StackLayout orientation="horizontal"
                    align={{ horizontal: "start" }}
                    gap={4}
                    className="dm-m-top dm-m-left">
                    <Button key="addWizardItem" onClick={() => onAddItem(getRequest(type))}>
                        {buttonAdd}
                    </Button>
                    <Button key="addWizardItemAlt" onClick={() => onAddItem(getRequest(typeAlt))}>
                        {buttonAddAlt}
                    </Button>
                </StackLayout>
            </div>
        </TabStripTab>
    </TabStrip>;
}