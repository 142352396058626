import React, { useState, useEffect } from 'react';
import { Checkbox, TextBox } from "@progress/kendo-react-inputs";
import { TreeView } from "@progress/kendo-react-treeview";
export default function ViewEditTreeView(props) {
    const [state, setState] = useState({
        textField: "name",
        viewField: "viewing",
        editField: "editing",
        dataTree: null,
        filteredDataTree: null,
        searchText: ''
    });

    useEffect(() => {
        mountProperties();
    }, [props.dataTree, props.viewField, props.textField, props.editField]);

    useEffect(() => {
        filterDataTree();
    }, [state.dataTree, state.searchText]);

    return (
        <div className="container">
            <TextBox
                className="dm-m-bottom"
                type="text"
                value={state.searchText}
                onChange={onSearchChange}
                placeholder={window.captions.MainTabCommonSearch}
            />
            <TreeView
                expandIcons={true}
                data={state.filteredDataTree}
                item={renderItem}
                textField={state.textField}
                onExpandChange={onExpandChange}
            />
        </div>
    );

    function mountProperties() {
        const textField = props.textField ?? "name";
        const viewField = props.viewField ?? "viewing";
        const editField = props.editField ?? "editing";

        const dataTree = Array.isArray(props.dataTree) && props.dataTree.length ? props.dataTree : [];

        setState(prevState => ({
            ...prevState,
            textField: textField,
            viewField: viewField,
            editField: editField,
            dataTree: dataTree,
        }));
    }

    function onExpandChange(event) {
        event.item.expanded = !event.item.expanded;
        setState(prevState => ({ ...prevState }));
    }

    function onCheckChange(e, item, field, dependentField) {
        var updateFields = [field];
        if (dependentField && e.value) {
            updateFields.push(dependentField);
        }
        setValue(item, updateFields, e.value);
        updateParents(item, updateFields);

        if (props.onCheckChange)
            props.onCheckChange(item);

        setState(prevState => ({ ...prevState }));
    }

    function updateParents(item, fields = []) {
        var parent = item.getParent ? item.getParent() : null;
        if (!parent) {
            return;
        }
        updateItemByChildrenValue(parent, fields);
    }

    function updateItemByChildrenValue(parent, fields = []) {
        var result = {};
        var fieldsClone = fields.slice();
        endUpdate:
        for (var itemKey in parent.items) {
            var item = parent.items[itemKey];
            for (var fieldKey in fieldsClone) {
                var field = fieldsClone[fieldKey];
                if (result[field] === null) {
                    fieldsClone.splice(fieldKey, 1);
                    if (fieldsClone.length === 0)
                        break endUpdate;
                    continue;
                }
                result[field] = getNewCheckboxValue(result[field], item[field]);
            }
        }

        var needUpdateField = [];
        fields.forEach(i => {
            if (parent[i] !== result[i]) {
                parent[i] = result[i];
                needUpdateField.push(i);
            }
        });
        if (needUpdateField.length > 0)
            updateParents(parent, needUpdateField);
    }

    function getNewCheckboxValue(oldValue, newValue) {
        if (oldValue) {
            return newValue ? oldValue : null;
        } else if (oldValue === false) {
            return newValue || newValue === null ? null : false;
        } else if (oldValue === null) {
            return null;
        } else {
            return newValue;
        }
    }

    function updateChildrens(item, fields = [], newValue) {
        if (!item.items)
            return;
        item.items.forEach(i => setValue(i, fields, newValue));
    }

    function setValue(item, fields = [], newValue) {
        var fieldsNeedUpdate = [];
        fields.forEach(i => {
            if (item[i] === newValue)
                return;
            item[i] = newValue;
            fieldsNeedUpdate.push(i);
        });
        updateChildrens(item, fieldsNeedUpdate, newValue);
    }

    function renderItem(props) {
        return (
            <>
                <div className="k-d-flex-row">
                    <Checkbox
                        key={`CheckboxView_${props.itemHierarchicalIndex}`}
                        value={props.item[state.viewField]}
                        disabled={props.item[state.editField]}
                        onChange={(e) => onCheckChange(e, props.item, state.viewField)}
                    >
                        <i className={`dm-i dm-i-eye`} />
                    </Checkbox>
                    <Checkbox
                        key={`CheckboxEdit_${props.itemHierarchicalIndex}`}
                        value={props.item[state.editField]}
                        onChange={(e) => onCheckChange(e, props.item, state.editField, state.viewField)}
                    >
                        <label className={"k-checkbox-label"} style={{ display: "inline-block" }}>
                            <i className={`dm-i dm-i-pen`} />
                            {props.item[state.textField]}
                        </label>
                    </Checkbox></div>
            </>
        );
    }

    function onSearchChange(event) {
        const value = event.target.value;
        setState(prevState => ({ ...prevState, searchText: value }));
    }

    function filterDataTree() {
        if (!state.searchText) {
            setState(prevState => ({ ...prevState, filteredDataTree: prevState.dataTree }));
            return;
        }

        const filteredData = filterTree(state.dataTree, state.searchText, state.textField);
        setState(prevState => ({ ...prevState, filteredDataTree: filteredData }));
    }

    function filterTree(items, searchText, textField) {
        const containsText = (str, search) => {
            return str.toLowerCase().includes(search.toLowerCase());
        };

        return items
            .map(item => {
                const children = item.items ? filterTree(item.items, searchText, textField) : [];

                if (containsText(item[textField], searchText) || (children && children.length)) {
                    return { ...item, items: children, expanded: true };
                }
                return null;
            })
            .filter(item => item !== null);
    }
}