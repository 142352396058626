import React, { useState, useEffect } from 'react';
import LoadingMask from "../../../../../components/loadingMask.jsx";
import WizardFunction from "../../wizardFunctions.jsx";
import { Checkbox } from "@progress/kendo-react-inputs";
import { renderPair } from "../../../../cards/cardRenderFunctions.jsx";
import { TextBox } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { useLocation } from "react-router-dom";

export function GeneralVariableTab({ variable, onEdit }) {
    const [currentVariable, setCurrentVariable] = useState();
    const location = useLocation();

    const isReports = location.pathname.includes("reports");

    useEffect(() => {
        setCurrentVariable(variable.item);
    }, [variable.item]);

    if (!currentVariable) {
        return <div style={{ height: "300px" }}><LoadingMask /></div>;
    }

    const changeParameterHandler = (e, type) => {
        var newVariable = currentVariable;
        switch (type) {
            case 0:
                newVariable.isIgnoreDateFilter = e.value
                break;
            case 1:
                newVariable.isHidden = e.value
                break;
        }

        setCurrentVariable(newVariable);
        onEdit({
            index: variable.index,
            item: newVariable
        });
    };

    const changeNameHandler = (e) => {
        var newVariable = currentVariable;
        newVariable.caption = e.value;
        setCurrentVariable(newVariable);
        onEdit({
            index: variable.index,
            item: newVariable
        });
    };

    const changeLinkNameHandler = (e) => {
        var newVariable = currentVariable;
        newVariable.caption = e.value;
        setCurrentVariable(newVariable);
        onEdit({
            index: variable.index,
            item: newVariable
        });
    };

    const changeTypeHandler = (e) => {
        var newVariable = currentVariable;
        newVariable.type = e.value.id;
        newVariable.totalShowName = "";
        setCurrentVariable(newVariable);
        onEdit({
            index: variable.index,
            item: newVariable
        });
    };

    const changeDataTypeHandler = (e) => {
        var newVariable = currentVariable;
        newVariable.dataArray = e.value.id;
        setCurrentVariable(newVariable);
        onEdit({
            index: variable.index,
            item: newVariable
        });
    };

    const changeConvertCodeHandler = (e) => {
        var newVariable = currentVariable;
        newVariable.convertCode = e.value.id;
        setCurrentVariable(newVariable);
        onEdit({
            index: variable.index,
            item: newVariable
        });
    };

    const changePeriodDelayHandler = (e) => {
        var newVariable = currentVariable;
        newVariable.periodDelay = e.value.id;
        setCurrentVariable(newVariable);
        onEdit({
            index: variable.index,
            item: newVariable
        });
    };

    var content = []
    const style = { width: "100%" };
    const types = WizardFunction.getVarTypes();
    const dataArrayTypes = WizardFunction.getDataArrayTypes(isReports);
    const convertsCodes = WizardFunction.getConvertCodes();
    const periods = WizardFunction.getPeriods();

    renderPair(content,
        window.captions.Caption,
        `VariableName_${currentVariable.id}`,
        () => <TextBox key="VariableName"
            value={currentVariable.caption}
            onChange={changeNameHandler} />
    );

    renderPair(content,
        window.captions.VariableInFile,
        `VariableLinkName_${currentVariable.id}`,
        () => <TextBox key="VariableLinkName"
            value={currentVariable.linkName}
            onChange={changeLinkNameHandler} />
    );

    renderPair(content,
        "",
        "IsIgnoreDateFilter",
        () => <Checkbox key="isIgnoreDateFilter" value={currentVariable.isIgnoreDateFilter}
            onChange={(e) => changeParameterHandler(e, 0)}
            label={window.captions.IgnoreFilterByDate} />,
        true);

    renderPair(content,
        "",
        "IsHidden",
        () => <Checkbox key="isHidden" value={currentVariable.isHidden}
            onChange={(e) => changeParameterHandler(e, 1)}
            label={window.captions.DoNotPrintVariableToResults} />,
        true);

    renderPair(content,
        window.captions.VariableType,
        "VariableType",
        () => <DropDownList style={style}
            data={types}
            textField="text"
            dataItemKey="id"
            onChange={changeTypeHandler}
            value={types.find(type => type.id === currentVariable.type)} />
    );

    if (currentVariable.type === 0) {
        renderPair(content,
            window.captions.DataArray,
            "VariableDataArrayType",
            () => <DropDownList style={style}
                data={dataArrayTypes}
                textField="text"
                dataItemKey="id"
                onChange={changeDataTypeHandler}
                value={dataArrayTypes.find(arraytype => arraytype.id === currentVariable.dataArray)} />
        );
    }

    if (currentVariable.type === 0 && !isReports) {
        renderPair(content,
            window.captions.ConvertToCode,
            "VariableConvertCode",
            () => <DropDownList style={style}
                data={convertsCodes}
                textField="text"
                dataItemKey="id"
                onChange={changeConvertCodeHandler}
                value={convertsCodes.find(arraytype => arraytype.id === currentVariable.convertCode)} />
        );
    }

    if (currentVariable.type === 0) {
        renderPair(content,
            window.captions.Period,
            "VariablePeriodDelay",
            () => <DropDownList style={style}
                data={periods}
                textField="text"
                dataItemKey="id"
                onChange={changePeriodDelayHandler}
                value={periods.find(period => period.id === currentVariable.periodDelay)} />
        );
    }

    return <div className="k-m-1">{content}</div>;
}