import React from "react";
import { getCellStyleAndValue } from "../../utils/pluginHelpers.jsx";

export default function GridTimeLoadCell({ dataItem, field, tdProps, style, className, childField }) {
    let value = childField
        ? dataItem[childField]
        : field;

    const props = { style: style, ...tdProps };
    if (className)
        props.className = className;
    if (!value)
        return <td {...props}></td>;

    const formattedTime = value ? formatTime(value) : '00:00:00.000';

    const plugin = getCellStyleAndValue({ value: formattedTime });
    props.style = plugin.style;
    value = typeof plugin.value === "object" ? null : plugin.value;

    return (<td {...props}><div className="dm-text-cell">{formattedTime}</div></td>);

    function formatTime(timeObj) {
        const pad = (num, size = 2) => String(num).padStart(size, '0');

        let totalMilliseconds = "00:00:00.000";

        if (typeof timeObj.totalMilliseconds === 'number') {
            totalMilliseconds = Math.floor(timeObj.totalMilliseconds);
        } else {
            totalMilliseconds =
                ((timeObj.days || 0) * 86400 +
                    (timeObj.hours || 0) * 3600 +
                    (timeObj.minutes || 0) * 60 +
                    (timeObj.seconds || 0)) * 1000 +
                (timeObj.milliseconds || 0);
        }

        const hours = Math.floor(totalMilliseconds / 3600000);
        const minutes = Math.floor((totalMilliseconds % 3600000) / 60000);
        const seconds = Math.floor((totalMilliseconds % 60000) / 1000);
        const milliseconds = totalMilliseconds % 1000;

        return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}.${pad(milliseconds, 3)}`;
    }
}