import React, { useState, useEffect } from 'react';
import LoadingMask from "../../../components/loadingMask.jsx";
import WizardFunction from "./wizardFunctions.jsx";
import sharedUiRenderFunctions from "./controls/sharedUiRenderFunctions.jsx";
import { PanelBar, PanelBarUtils } from "@progress/kendo-react-layout";

export function DataFilterTab({ template, onEdit }) {
    const [currentTemplate, setCurrentTemplate] = useState();

    useEffect(() => {
        setCurrentTemplate(template);
    }, [template]);

    if (!currentTemplate) {
        return <div style={{ height: "300px" }}><LoadingMask /></div>;
    }

    const onEditFilters = (item) => {
        var template = currentTemplate;
        template.addonFilter = item;
        setCurrentTemplate(template);
        onEdit(template);
    };

    var content = [];
    var panelItems = [];
    var entity = WizardFunction.findEntitySourceByValue(currentTemplate.source);

    const filterGroups = sharedUiRenderFunctions.renderForm(currentTemplate.addonFilter, onEditFilters, entity, false);
    filterGroups.map((group) => {
        panelItems.push(group);
    });

    if (panelItems.length > 0)
        content.push(<PanelBar key={`PanelBarParameters${currentTemplate.id}`} className="k-panelbar dm-m-bottom">
            {PanelBarUtils.mapItemsToComponents(panelItems)}
        </PanelBar>);

    return <div style={{ maxHeight: "750px", overflow: "auto" }}>{content}</div>
}