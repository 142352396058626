import React, { forwardRef, useState, useEffect } from "react";
import { formatDate, formatNumber } from "@progress/kendo-intl";
import { dateFormat } from "../../../utils/systemConstants.jsx";
import { GridBaseComponent, createdSort } from "../../../components/gridApiSource.jsx";
import { toDataSourceRequestString, translateDataSourceResultGroups } from "@progress/kendo-data-query";
import { GridColumn } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import GridDateTimeCell from "../../../components/cells/gridDateTimeCell.jsx";
import GridColorCell from "../../../components/cells/gridColorCell.jsx";
import GridFullTextCell from "../../../components/cells/gridFullTextCell.jsx";
import gridFunctions from "../../../components/gridFunctions.jsx";
import GridLoadingPanel from "../../../components/gridLoadingPanel.jsx";
import { getLinkCellStyle } from "../../../utils/pluginHelpers.jsx";
import { getUiCulture } from "../../../utils/authHelper.jsx";
import { fetchGet } from '../../../utils/requestsHelper.jsx';
import { Grid } from "../../../../node_modules/@progress/kendo-react-grid/index.js";
import LoadingMask from "../../../components/loadingMask.jsx";
import ErrorMask from "../../../components/errorMask.jsx";

const CustomHistoryControl = forwardRef(function CustomHistoryControl(props, ref) {
    const [customColumns, setCustomColumns] = useState([]);
    const [columnsOrder, setColumnsOrder] = useState(null);
    const [dataState, setDataState] = useState({
        skip: 0,
        take: 20,
        sort: createdSort == undefined ? [] : createdSort,
        filter: []
    });
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [pending, setPending] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState(null);
    const columns = [];
    const showTitle = window.systemConfig.historyTitle.indexOf(props.source) >= 0;

    useEffect(() => {
        historyColumnsLoad();
    }, []);

    useEffect(() => {
        if (isLoaded)
            historyLoad();
    }, [isLoaded]);

    if (!isLoaded)
        return <LoadingMask />;

    if (error) {
        return <ErrorMask {...props} error={error} inline />;
    }

    let pager = undefined;
    if (total <= dataState.take)
        pager = false;

    const panel = pending ? <GridLoadingPanel /> : null;
    
    if (typeof columnsOrder === 'object' && columnsOrder !== null) {
        for (const key of Object.keys(columnsOrder).sort((key1, key2) => columnsOrder[key1] - columnsOrder[key2])) {
            var column = customColumns.find(item => item.column == key);
            if (column) {
                columns.push(gridFunctions.renderGridColumn(column, columnsOrder[key]));
                continue;
            }

            column = GetDefaultColumn(key);
            if (!column)
                continue;

            columns.push(column);
        }
    }
    

    return <>
        <Grid className="dm-full-wh"
            ref={ref}
            sortable={true}
            pageable={pager}
            resizable={true}
            total={total}
            data={data}
            {...dataState}
            onDataStateChange={handleDataStateChange}
            selectedField="Selected">
            {columns}
        </Grid>
        {panel}
    </>;

    function handleDataStateChange(changeEvent) {
        const newState = {
            skip: changeEvent.dataState.skip ? changeEvent.dataState.skip : 0,
            take: changeEvent.dataState.take ? changeEvent.dataState.take : 20,
            sort: changeEvent.dataState.sort,
            group: changeEvent.dataState.group
        };

        if (newState.group && gridFunctions.hasDuplicatesGroups(newState.group))
            return;

        historyLoad(newState);
    }

    function handleOnMap(dataItem) {
        var latitude = parseFloat(dataItem["latitude"]);
        var longitude = parseFloat(dataItem["longitude"]);
        if (!isNaN(latitude) && !isNaN(longitude)) {
            props.dialogMap(true, { latitude, longitude });
        }
    }

    function GridPromiseCell({ dataItem, tdProps, style, className }) {
        const paymentDate = dataItem["paymentDate"];
        const promiseDate = dataItem["promiseDate"];
        const declaredDate = dataItem["declaredDate"];
        const props = { ...tdProps };
        if (style)
            props.style = style;
        if (className)
            props.className = className;

        var data = [];
        var culture = getUiCulture();
        if (paymentDate) {
            data.push(dateValueBlock("pay", culture, window.captions.Promiss, paymentDate, dataItem["paymentValue"]));
        }
        if (declaredDate) {
            data.push(dateValueBlock("dec", culture, window.captions.Declared, declaredDate, dataItem["declaredValue"]));
        }
        if (promiseDate) {
            data.push(dateValueBlock("pro", culture, window.captions.PromiseScheduler, promiseDate, dataItem["promiseValue"]));
        }

        return <td {...props}>
            {data}
        </td>;
    }

    function dateValueBlock(key, culture, caption, data, value) {
        return <div key={key}>{caption} {formatDate(new Date(data), dateFormat, culture)} / <span className="dm-positive">{formatNumber(new Number(value), "n2", culture)}</span></div>;
    }

    function historyColumnsLoad() {
        fetchGet(`${window.constants.cardSourceGetHistoryCustomColumns}`,
            data => {
                if (data) {
                    setCustomColumns(data.columns);
                    setColumnsOrder(data.columnsOrder);
                    setIsLoaded(true);
                    setPending(true);

                } else
                    loadedError(window.captions.LoadDataError);
            },
            ex => loadedError(ex)
        );
    }

    function historyLoad(newState) {
        var fetchDataState = dataState;
        if (newState) {
            setDataState(newState);
            fetchDataState = newState;
        }

        const hasGroups = dataState.group && dataState.group.length;
        const requestUrl =
            `${window.constants.cardSourceCustomHistory}/${props.source}/${props.id}?${toDataSourceRequestString(fetchDataState)}`;

        setPending(true);
        fetchGet(requestUrl,
            model => {
                if (model) {
                    setData(hasGroups ? translateDataSourceResultGroups(model.data) : model.data);
                    setTotal(model.total);
                    setError(null);
                    setPending(false);
                } else
                    loadedError(window.captions.LoadDataError);
            },
            ex => loadedError(ex)
        );
    }

    function loadedError(ex) {
        setError(ex);
        setIsLoaded(true);
    }

    

    function GetDefaultColumn(columnName) {
        switch (columnName) {
            case "color":
                return <GridColumn key="color" field="color" title=" " width="35" cells={{ data: GridColorCell }} />;
            case "title":
                return <GridColumn key="title" field="title" title={window.captions.EntityName} width="240" />;
            case "created":
                return <GridColumn key="created" field="created" title={window.captions.Created} width="160" cells={{ data: GridDateTimeCell }} />;
            case "contactType":
                return <GridColumn key="contactType" field="contactType" title={window.captions.WordType} width="240" />;
            case "contactResult":
                return <GridColumn key="contactResult" field="contactResult" title={window.captions.Result} width="240" />;
            case "text":
                return <GridColumn key="text" field="text" title={window.captions.Comment} width="300" cells={{ data: GridFullTextCell }} />;
            case "contact":
                return <GridColumn key="contact" field="contact" title={window.captions.ContactPerson} width="240" />;
            case "notification":
                return <GridColumn key="notification" field="notification" title={window.captions.Notification} width="160" cells={{ data: GridDateTimeCell }} />;
            case "paymentDate":
                return <GridColumn key="paymentDate" field="paymentDate" title={window.captions.PromissDeclared} width="160" cells={{ data: GridPromiseCell }} />;
            case "author":
                return <GridColumn key="author" field="author" title={window.captions.Author} width="240" />;
            case "userGroup":
                return <GridColumn key="userGroup" field="userGroup" title={window.captions.Group} width="240" />;
            case "location":
                const mapCell = (p) => p.dataItem["location"]
                    ? <td {...getLinkCellStyle(p)}>
                        {p.dataItem["location"]}
                        <Button icon="globe" fillMode="flat" onClick={() => handleOnMap(p.dataItem)} />
                    </td>
                    : <td {...getLinkCellStyle(p)} />;
                return <GridColumn key="geolocation" field="location" title={window.captions.Geolocation}
                    cells={{ data: mapCell }}
                    width="240" />;
            default:
                return null;
        }
    }
})

export default CustomHistoryControl;