import React, { useState, useEffect } from 'react';
import LoadingMask from "../../../../../components/loadingMask.jsx";
import { renderPair } from "../../../../cards/cardRenderFunctions.jsx";
import { EditListViewBooleanCheckItem } from "../../../structureSettings/controls/editListViewItem.jsx";
import { TextBox, NumericTextBox } from "@progress/kendo-react-inputs";
import { ChipList, Chip } from "@progress/kendo-react-buttons";
import { checkCircleIcon } from "@progress/kendo-svg-icons";
import WizardFunction from "../../wizardFunctions.jsx";
import { ListView } from "@progress/kendo-react-listview";

export function VariableGroupTab({ variable, source, onEdit }) {
    const [currentVariable, setCurrentVariable] = useState();

    useEffect(() => {
        setCurrentVariable(variable.item);
    }, [variable.item]);

    if (!currentVariable) {
        return <div style={{ height: "300px" }}><LoadingMask /></div>;
    }

    function onChangeCheckers(e) {
        var item = e.dataItem;

        var isChecked = currentVariable.linkEntities.includes(item.id);
        var newValue = [];

        if (!isChecked) {
            newValue.push(item.id);
            currentVariable.linkEntities.map((element) => newValue.push(element));
        } else {
            currentVariable.linkEntities.map((element) => {
                if (element !== item.id)
                    newValue.push(element);
            });
        }

        var newVariable = {
            ...currentVariable,
            linkEntities: newValue
        };

        setCurrentVariable(newVariable);
        onEdit({
            index: variable.index,
            item: newVariable
        });
    }

    const getCheckerItem = (item) => {

        var data = {
            dataItem: {
                id: item.dataItem.value,
                name: item.dataItem.text,
                value: currentVariable.linkEntities.includes(item.dataItem.value)
            },
            index: item.index
        }

        return <EditListViewBooleanCheckItem
            key={`BoolListItem_${item.index}`}
            item={data}
            onSaveItem={onChangeCheckers}
            fieldId={item.dataItem.value} />;
    }

    const getChip = (item) => {
        return <Chip {...item} selectedSvgIcon={checkCircleIcon} />;
    }

    const changeNameHandler = (e) => {
        var newVariable = currentVariable;
        newVariable.caption = e.value;
        setCurrentVariable(newVariable);
        onEdit({
            index: variable.index,
            item: newVariable
        });
    };

    const changeSheetIndexHandler = (e) => {
        var newVariable = currentVariable;
        newVariable.sheetNumber = e.value;
        setCurrentVariable(newVariable);
        onEdit({
            index: variable.index,
            item: newVariable
        });
    };

    const actualDateAffectsHandler = (e) => {
        var newVariable = currentVariable;
        newVariable.actualDateAffects = e.value;
        setCurrentVariable(newVariable);
        onEdit({
            index: variable.index,
            item: newVariable
        });
    };

    var content = [];

    renderPair(content,
        window.captions.Caption,
        `VariableGroupName_${currentVariable.id}`,
        () => <TextBox key="VariableGroupName"
            value={currentVariable.caption}
            onChange={changeNameHandler} />
    );

    renderPair(content,
        window.captions.SheetNumber,
        `VariableGroupIndex_${currentVariable.id}`,
        () => <NumericTextBox key="VariableGroupIndex"
            value={currentVariable.sheetNumber}
            onChange={changeSheetIndexHandler} />
    );

    renderPair(content,
        window.captions.LinkDirectToEntity,
        `VariableGroupLinkDirectToEntity_${currentVariable.id}`,
        () => <ListView data={WizardFunction.getDefaultLinkEntities(source)} item={getCheckerItem} />
    );

    renderPair(content,
        window.captions.ActualDateAffects,
        `VariableGroupAffects_${currentVariable.id}`,
        () => <ChipList
            defaultData={WizardFunction.getDefaultDateAffects(source)}
            defaultValue={currentVariable.actualDateAffects}
            chip={getChip}
            onChange={actualDateAffectsHandler}
            selection="multiple"
        />
    );

    return <div className="k-m-1">{content}</div>;
}