import React, { useState, useEffect } from 'react';
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { varType, varDataArrayType } from "../../../../../utils/systemConstants.jsx";
import { VariableGroupTab } from "./variableGroupTab.jsx";
import { SpellingTab } from "./spellingTab.jsx";
import { GeneralVariableTab } from "./generalVariableTab.jsx";
import { MergingVariableTab } from "./mergingParametersTabs.jsx";
import { FormulasRestrictionsTab } from "./formulasRestrictionsTab.jsx";
import { SourceFieldsTab } from "./sourceFieldsTab.jsx";
import { ConfigFilterTab } from "./configFilterTab.jsx";
import { VariableSortingTab } from "./sortingTab.jsx";
import { ContactFilterTab } from "./contactFilterTab.jsx";
import { ConditionsFilterTab } from "./conditionsFilterTab.jsx";
import WizardFunctions from '../../wizardFunctions.jsx';

export function VariableComponent({ variable, source, languages, names, onEdit }) {
    const [currentVariable, setCurrentVariable] = useState(null);
    const [selectedTab, setSelectedTab] = useState(0);
    const handleSelectTab = (e) => {
        setSelectedTab(e.selected);
    };

    const handleEdit = (model) => {
        setCurrentVariable(model);
        onEdit(model);
    };

    if (!variable) {
        return;
    }

    useEffect(() => {
        setCurrentVariable(variable);
    }, [variable.item]);

    if (!currentVariable || currentVariable.item.id != variable.item.id) {
        setCurrentVariable(variable);
        setSelectedTab(0);
    }

    if (!currentVariable) {
        return;
    }

    let content = [];

    if ("items" in currentVariable.item) {
        content = <TabStrip className="dm-container dm-container-slim"
            style={{ maxWidth: "1500px" }}
            selected={selectedTab}
            onSelect={handleSelectTab}>
            <TabStripTab key="generalTab" title={currentVariable.item.caption}>
                <VariableGroupTab variable={currentVariable} source={source} onEdit={handleEdit} />
            </TabStripTab>
        </TabStrip>;
    }
    else {
        content = <TabStrip className="dm-container dm-container-slim"
            style={{ maxWidth: "1500px" }}
            selected={selectedTab}
            onSelect={handleSelectTab}>
            {getTabs()}
        </TabStrip>;
    }

    return content;

    function getTabs() {
        const tabs = [];

        tabs.push(<TabStripTab key="generalTab" title={window.captions.General}>
            <GeneralVariableTab variable={currentVariable} onEdit={handleEdit} />
        </TabStripTab>);
        tabs.push(<TabStripTab key="sourceTab" title={window.captions.Source}>
            <SourceFieldsTab variable={currentVariable} source={source} names={names} onEdit={handleEdit} />
        </TabStripTab>);

        if (currentVariable.item.type === varType.dataView) {
            if (WizardFunctions.isVisibleFormulaTab(currentVariable.item)) {
                tabs.push(<TabStripTab key="formulaTab" title={window.captions.FormulasAndLimitations}>
                    <FormulasRestrictionsTab variable={currentVariable} onEdit={handleEdit} />
                </TabStripTab>);
            }

            if (WizardFunctions.isVisibleConditionsTab(currentVariable.item, source)) {
                tabs.push(<TabStripTab key="conditionsFilterTab" title={window.captions.Conditions}>
                    <ConditionsFilterTab variable={currentVariable} onEdit={handleEdit} />
                </TabStripTab>);
            }

            if (WizardFunctions.isVisibleMergingTab(currentVariable.item)) {
                tabs.push(<TabStripTab key="mergingTab" title={window.captions.MergingParameters}>
                    <MergingVariableTab variable={currentVariable} onEdit={handleEdit} />
                </TabStripTab>);
            }

            if (WizardFunctions.isVisibleConfigFilterTab(currentVariable.item)) {
                tabs.push(<TabStripTab key="configFilterTab" title={window.captions.ConfigFilters}>
                    <ConfigFilterTab variable={currentVariable} source={source} onEdit={handleEdit} />
                </TabStripTab>);
            }

            tabs.push(<TabStripTab key="spellingTab" title={window.captions.SpellingTab}>
                <SpellingTab variable={currentVariable} languages={languages} onEdit={handleEdit} />
            </TabStripTab>);

            if (WizardFunctions.isVisibleContactFilterTab(currentVariable.item)) {
                tabs.push(<TabStripTab key="contactFilterTab" title={window.captions.ConfigContactFilters}>
                    <ContactFilterTab variable={currentVariable} source={source} onEdit={handleEdit} />
                </TabStripTab>);
            }
        }

        if (WizardFunctions.isVisibleSortingTab(currentVariable.item)) {
            tabs.push(<TabStripTab key="sortingTab" title={window.captions.SortFields}>
                <VariableSortingTab variable={currentVariable} onEdit={handleEdit} />
            </TabStripTab>);
        }

        return tabs;
    }
}