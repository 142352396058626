import React from 'react';
import { Button } from "@progress/kendo-react-buttons";

export function TreeViewAddItem({ props, addItemHandler }) {
    const addTreeItemHandler = () => {
        var names = props.item.items.map((i) => {
            return { id: i.id, name: "caption" in i ? i.caption : i.name };
        });

        var request = {
            names: names,
            type: 0,
            isReport: true,
            id: props.item.id,
            isGroup: false,
            parent: props.item
        };

        addItemHandler(request)
    }

    return (
        <>
            {props.useAddButton
                ? <Button key="addItem"
                    icon="add"
                    rounded="full"
                    size="small"
                    onClick={addTreeItemHandler} />
                : null}
            <span style={{ padding: "4px" }}>
                {props.text}
            </span>
        </>
    );
}