import React, { useState, useEffect } from 'react';
import { TreeView, processTreeViewItems } from '@progress/kendo-react-treeview';
import { TreeViewAddItem } from "./TreeViewAddItem.jsx";

export function ItemsTreeView({ items, iconsLevel, differentIcon, onSelect, selectedItem, useAddItem, addItemHandler }) {
    const [expand, setExpand] = useState([]);

    const treeProps = { className: "dm-full-height" };

    const generateTreeView = (levelIcons = {}) => {
        if (!items)
            return null;

        const processItems = (itemsArray, currentLevel) => {
            return itemsArray.map(item => {
                var name = "caption" in item ? item.caption : item.name;
                const result = {
                    text: name,
                    item: item,
                    useAddButton: useAddItem && currentLevel == 0
                };
                if (levelIcons.hasOwnProperty(currentLevel)) {
                    if (!differentIcon) {
                        result.iconField = levelIcons[currentLevel];
                    }
                    else {
                        var currentValue = item[differentIcon.parameter];
                        result.iconField = currentValue === differentIcon.value
                            ? levelIcons[`-${currentLevel}`]
                            : levelIcons[currentLevel];
                    }

                }
                if (item.items && item.items.length > 0) {
                    result.items = processItems(item.items, currentLevel + 1);
                }
                return result;
            });
        };

        const list = processItems(items, 0);

        return processTreeViewItems(list, { expand: expand, select: selectedItem });
    };

    const onItemClick = (event) => {
        var model = {
            item: event.item.item,
            index: event.itemHierarchicalIndex
        }
        onSelect(model)
    };

    const onExpandChange = (event) => {
        const newExpand = [...expand];
        const index = newExpand.indexOf(event.itemHierarchicalIndex);
        index === -1 ? newExpand.push(event.itemHierarchicalIndex) : newExpand.splice(index, 1);
        setExpand(newExpand);
    };

    const getCustomItem = (item) => {
        return <TreeViewAddItem props={item.item} addItemHandler={addItemHandler} key={`AddItemKey_${item.id}`} />
    };

    const widthSettings = { minWidth: "300px", maxWidth: "500px"};
    return <TreeView
        data={generateTreeView(iconsLevel)}
        textField="text"
        iconField={"iconField"}
        style={widthSettings}
        {...treeProps}
        expandIcons={true}
        item={useAddItem ? getCustomItem : null}
        onExpandChange={onExpandChange}
        onItemClick={onItemClick} />
}