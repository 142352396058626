import React, { useState } from "react";
import { useEffect, useRef } from 'react';
import { Button } from "@progress/kendo-react-buttons";
import { Card, CardTitle, CardBody, CardActions } from "@progress/kendo-react-layout";
import { ProgressBar } from "@progress/kendo-react-progressbars";
import ErrorMask from "../../components/errorMask.jsx";
import { FileIcon, defaultStyles } from "react-file-icon";
import FileHelper from "../../utils/fileHelper.jsx";
import { isMobileSize } from "../../utils/systemConstants.jsx";
import { getMonitorName, fileTypeClassName } from "../statistic/statisticHelpers.jsx";
import { fetchGet, fetchPost } from '../../utils/requestsHelper.jsx';

export default function ExportQueues({ isStarted, inDialog, onFinish, onCheckQueue = () => { } }) {
    const [items, setItems] = useState([]);
    const [error, setError] = useState(null);

    const refreshTimer = useRef(null);

    useEffect(() => {
        return function cleanup() {
            clearTimeout(refreshTimer.current);
        };
    }, []);

    const hasQueue = items && items.length > 0;

    useEffect(() => {
        onCheckQueue(hasQueue);
    }, [items]);

    useEffect(() => queueUpdate(), [isStarted]);

    const isMobile = isMobileSize(window.innerWidth, window.innerHeight);
    if (error)
        return <ErrorMask error={error} />;

    const hasActiveQueue = hasQueue && items.some(i => !i.hasData && !i.isError && !i.isFinished);
    if (refreshTimer.current) {
        if (!hasActiveQueue) {
            clearTimeout(refreshTimer.current);
        }
    } else {
        if (hasActiveQueue) refreshTimer.current = setInterval(() => queueUpdate(), 500);
    }

    if (!hasQueue)
        return "";

    const itemsPanel = items.map((item, i) => {
        var closeButton = <Button
            onClick={() => queueCancel(item.queryId)}
            style={{ "float": "right", "width": "16px", "height": "16px" }}
            icon="close"
            fillMode="flat" />;        
        const name = <CardTitle>
            {item.caption}
            {closeButton}
        </CardTitle>;

        const footer =
            <CardActions>
                <small>{item.typeCaption}</small>
            </CardActions>;

        var cardStyle = isMobile
            ? { "maxWidth": "100%" }
            : { "maxWidth": "18rem", "minWidth": "18rem" };

        if (inDialog)
            cardStyle = { "maxWidth": "100%", margin: 0 };

        if (item.hasData) {
            const image = fileTypeClassName(item);
            const icon = <FileIcon extension={image} {...defaultStyles[image]} />;

            return <Card key={i} type="success" style={cardStyle}>
                <CardBody>
                    {name}
                    <Button onClick={() => download(item.queryId)}>
                        <span className="dm-icon-16" style={{ marginRight: "5px" }}>{icon}</span> {window
                            .captions.Download}
                    </Button>
                </CardBody>
                {footer}
            </Card>;
        }

        if (item.isError) {
            return <Card key={i} type="error" style={cardStyle}>
                <CardBody>
                    {name}
                    <p>{item.error}</p>
                </CardBody>
                {footer}
            </Card>;
        }

        var state = getMonitorName(item.state);
        if (item.isFinished) {
            return <Card key={i} type="warning" style={cardStyle}>
                <CardBody>
                    {name}
                    <p>{state}</p>
                </CardBody>
                {footer}
            </Card>;
        }

        return <Card key={i} style={cardStyle}>
            <CardBody>
                {name}
                <ProgressBar
                    value={item.percent}
                    label={(p) => <strong>{p.value}% {state}</strong>}
                    style={{ width: "100%" }} />
            </CardBody>
            {footer}
        </Card>;
    });

    return inDialog
        ? <div className="dm-size-100" key="queueBlock">
            {itemsPanel}
        </div>
        : <div className="dm-size-100" key="queueBlock">
            <div className="k-card-deck">{itemsPanel}</div>
        </div>;


    function download(id) {
        FileHelper.getExport(id, () => queueUpdate());
        if (onFinish)
            onFinish();
    }

    function queueUpdate() {
        fetchGet(window.constants.statisticQuery,
            data => {
                setItems(data);
                setError(null);
            },
            ex => setError(ex)
        );
    }

    function queueCancel(id) {
        fetchPost(window.constants.statisticCancel,
            id,
            data => setItems(data),
            () => { }
        );
    }
}