import React from "react";
import { useEffect } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { Input } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { DropDownButton, Button } from "@progress/kendo-react-buttons";
import ValidationMessage from "../../components/validationMessage.jsx";
import LoadingMask from "../../components/loadingMask.jsx";
import ErrorMask from "../../components/errorMask.jsx";
import DrawerMenu from "../../components/drawerMenu.jsx";
import { eventKeyPress, keyEnter, entitySourcesNames, eventResize } from "../../utils/systemConstants.jsx";
import {
    filterSaved,
    reserveSaved,
    hideValidate,
    formLoad,
    clearField,
    save,
    start,
    renameMode,
    rename,
    changeSearchAllValue,
    remove,
    windowSizeChanged
} from
    "./searchFormReducer.jsx";
import searchRenderFunctions from "./searchRenderFunctions.jsx";

export default function SearchForm() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();

    const store = useSelector(state => state.searchForm);
    const access = useSelector(state => state.header.model.access);

    useEffect(() => {
        window.addEventListener(eventKeyPress, handleKeyPress);
        window.addEventListener(eventResize, handleWindowSizeChange);
        const title = getSearchTitle(params.entity);
        document.title = `Delta M. Crm. ${title}`;

        return function cleanup() {
            window.removeEventListener(eventResize, handleWindowSizeChange);
            window.removeEventListener(eventKeyPress, handleKeyPress);
        };
    }, []);

    useEffect(() => dispatch(formLoad(params.id, params.entity, store)), [params.id, params.entity]);

    useEffect(() => {
        if (store.savedId > 0) {
            dispatch(filterSaved(0));
            navigate(`/search/${store.entity}/${store.savedId}`);
        }
    }, [store.savedId]);

    useEffect(() => {
        if (store.reserveId > 0) {
            dispatch(reserveSaved(0));
            navigate(`/reserve/${store.entity}/${store.reserveId}`);
        }
    }, [store.reserveId]);

    useEffect(() => {
        if (store.selected && store.selected.id > 0 && store.selected.id != params.id) {
            navigate(`/search/${store.entity}/form/${store.selected.id}`);
        }
    }, [store.selected]);

    if (store.errors)
        return <ErrorMask error={store.errors} />;

    if (!store.isLoaded || !store.selected)
        return <LoadingMask text={window.captions.LoadingData} />;

    const isReadOnly = store.selected.isPublic === true;
    const title = getSearchTitle(store.entity);
    const filterGroups = searchRenderFunctions.renderForm(store, dispatch, params.entity, isReadOnly);

    let savingState = null;
    if (store.isProcessing)
        savingState = <LoadingMask text="Starting search" />;

    const searchAllFields = store.canSearchAllFields
        ? <div style={{ width: 350, paddingLeft: 20 }}>
            <Input value={store.selected.searchAllFieldsValue}
                onChange={(e) => dispatch(changeSearchAllValue(e))}
                style={{ width: "100%" }}
                label={window.captions.SearchByAllFields} />
        </div>
        : null;

    const blockEdit = isReadOnly || store.blockCreateSearch;
    const canClean = !blockEdit && store.selected.items && store.selected.items.length > 0;
    const canDelete = !blockEdit && store.selected.id > 0;

    let leftMenu = null;
    let topMenu = null;
    let actionButtons = [];
    let titleControl = [];
    if (store.isMobile) {
        const editFilterName = store.isNameEdit && !blockEdit
            ? <Input value={store.selected.name}
                maxLength={25}
                onChange={(e) => dispatch(rename(e))}
                className="dm-wrapper-content" />
            : <DropDownList data={store.filters}
                textField="name"
                dataItemKey="id"
                value={store.selected}
                onChange={(e) => navigate(`/search/form/${store.entity}/${e.target.value.id}`)}
                className="dm-wrapper-content" />;

        titleControl.push(<DropDownButton
            onItemClick={(e) => onSelect(e.item)}
            themeColor="primary"
            key="menuFilters"
            className="dm-m-left"
            icon="filter"
            items={getMenuItems(true)}
            textField="name" />);

        topMenu = <div className="dm-filter-menu dm-wrapper">
            {editFilterName}
            <div>
                {canClean
                    ? <Button className="float-right"
                        onClick={() => dispatch(clearField(0))}
                        icon="filter-clear">
                        {window.captions.Clear}
                    </Button>
                    : null}
                {blockEdit
                    ? null
                    : <Button togglable={true}
                        selected={store.isNameEdit}
                        onClick={(e) => dispatch(renameMode(e))}
                        icon="edit" />}
                {canDelete
                    ? <Button className="float-right"
                        onClick={() => dispatch(remove(store.selected.id))}
                        icon="delete" />
                    : null}
            </div>
        </div>;
    } else {
        if (canClean) {
            titleControl.push(<Button style={{ margin: "0 4px" }}
                key="buttonClean"
                onClick={() => dispatch(clearField(0))}
                icon="filter-clear">
                {window.captions.Clear}
            </Button>);
        }

        if (!blockEdit)
            titleControl.push(<Button togglable={true}
                selected={store.isNameEdit}
                key="buttonEdit"
                style={{ margin: "0 4px" }}
                onClick={() => dispatch(renameMode())}
                icon="edit" />);
        if (canDelete) {
            titleControl.push(<Button style={{ margin: "0 4px" }}
                key="buttonDelete"
                onClick={() => dispatch(remove(store.selected.id))}
                icon="delete" />);
        }

        if (store.isNameEdit && !blockEdit) {
            titleControl.push(<Input key="nameEdior"
                maxLength={25}
                style={{ maxWidth: "300px" }}
                value={store.selected.name}
                onChange={(e) => dispatch(rename(e))}
                className="dm-wrapper-content" />);
        }

        leftMenu = <DrawerMenu items={getMenuItems()}
            align="left"
            onSelect={onSelect}>
        </DrawerMenu>;
    }

    if (store.entity === entitySourcesNames.collateral) {
        actionButtons.push(<Button themeColor="primary"
            icon="calendar"
            key="buttonReserve"
            style={{ margin: "16px" }}
            className="dm-button"
            onClick={() => dispatch(start(store.selected,
                true,
                store.denyEmptySearchFilter))}>
            {window.captions.DisplayResultByReserve}
        </Button>);
    }

    let modal = null;
    if (store.validate) {
        modal = <ValidationMessage close={() => dispatch(hideValidate(false))} text={store.validate} error={true} />;
    }

    return <div className="dm-full-height">
        {leftMenu}
        <div className="dm-full-height">
            <h1 className="dm-title">{title}{titleControl}</h1>
            <div className="dm-under-title-content">
                <div className="dm-flex-height">
                    {topMenu}
                    {searchAllFields}
                    <div className="dm-flex-height-content">
                        {filterGroups}
                    </div>
                    <div>
                        {blockEdit
                            ? null
                            : <Button icon="save" style={{ margin: "16px 0 16px 16px" }}
                                className="dm-button"
                                onClick={() => dispatch(save(store.selected, store.denyEmptySearchFilter))}>
                                {window.captions.Save}
                            </Button>}

                        <Button themeColor="primary"
                            icon="search"
                            style={{ margin: "16px" }}
                            className="dm-button"
                            onClick={() => dispatch(start(store.selected,
                                false,
                                store.denyEmptySearchFilter))}>
                            {window.captions.StartSearch}
                        </Button>

                        {actionButtons}
                    </div>
                </div>
                {savingState}
                {modal}
            </div>
        </div>
    </div>;

    function getSearchTitle(entity) {
        switch (entity) {
            case entitySourcesNames.loan:
                return window.captionsDynamic.RuleLoansSearch;
            case entitySourcesNames.client:
                return window.captions.ConfigSearchClient;
            case entitySourcesNames.collateral:
                return window.captionsDynamic.ConfigSearchCollateral;
            case entitySourcesNames.callRecords:
                return window.captions.ConfigSearchCallRecords;
            case entitySourcesNames.documents:
                return window.captions.ConfigSearchDocuments;
            default:
                return window.captions.RuleDataSearch;
        }
    }

    function handleKeyPress(event) {
        if (event.key === keyEnter)
            dispatch(save(store.selected, store.denyEmptySearchFilter));
    };

    function handleWindowSizeChange() {
        dispatch(windowSizeChanged());
    }

    function getMenuItems(withoutFilters) {
        const menuItems = [];
        if (access.searchLoan) {
            menuItems.push({
                text: window.captionsDynamic.LoanManyNominative,
                route: `/search/loan/form`,
                selected: store.entity === entitySourcesNames.loan
            });
        }
        if (access.searchClient) {
            menuItems.push({
                text: window.captions.Clients,
                route: `/search/client/form`,
                selected: store.entity === entitySourcesNames.client
            });
        }
        if (access.searchCollateral) {
            menuItems.push({
                text: window.captionsDynamic.Collaterals,
                route: `/search/collateral/form`,
                selected: store.entity === entitySourcesNames.collateral
            });
        }
        if (access.searchCallRecords) {
            menuItems.push({
                text: window.captions.CallRecords,
                route: `/search/callRecords/form`,
                selected: store.entity === entitySourcesNames.callRecords
            });
        }
        if (access.searchDocuments) {
            menuItems.push({
                text: window.captions.Documents,
                route: `/search/documents/form`,
                selected: store.entity === entitySourcesNames.documents
            });
        }

        if (!withoutFilters && store.filters && store.filters.length > 0) {
            menuItems.push({ separator: true });
            menuItems.push({
                text: window.captions.AvailableFilters,
                disabled: true
            });
            store.filters.forEach((item, index) => menuItems.push({
                text: item.name,
                index: index,
                selected: store.selected.id === item.id
            }));
        }

        return menuItems;
    }

    function onSelect(e) {
        if (e.route)
            navigate(e.route);
        else if (e.index >= 0) {
            var item = store.filters[e.index];
            navigate(`/search/${store.entity}/form/${item.id}`);
        }
    }
}