import React, { useState, useEffect } from 'react';
import LoadingMask from "../../../../../components/loadingMask.jsx";
import { renderPair } from "../../../../cards/cardRenderFunctions.jsx";
import WizardFunction from "../../wizardFunctions.jsx";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Checkbox, TextBox } from "@progress/kendo-react-inputs";
import { varFormulaType } from "../../../../../utils/systemConstants.jsx";
export function FormulasRestrictionsTab({ variable, onEdit }) {
    const [currentVariable, setCurrentVariable] = useState();

    useEffect(() => {
        setCurrentVariable(variable.item);
    }, [variable.item]);

    if (!currentVariable) {
        return <div style={{ height: "300px" }}><LoadingMask /></div>;
    }

    const changeParameterHandler = (e, type) => {
        var newVariable = currentVariable;
        switch (type) {
            case 0:
                newVariable.isConvertCurrency = e.value;
                break;
            case 1:
                newVariable.isConstantsCode = e.value;
                break;
            case 2:
                newVariable.isGroupBy = e.value;
                break;
            case 3:
                newVariable.isShowTotalGroup = e.value;
                newVariable.totalGroupShowName = "";
                break;
            case 4:
                newVariable.isShowTotal = e.value;
                newVariable.totalShowName = "";
                break;
            case 5:
                newVariable.isShowTotalTop = e.value;
                break;
        }

        setCurrentVariable(newVariable);
        onEdit({
            index: variable.index,
            item: newVariable
        });
    };

    const changeTotalShowHandler = (e, type) => {
        var newVariable = currentVariable;
        switch (type) {
            case 0:
                newVariable.totalGroupShowName = e.value
                break;
            case 1:
                newVariable.totalShowName = e.value
                break;
        }

        setCurrentVariable(newVariable);
        onEdit({
            index: variable.index,
            item: newVariable
        });
    };

    const changeFormulaTypesHandler = (e) => {
        var newVariable = currentVariable;
        newVariable.formulaDefault = e.value.id;
        setCurrentVariable(newVariable);
        onEdit({
            index: variable.index,
            item: newVariable
        });
    };

    const changeFormulaGroupHandler = (e) => {
        var newVariable = currentVariable;
        newVariable.formulaGroup = e.value.id;
        if (e.value.id !== varFormulaType.none)
            newVariable.formulaDefault = e.value.id;
        setCurrentVariable(newVariable);
        onEdit({
            index: variable.index,
            item: newVariable
        });
    };

    const changeFormulaTotalHandler = (e) => {
        var newVariable = currentVariable;
        newVariable.formulaTotal = e.value.id;
        setCurrentVariable(newVariable);
        onEdit({
            index: variable.index,
            item: newVariable
        });
    };

    var content = [];
    const style = { width: "100%" };
    const paddingX = "dm-m-left dm-m-right";
    const formulaTypes = WizardFunction.getFormulaTypes()

    renderPair(content,
        window.captions.Formula,
        "VariableFormulaTypes",
        () => <DropDownList style={style}
            data={formulaTypes}
            disabled={currentVariable.formulaGroup > 0}
            textField="text"
            dataItemKey="id"
            onChange={changeFormulaTypesHandler}
            value={formulaTypes.find(type => type.id === currentVariable.formulaDefault)} />
    );

    renderPair(content,
        window.captions.FormulaGrouping,
        "VariableFormulaGrouping",
        () => <DropDownList style={style}
            data={formulaTypes}
            textField="text"
            dataItemKey="id"
            onChange={changeFormulaGroupHandler}
            value={formulaTypes.find(type => type.id === currentVariable.formulaGroup)} />
    );

    renderPair(content,
        window.captions.FormulaSummary,
        "VariableFormulaSummary",
        () => <DropDownList style={style}
            data={formulaTypes}
            textField="text"
            dataItemKey="id"
            onChange={changeFormulaTotalHandler}
            value={formulaTypes.find(type => type.id === currentVariable.formulaTotal)} />
    );

    renderPair(content,
        "",
        "TransferFinancialValueInDefaultCurrency",
        () => <Checkbox value={currentVariable.isConvertCurrency}
            onChange={(e) => changeParameterHandler(e, 0)}
            label={window.captions.TransferFinancialValueInDefaultCurrency} />,
        true);

    renderPair(content,
        "",
        "ForDictFieldsDisplayCodes",
        () => <Checkbox value={currentVariable.isConstantsCode}
            onChange={(e) => changeParameterHandler(e, 1)}
            label={window.captions.ForDictFieldsDisplayCodes} />,
        true);

    renderPair(content,
        "",
        "GroupDataByThisField",
        () => <Checkbox value={currentVariable.isGroupBy}
            onChange={(e) => changeParameterHandler(e, 2)}
            label={window.captions.GroupDataByThisField} />,
        true);

    if (currentVariable.isGroupBy) {
        renderPair(content,
            "",
            "DisplayGroupedSummaryByField",
            () => <div>
                <Checkbox value={currentVariable.isShowTotalGroup}
                    onChange={(e) => changeParameterHandler(e, 3)}
                    label={window.captions.DisplayGroupedSummaryByField} />
                {currentVariable.isShowTotalGroup
                    ? <TextBox value={currentVariable.totalGroupShowName}
                        onChange={(e) => changeTotalShowHandler(e, 0)} />
                    : null}
            </div>,
            true);
    }

    renderPair(content,
        "",
        "DisplaySummaryByField",
        () => <div>
            <Checkbox value={currentVariable.isShowTotal}
                onChange={(e) => changeParameterHandler(e, 4)}
                label={window.captions.DisplaySummaryByField} />
            {currentVariable.isShowTotal
                ? <TextBox value={currentVariable.totalShowName}
                    onChange={(e) => changeTotalShowHandler(e, 1)} />
                : null}
        </div>,
        true);

    renderPair(content,
        "",
        "DisplaySummaryOnTop",
        () => <Checkbox value={currentVariable.isShowTotalTop}
            onChange={(e) => changeParameterHandler(e, 5)}
            label={window.captions.DisplaySummaryOnTop} />,
        true);

    return <div className="k-m-1">{content}</div>;
}