import React, { useState, useEffect } from 'react';
import LoadingMask from "../../../../../components/loadingMask.jsx";
import { renderPair } from "../../../../cards/cardRenderFunctions.jsx";
import WizardFunction from "../../wizardFunctions.jsx";
import { TextBox, RadioGroup, Checkbox } from "@progress/kendo-react-inputs";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { fetchPost } from "../../../../../utils/requestsHelper.jsx";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

export function SpellingTab({ variable, onEdit }) {
    const [currentVariable, setCurrentVariable] = useState();
    const [languages, setLanguages] = useState();
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        setCurrentVariable(variable.item)
        loadSpelling();
    }, [variable.item,
        variable.item.spell,
        variable.item.spell.general,
        variable.item.spell.fractional]);


    if (!currentVariable || !languages || isLoaded)
        return <div style={{ height: "300px" }}><LoadingMask /></div>;

    if (error) {
        return <Dialog width={400}>
            <MessageMask inline error={error} />
            <DialogActionsBar>
                <Button onClick={() => { setError(null) }}>{window.captions.Close}</Button>
            </DialogActionsBar>
        </Dialog>;
    }

    const changeSpellingHandler = (e) => {
        var newVariable = currentVariable;
        newVariable.spell.isOn = e.value;
        newVariable.spell.isFractional = false;
        newVariable.spell.isFractionalShort = false;
        newVariable.spell.lang = 0;
        newVariable.spell.general.gender = 0;
        newVariable.spell.general.genericPlural = "";
        newVariable.spell.general.genericSingular = "";
        newVariable.spell.general.nominalSingular = "";
        newVariable.spell.stringFormat = "";
        setCurrentVariable(newVariable);
        onEdit({
            index: variable.index,
            item: newVariable
        });
    };

    const changeIsFractionalHandler = (e) => {
        var newVariable = currentVariable;
        newVariable.spell.isFractional = e.value;
        newVariable.spell.fractional.gender = 0;
        newVariable.spell.fractional.genericPlural = "";
        newVariable.spell.fractional.genericSingular = "";
        newVariable.spell.fractional.nominalSingular = "";
        setCurrentVariable(newVariable);
        onEdit({
            index: variable.index,
            item: newVariable
        });
    };

    const changeIsFractionalShortHandler = (e) => {
        var newVariable = currentVariable;
        newVariable.spell.isFractionalShort = e.value;
        newVariable.spell.fractional.gender = 0;
        newVariable.spell.fractional.genericPlural = "";
        newVariable.spell.fractional.genericSingular = "";
        newVariable.spell.fractional.nominalSingular = "";
        setCurrentVariable(newVariable);
        onEdit({
            index: variable.index,
            item: newVariable
        });
    };

    const changeLanguageHandler = (e) => {
        var newVariable = currentVariable;
        newVariable.spell.lang = e.value;
        setCurrentVariable(newVariable);
        onEdit({
            index: variable.index,
            item: newVariable
        });
    };

    const changeGeneralGenderHandler = (e) => {
        var newVariable = currentVariable;
        newVariable.spell.general.gender = e.value;
        setCurrentVariable(newVariable);
        onEdit({
            index: variable.index,
            item: newVariable
        });
    };

    const changeFractionalGenderHandler = (e) => {
        var newVariable = currentVariable;
        newVariable.spell.fractional.gender = e.value;
        setCurrentVariable(newVariable);
        onEdit({
            index: variable.index,
            item: newVariable
        });
    };

    const changeStringFormatHandler = (e) => {
        var newVariable = currentVariable;
        newVariable.spell.stringFormat = e.value;
        setCurrentVariable(newVariable);
        onEdit({
            index: variable.index,
            item: newVariable
        });
    };

    const changeGeneralParameterHandler = (e, type) => {
        var newVariable = currentVariable;
        switch (type) {
            case 0:
                newVariable.spell.general.genericSingular = e.value
                break;
            case 1:
                newVariable.spell.general.nominalSingular = e.value
                break;
            case 2:
                newVariable.spell.general.genericPlural = e.value
                break;
        }

        setCurrentVariable(newVariable);
        onEdit({
            index: variable.index,
            item: newVariable
        });
    };

    const changeFractionalParameterHandler = (e, type) => {
        var newVariable = currentVariable;
        switch (type) {
            case 0:
                newVariable.spell.fractional.genericSingular = e.value
                break;
            case 1:
                newVariable.spell.fractional.nominalSingular = e.value
                break;
            case 2:
                newVariable.spell.fractional.genericPlural = e.value
                break;
        }

        setCurrentVariable(newVariable);
        onEdit({
            index: variable.index,
            item: newVariable
        });
    };

    var content = []

    var languagesList = WizardFunction.getLanguages(languages)
    var spellingGeneralGender = WizardFunction.getSpellingGeneralGender()

    renderPair(content,
        "",
        "SpellingIsOn",
        () => <Checkbox key="SpellingIsOnKey" value={currentVariable.spell.isOn}
            onChange={changeSpellingHandler}
            label={window.captions.ReplaceTheNumericValuesToWords} />,
        true);

    if (currentVariable.spell.isOn) {
        renderPair(content,
            window.captions.SpellingLanguage,
            "SpellLanguage",
            () => <RadioGroup data={languagesList}
                key={`SpellLanguage_${currentVariable.id}`}
                onChange={changeLanguageHandler}
                value={currentVariable.spell.lang == 0 ? languagesList[0].value : currentVariable.spell.lang}
                layout="horizontal" />,
            true);

        renderPair(content,
            window.captions.SpellingGeneralGender,
            "SpellingGeneralGender",
            () => <RadioGroup data={spellingGeneralGender}
                key={`SpellingGeneralGender_${currentVariable.id}`}
                onChange={changeGeneralGenderHandler}
                value={currentVariable.spell.general.gender}
                layout="horizontal" />,
            true);

        renderPair(content,
            window.captions.SpellingGeneralNominativeSingle,
            `VariableGenericSingular_${currentVariable.id}`,
            () => <TextBox key="VariableName"
                value={currentVariable.spell.general.genericSingular}
                onChange={(e) => changeGeneralParameterHandler(e, 0)} />
        );

        renderPair(content,
            window.captions.SpellingGeneralGenitiveSingle,
            `VariableNominalSingular_${currentVariable.id}`,
            () => <TextBox key="VariableName"
                value={currentVariable.spell.general.nominalSingular}
                onChange={(e) => changeGeneralParameterHandler(e, 1)} />
        );

        renderPair(content,
            window.captions.SpellingGeneralGenitiveMultiple,
            `VariableGenericPlural_${currentVariable.id}`,
            () => <TextBox key="VariableName"
                value={currentVariable.spell.general.genericPlural}
                onChange={(e) => changeGeneralParameterHandler(e, 2)} />
        );

        renderPair(content,
            "",
            "SpellingIsFractional",
            () => <Checkbox key="SpellingIsFractionalKey" value={currentVariable.spell.isFractional}
                onChange={changeIsFractionalHandler}
                label={window.captions.SpellingIsFractional} />,
            true);

        if (currentVariable.spell.isFractional) {
            renderPair(content,
                "",
                "SpellingIsFractionalShort",
                () => <Checkbox key="SpellingIsFractionalShortKey" value={currentVariable.spell.isFractionalShort}
                    onChange={changeIsFractionalShortHandler}
                    label={window.captions.SpellingIsFractionalShort} />,
                true);

            if (currentVariable.spell.isFractionalShort) {
                renderPair(content,
                    window.captions.SpellingFractionalText,
                    `VariableSpellingFractionalText_${currentVariable.id}`,
                    () => <TextBox value={currentVariable.spell.fractional.nominalSingular}
                        onChange={(e) => changeFractionalParameterHandler(e, 1)} />
                );
            }
            else {
                renderPair(content,
                    window.captions.SpellingFractionalGender,
                    "SpellingFractionalGender",
                    () => <RadioGroup data={spellingGeneralGender}
                        key={`SpellingGeneralGender_${currentVariable.id}`}
                        onChange={changeFractionalGenderHandler}
                        value={currentVariable.spell.fractional.gender}
                        layout="horizontal" />,
                    true);

                renderPair(content,
                    window.captions.SpellingFractionalNominativeSingle,
                    `VariableFractionalSingular_${currentVariable.id}`,
                    () => <TextBox value={currentVariable.spell.fractional.genericSingular}
                        onChange={(e) => changeFractionalParameterHandler(e, 0)} />
                );

                renderPair(content,
                    window.captions.SpellingGeneralGenitiveSingle,
                    `VariableFractionalNominalSingular_${currentVariable.id}`,
                    () => <TextBox value={currentVariable.spell.fractional.nominalSingular}
                        onChange={(e) => changeFractionalParameterHandler(e, 1)} />
                );

                renderPair(content,
                    window.captions.SpellingGeneralGenitiveMultiple,
                    `VariableFractionalPlural_${currentVariable.id}`,
                    () => <TextBox value={currentVariable.spell.fractional.genericPlural}
                        onChange={(e) => changeFractionalParameterHandler(e, 2)} />
                );
            }
        }

        renderPair(content,
            window.captions.MergingParsingTemplate,
            `VariableMergingParsingTemplate_${currentVariable.id}`,
            () => <TextBox key="VariableMergingParsingTemplate"
                value={currentVariable.spell.stringFormat ?? ""}
                onChange={changeStringFormatHandler} />
        );

        content.push(<PanelBar key={`PanelBarTemplates${currentVariable.id}`} className="k-panelbar dm-m-bottom">
            <PanelBarItem key="templatesItem"
                title={window.captions.MergingVariables}
                style={{ maxHeight: "500px", overflow: "hidden" }}>
                <div className="k-m-1" style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gap: "8px",
                    maxHeight: "300px",
                    overflow: "auto"
                }}>
                    {WizardFunction.getSpellingTemplates()}
                </div>
            </PanelBarItem>
        </PanelBar>);
    }

    return <div className="k-m-1">{content}</div>;

    function loadSpelling() {
        setError(null);
        fetchPost(window.constants.loadWizardItem,
            { type: 3 },
            (data) => {
                setLanguages(data);
            },
            (ex) => {
                setLanguages(null);
                showError(ex);
            });
    }


    function showError(ex) {
        setError(ex);
        setIsLoaded(false);
    }
}