import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Checkbox } from "@progress/kendo-react-inputs";
import { TreeView } from "@progress/kendo-react-treeview";
import { SvgIcon } from "@progress/kendo-react-common";
import { xCircleIcon } from "@progress/kendo-svg-icons";
import { InputClearValue, TextBox } from "@progress/kendo-react-inputs";

export default function TreeViewCheck(props) {
    const EMPTY_VALUE = '';
    const [state, setState] = useState({
        textField: "name",
        isCheckedField: "checked",
        disabledField: "disabled",
        expandField: "expanded",
        dataTree: null,
        searchQuery: EMPTY_VALUE,
        searchQuery: "100%"
    });

    useEffect(() => mountProperties(), [props.dataTree, props.isCheckedField, props.textField, props.isSelectedField]);

    const filteredDataTree = useMemo(() => {
        if (state.dataTree) {
            return filterTree(state.dataTree, state.searchQuery);
        }
        return state.dataTree;
    }, [state.dataTree, state.searchQuery]);

    const handleClear = useCallback(() => {
        setState(prevState => ({
            ...prevState,
            searchQuery: EMPTY_VALUE
        }));
    }, []);

    return (
        <div className="container dm-m-right">
            <TextBox placeholder={window.captions.Search}
                style={{maxWidth: "300px", overflow: "auto"}}
                className="dm-m-bottom"
                value={state.searchQuery}
                suffix={() => (
                    <>
                        {state.searchQuery !== EMPTY_VALUE && (
                            <InputClearValue onClick={handleClear}>
                                <SvgIcon icon={xCircleIcon} />
                            </InputClearValue>
                        )}
                    </>
                )}
                onChange={onSearchChange} />
            <TreeView
                style={{ maxHeight: `${state.scrollHeight}` }}
                expandIcons={true}
                data={filteredDataTree ?? state.dataTree}
                item={renderItem}
                textField={state.textField}
                expandField={state.expandField}
                onExpandChange={onExpandChange}
            />
        </div>
    );

    function mountProperties() {
        const textField = props.textField ?? "name";
        const isCheckedField = props.isCheckedField ?? "checked";
        const disabledField = props.disabledField ?? "disabled";
        const expandField = props.expandField ?? "expanded";
        const scrollHeight = props.scrollHeight ?? "100%";

        const dataTree = Array.isArray(props.dataTree) && props.dataTree.length ? props.dataTree : [];

        setState(prevState => ({
            ...prevState,
            textField: textField,
            isCheckedField: isCheckedField,
            disabledField: disabledField,
            expandField: expandField,
            dataTree: dataTree,
            searchQuery: EMPTY_VALUE,
            scrollHeight: scrollHeight,
        }));
    }

    function onExpandChange(event) {
        const item = event.item;
        const originalItem = findItemById(state.dataTree, item.id);
        if (originalItem) {
            originalItem[state.expandField] = !originalItem[state.expandField];
        }

        setState(prevState => ({
            ...prevState,
            dataTree: [...prevState.dataTree],
        }));
    };

    function onCheckChange(e, item) {
        const originalItem = findItemById(state.dataTree, item.id);
        if (originalItem) {
            originalItem[state.isCheckedField] = e.value;
            updateParents(state.dataTree, originalItem);
            updateChildrens(originalItem);
        }

        setState(prevState => ({
            ...prevState,
            dataTree: [...prevState.dataTree],
        }));

        if (props.onCheckChange)
            props.onCheckChange(originalItem);
    }

    function updateParents(tree, item) {
        if (!item)
            return;

        const parent = findParentById(tree, item.parentId);

        if (!parent)
            return;

        if (parent.items.some((child) => child[state.isCheckedField] || child[state.isCheckedField] == null)) {
            if (parent.items.every((child) => child[state.isCheckedField])) {
                setParentValue(parent, true);
            } else {
                setParentValue(parent, null);
            }
        }
        else {
            setParentValue(parent, false);
        }
    }

    function setParentValue(parent, newValue) {
        if (parent[state.isCheckedField] === newValue)
            return;
        parent[state.isCheckedField] = newValue;
        updateParents(state.dataTree, parent);
    }

    function updateChildrens(item) {
        if (!item.items)
            return;
        item.items.forEach(child => {
            setChildrenValue(child, item[state.isCheckedField]);
        });
    }

    function setChildrenValue(child, newValue) {
        if (child[state.isCheckedField] === newValue)
            return;
        child[state.isCheckedField] = newValue;
        updateChildrens(child);
    }

    function renderItem(props) {
        return <Checkbox key={`Checkbox_${props.itemHierarchicalIndex}`}
            value={props.item[state.isCheckedField]}
            label={props.item[state.textField]}
            disabled={props.item[state.disabledField]}
            onChange={(e) => onCheckChange(e, props.item)}
        />;
    };

    function findParentById(items, parentId) {
        if (!parentId)
            return null;

        function recursiveSearch(items) {
            for (const item of items) {
                if (item.id === parentId)
                    return item;
                if (item.items && item.items.length > 0) {
                    const result = recursiveSearch(item.items);
                    if (result)
                        return result;
                }
            }
            return null;
        }

        return recursiveSearch(items);
    }

    function findItemById(items, id) {
        for (const item of items) {
            if (item.id === id) {
                return item;
            }
            if (item.items) {
                const result = findItemById(item.items, id);
                if (result) {
                    return result;
                }
            }
        }
        return null;
    }

    function onSearchChange(e) {
        const query = e.target.value;
        setState(prevState => ({
            ...prevState,
            searchQuery: query,
        }));
    }

    function filterTree(items, query) {
        if (!query || query.length === 0) {
            return items;
        }

        const filteredItems = [];

        items.forEach(item => {
            const itemMatches = item[state.textField].toLowerCase().includes(query.toLowerCase());

            if (itemMatches) {
                const newItem = { ...item, [state.expandField]: item[state.expandField] };
                if (item.items && item.items.length > 0) {
                    newItem.items = item.items.map(child => ({ ...child }));
                }
                filteredItems.push(newItem);
            } else if (item.items) {
                const children = filterTree(item.items, query);

                if (children.length > 0) {
                    const newItem = { ...item, items: children, [state.expandField]: item[state.expandField] };
                    filteredItems.push(newItem);
                }
            }
        });

        return filteredItems;
    }
}
