import React, { useState, useEffect } from 'react';
import LoadingMask from "../../../components/loadingMask.jsx";
import WizardFunctions from "../sharedUi/wizardFunctions.jsx";
import { Button } from "@progress/kendo-react-buttons";
import { ItemsTreeView } from "../sharedUi/controls/itemsTreeView.jsx";
import { ReportSqlView } from "./reportSqlView.jsx";
import { formulaFxIcon } from "@progress/kendo-svg-icons";
import { fetchPost } from '../../../utils/requestsHelper.jsx';
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

export function ReportSqlTab({ template, fields, onEdit, onDelete }) {
    const [currentTemplate, setCurrentTemplate] = useState();
    const [selectedScript, setSelectedScript] = useState();
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        setCurrentTemplate(template);
    }, [template, template.scripts]);

    if (!currentTemplate || isLoaded) {
        return <div style={{ height: "300px" }}><LoadingMask/></div>;
    }

    const updateItem = (model, selectedIndex) => {
        let data = currentTemplate;

        copyAndUpdateItem(Number(selectedIndex), data, model);

        setCurrentTemplate(data);
        onEdit(data);
    };

    const copyAndUpdateItem = (itemIndex, siblings, newData) => {
        siblings[itemIndex] = { ...siblings[itemIndex] };
        siblings[itemIndex].scripts = newData.scripts;
    };

    const handleEdit = (model) => {
        updateItem(model, model.index);
    };

    const handleAdd = () => {
        let scriptsNames = [];
        currentTemplate.scripts.forEach(item => scriptsNames.push({ id: scriptsNames.length, name: item.name }));
        var request = {
            names: scriptsNames,
            type: template.type,
            isReport: true,
            id: currentTemplate.id,
            isGroup: false
        };
        addSqlItem(request);
    };

    const handleDelete = () => {
        if (!selectedScript)
            return;

        if (!confirm(window.captions.DeleteItemConfirm))
            return;

        if (selectedScript.item.id === 0) {
            const newArray = WizardFunctions.removeItemByObject(currentTemplate.scripts, selectedScript.item);
            var newTemplates = currentTemplate;
            newTemplates.scripts = newArray;
        }
        else {
            onDelete(WizardFunctions.createDeletedModel(selectedScript.item.id, 5))

            const newArray = WizardFunctions.removeItemById(currentTemplate.scripts, selectedScript.item.id);
            var newTemplates = currentTemplate;
            newTemplates.scripts = newArray;
        }

        onEdit(newTemplates);
        setSelectedScript(null)
    };

    const getFields = (selectedIdFields) => {
        return fields.filter(field => selectedIdFields.includes(field.id))
    };

    const handleSelectScript = (model) => {
        setSelectedScript(model)
    };

    const onCloseError = () => {
        setError(null);
        setCurrentTemplate(template);
    };

    if (error) {
        return <Dialog width={400}>
            <MessageMask inline error={error} />
            <DialogActionsBar>
                <Button onClick={() => { onCloseError() }}>{window.captions.Close}</Button>
            </DialogActionsBar>
        </Dialog>;
    }

    const paddingY = "dm-m-bottom dm-m-top";
    const fullWidth = { width: "100%" };

    return <div className="k-d-flex-row" style={{ minHeight: "300px" }} key="reportSqlView">
        <div>
            <div>
                <Button
                    key="addItem"
                    icon="add"
                    className={`dm-m-right ${paddingY}`}
                    onClick={handleAdd}/>
                <Button
                    key="deleteItem"
                    icon="delete"
                    disabled={!selectedScript}
                    className={`dm-m-right dm-negative-filled ${paddingY}`}
                    onClick={handleDelete}/>
            </div>
            <ItemsTreeView key="SriptsItems"
                items={currentTemplate.scripts}
                iconsLevel={{0: formulaFxIcon }}
                onSelect={handleSelectScript}
                selectedItem={selectedScript} />
        </div>
        <div style={fullWidth}>
            {selectedScript
                ? <ReportSqlView script={selectedScript} fields={getFields(currentTemplate.fields)} onEdit={handleEdit} />
                : <div className="dm-rules-users">
                    <div className="select-request">
                        {window.captions.ChoseOrAddElement}
                    </div>
                </div>}
        </div>
    </div>

    function addSqlItem(request) {
        setIsLoaded(true);
        fetchPost(`${window.constants.addWizardSqlItem}`,
            request,
            model => {
                if (model) {
                    var newTemplate = currentTemplate;
                    newTemplate.scripts.push(model);
                    setCurrentTemplate(newTemplate);
                    onEdit(newTemplate);

                    setIsLoaded(false);
                }
            },
            ex => showError(ex)
        );
    };

    function showError(ex) {
        setError(ex);
        setIsLoaded(false);
    }
}