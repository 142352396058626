import React, { useState, useEffect } from 'react';
import { renderPair } from "../../../cards/cardRenderFunctions.jsx";
import WizardFunctions from "../wizardFunctions.jsx";
import { useLocation } from "react-router-dom";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import { RadioGroup } from "@progress/kendo-react-inputs";

export function ParametersSelector({ template, onEdit }) {
    const location = useLocation();
    const [currentTemplate, setCurrentTemplate] = useState(0);
    const isReports = location.pathname.includes("reports");

    useEffect(() => {
        setCurrentTemplate(template)
    }, [template]);

    const varDateDividerHandler = (item) => {
        var template = currentTemplate;
        template.varDateDivider = item.value.id;
        setCurrentTemplate(template);
        onEdit(template);
    };

    const dividerTypeHandler = (item) => {
        var template = currentTemplate;
        template.dividerType = item.value;
        setCurrentTemplate(template);
        onEdit(template);
    };

    const varDateDividerPartHandler = (item) => {
        var template = currentTemplate;
        template.dateDividerPart = item.value;
        setCurrentTemplate(template);
        onEdit(template);
    };

    var content = [];
    const dividers = WizardFunctions.getDateDividers();
    var innerContent = [];

    if (isReports) {
        renderPair(innerContent,
            window.captions.DivideDateToIntervals,
            `varDateDivider_${currentTemplate.id}`,
            () => <DropDownList style={{ width: "95%" }}
                data={dividers}
                textField="text"
                dataItemKey="id"
                value={dividers.find(type => type.id === currentTemplate.varDateDivider)}
                onChange={varDateDividerHandler} />
        );

        renderPair(innerContent,
            window.captions.AndForNParts,
            `varDateDividerPart_${currentTemplate.id}`,
            () => <NumericTextBox style={{ width: "100%" }}
                value={currentTemplate.dateDividerPart}
                onChange={varDateDividerPartHandler} />
        );
    }
    else {
        renderPair(innerContent,
            window.captions.TemplateBreakers,
            "TemplateBreakers",
            () => <RadioGroup key="templateBreakers"
                data={WizardFunctions.getTemplateDividers()}
                value={currentTemplate.dividerType}
                layout="horizontal"
                onChange={dividerTypeHandler}
                label={window.captions.SameValueCells} />,
            true);
    }

    content.push(<div key="selector" className={isReports ? "k-d-flex-row" : null}>{innerContent}</div>)

    return content;
}
