import React, { useState, useEffect } from 'react';
import LoadingMask from "../../../components/loadingMask.jsx";
import WizardFunction from "../sharedUi/wizardFunctions.jsx";
import { TextBox, NumericTextBox, TextArea } from "@progress/kendo-react-inputs";
import {
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    CardFooter
} from "@progress/kendo-react-layout";
import { renderPair } from "../../cards/cardRenderFunctions.jsx";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";

export function ReportSqlView({ script, fields, onEdit }) {
    const [currentScript, setCurrentScript] = useState();

    useEffect(() => {
        setCurrentScript(script);
    }, [currentScript, script]);

    if (!currentScript || currentScript.id != script.item.id)
        setCurrentScript(script.item);

    if (!currentScript)
        return <div style={{ height: "300px" }}><LoadingMask /></div>;

    const changeNameHandler = (e) => {
        var newScript = currentScript;
        newScript.name = e.value;
        setCurrentScript(newScript);
        onEdit({
            index: script.index,
            item: newScript
        });
    };

    const changeScriptHandler = (e) => {
        var newScript = currentScript;
        newScript.script = e.value;
        setCurrentScript(newScript);
        onEdit({
            index: script.index,
            item: newScript
        });
    };

    const changeSheetIndexHandler = (e) => {
        var newScript = currentScript;
        newScript.sheetIndex = e.value;
        setCurrentScript(newScript);
        onEdit({
            index: script.index,
            item: newScript
        });
    };

    const changeSheetAddressHandler = (e) => {
        var newScript = currentScript;
        newScript.sheetAddress = e.value;
        setCurrentScript(newScript);
        onEdit({
            index: script.index,
            item: newScript
        });
    };

    var titleContent = [];
    var content = [];
    var scriptContent = [];

    renderPair(titleContent,
        window.captions.Caption,
        `ScriptName_${currentScript.id}`,
        () => <TextBox key="ScriptName"
            value={currentScript.name}
            onChange={changeNameHandler}/>
    );

    renderPair(content,
        window.captions.SheetNumber,
        `ScriptIndex_${currentScript.id}`,
        () => <NumericTextBox key="ScriptIndex"
            value={currentScript.sheetIndex}
            onChange={changeSheetIndexHandler} />
    );

    renderPair(content,
        window.captions.SheetAddress,
        `ScriptAddress_${currentScript.id}`,
        () => <TextBox key="ScriptAddress"
            value={currentScript.sheetAddress}
            onChange={changeSheetAddressHandler} />
    );


    renderPair(scriptContent,
        "",
        "ScriptAreaWarningKey",
        () => <div key="ScriptAreaWarning" className="k-d-flex-row">
            <span className="k-flex-column k-icon k-font-icon k-i-warning k-color-error"></span>
            {window.captions.ReportSqlRestriction}
        </div>,
        true);

    renderPair(scriptContent,
        "",
        "ScriptAreaContentKey",
        () => <TextArea key="ScriptAreaContent" value={currentScript.script} onChange={changeScriptHandler} rows={25} />,
        true);

    return <Card className="k-m-1">
        <CardHeader>
            <CardTitle>{currentScript.name}</CardTitle>
        </CardHeader>
        <CardBody>
            <div className="dm-container">{titleContent}</div>
            <div className="dm-container dm-container-two">{content}</div>
            <div className="dm-container">{scriptContent}</div>
        </CardBody>
        <CardFooter>
            <PanelBar key={`PanelBarTemplates${currentScript.id}`} className="k-panelbar dm-m-bottom">
                <PanelBarItem key="templatesItem"
                    title={window.captions.MergingVariables}
                    style={{ maxHeight: "500px", overflow: "hidden" }}>
                    <div className="k-m-1" style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                        gap: "8px",
                        maxHeight: "300px",
                        overflow: "auto"
                    }}>
                        {WizardFunction.getSqlTemplateFields(fields)}
                    </div>
                </PanelBarItem>
            </PanelBar>
        </CardFooter>
    </Card>
}