import React, { useState, useEffect } from 'react';
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { statGeneralTypeEnum, dateTimeWidth, getFormatDateTime } from "../../../utils/systemConstants.jsx";
import { Button } from "@progress/kendo-react-buttons";
import { TextBox } from "@progress/kendo-react-inputs";
import { renderPair } from "../../cards/cardRenderFunctions.jsx";
import { DateTimePicker } from "@progress/kendo-react-dateinputs";
import WizardFunction from "../sharedUi/wizardFunctions.jsx";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import LoadingMask from "../../../components/loadingMask.jsx";
import ViewEditTreeView from "../../../components/controls/viewEditTreeView.jsx";
import { fetchPost } from "../../../utils/requestsHelper.jsx";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import ExportQueues from "../../exportQueue/exportQueues.jsx";
import { TemplateName } from "../sharedUi/controls/templatedName.jsx";

export function GroupUI({ handleSelectTab, template, onAddItem, onEdit, getRequest, templateFields }) {
    var content = [];
    const style = { width: "100%" };
    const sources = WizardFunction.getSources();
    const [isVisible, setIsVisible] = useState(false);
    const [currentTemplate, setCurrentTemplate] = useState(null);
    const [users, setUsers] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState(null);
    const [exportStarted, setExportStarted] = useState(false);
    const [hasQueue, setHasQueue] = useState(false);

    const toggleDialog = () => {
        setIsVisible(!isVisible);
    };

    useEffect(() => {
        setCurrentTemplate(template);
        loadUsers();
    }, [template]);

    const generateHandler = (e) => {
        var request = {
            id: currentTemplate.id,
            isReport: false,
            type: currentTemplate.type,
            filters: currentTemplate.mainFilter.selectedFilters
        }

        generateTemplate(request);
    };

    const changeNameHandler = (e) => {
        var template = currentTemplate;
        template.name = e.value;
        onEdit(template);
    };

    const changeSourceHandler = (e) => {
        var newTemplate = currentTemplate;
        newTemplate.source = e.value.id;
        onEdit(newTemplate);
    };

    const changeAllowedUsersHandler = (e) => {
        var newTemplate = currentTemplate;
        newTemplate.allowedUsers = WizardFunction.getAllowedUserModels(currentTemplate.allowedUsers, e)
        onEdit(newTemplate);
    };

    if (!currentTemplate || isLoaded)
        return <div style={{ height: "300px" }}><LoadingMask /></div>;

    const titleContent = [];

    if (error) {
        return <Dialog width={400}>
            <MessageMask inline error={error} />
            <DialogActionsBar>
                <Button onClick={() => { setError(null) }}>{window.captions.Close}</Button>
            </DialogActionsBar>
        </Dialog>;
    }

    renderPair(titleContent,
        window.captions.Identifier,
        `StatFolderIdentifier_${currentTemplate.id}`,
        () => <TextBox key="FolderIdentifier" value={currentTemplate.id} readOnly={true} />
    );

    renderPair(titleContent,
        window.captions.Author,
        `StatAuthor_${currentTemplate.id}`,
        () => <TextBox key="FolderAuthor" value={currentTemplate.author} readOnly={true} />
    );

    if ("created" in currentTemplate) {
        renderPair(titleContent,
            window.captions.Created,
            `StatCreated_${currentTemplate.id}`,
            () => <DateTimePicker key="Created"
                value={new Date(currentTemplate.created)}
                width={dateTimeWidth}
                disabled
                formatPlaceholder={getFormatDateTime()}
                readOnly={true} />
        );
    }

    if (currentTemplate.id !== 0) {
        renderPair(titleContent,
            window.captions.Execute,
            `StatGenerateButton_${currentTemplate.id}`,
            () => <Button
                disabled={currentTemplate.id === 0 || currentTemplate.isEdited}
                icon="arrow-chevron-right"
                onClick={generateHandler}>{window.captions.MakeDocument}</Button>
        );
    }

    content.push(<div key="titleUi" className="dm-container dm-container-two">{titleContent}</div>);
    content.push(<div key="dividerUi" className="dm-menu-divider" />);

    renderPair(content,
        window.captions.Caption,
        `StatName_${currentTemplate.id}`,
        () => <TextBox key="Name" value={currentTemplate.name} onChange={changeNameHandler} />
    );

    renderPair(content,
        window.captions.Source,
        `StatSource_${currentTemplate.id}`,
        () => <DropDownList style={style}
            data={sources}
            textField="name"
            disabled={currentTemplate.id > 0}
            dataItemKey="id"
            value={WizardFunction.getSelectedSource(sources, currentTemplate.source)}
            onChange={changeSourceHandler} />
    );

    renderPair(content,
        window.captions.FileCaptionTemplate,
        `StatTemplateName_${currentTemplate.id}`,
        () => <TemplateName key="StatTemplateName"
            template={currentTemplate}
            fields={templateFields}
            onEdit={onEdit} />
    );

    renderPair(content,
        "",
        "AllowedUsers",
        () =>
            <div>
                <Button type="button" onClick={toggleDialog} id="open-dialog">
                    {window.captions.UsersAccess}
                </Button>
                {isVisible && (
                    <Dialog title={currentTemplate.name}
                        onClose={toggleDialog}
                        minWidth={500}
                        maxWidth={700}
                        height={700}>
                        <ViewEditTreeView
                            key="usersEditTreeView"
                            dataTree={WizardFunction.setAllowedUserModels(users, currentTemplate.allowedUsers)}
                            viewField="isView"
                            editField="isEdit"
                            onCheckChange={changeAllowedUsersHandler} />
                    </Dialog>
                )}
            </div>,
        true);

    return currentTemplate
        ? <TabStrip selected={0}
            key="groupUi"
            style={{ maxWidth: "1500px", width: "100%" }}
            className="k-m-1"
            onSelect={handleSelectTab}>
            <TabStripTab title={currentTemplate.name}>
                <div className="dm-full-height dm-m-top">
                    {content}
                    <Button key="adddocument" onClick={() => onAddItem(getRequest(statGeneralTypeEnum.document))}>
                        {window.captions.AddDocument}
                    </Button>
                </div>
                {exportStarted
                    ? <Dialog width={400}>
                        <div style={{ minHeight: "100px", maxHeight: "700px", overflow: "auto" }}>
                            <ExportQueues
                                inDialog
                                onCheckQueue={(e) => setHasQueue(e)}
                                isStarted={true} />
                            {hasQueue ? null : window.captions.EmptyQueue}
                        </div>
                        <DialogActionsBar>
                            <Button onClick={() => { setExportStarted(false) }}>{window.captions.Close}</Button>
                        </DialogActionsBar>
                    </Dialog>
                    : null}
            </TabStripTab>
        </TabStrip>
        : <LoadingMask />;

    function loadUsers() {
        setError(null);
        fetchPost(window.constants.loadWizardItem,
            { type: 2 },
            (data) => {
                setUsers(data);
            },
            (ex) => {
                setUsers([]);
                showError(ex);
            });
    }

    function generateTemplate(request) {
        fetchPost(`${window.constants.statisticRender}/${request.type}/${request.id}/${0}`,
            [],
            () => {
                setExportStarted(true);
                setHasQueue(true);
            },
            ex => {
                showError(ex);
                setIsLoaded(true);
            }
        );
    };

    function showError(ex) {
        setError(ex);
        setIsLoaded(false);
    }
}