import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { DateTimePicker, DatePicker } from "@progress/kendo-react-dateinputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { NumericTextBox, TextArea } from "@progress/kendo-react-inputs";
import { Skeleton } from "@progress/kendo-react-indicators";
import { addDays } from "@progress/kendo-date-math";
import DropDownAlternating from "../../components/controls/dropDownAlternating.jsx";
import InlineDialog from "../../components/inlineDialog.jsx";
import { fetchGet, fetchPost } from "../../utils/requestsHelper.jsx";
import { getLocation } from "../../utils/locationHelper.jsx";
import FieldsControl from "../cards/controls/fieldsControl.jsx";
import CardSaveFunctions from "../cards/cardSaveFunctions.jsx";
import { getAddressText, getPhoneText, renderPair, renderMasker } from "../cards/cardRenderFunctions.jsx";
import MessageMask from "../../components/messageMask.jsx";
import QuestionnaireDialogHistory from "../questionnaire/questionnaireDialogHistory.jsx";
import DialogPromiseSchedulerAdder from "../dialogs/dialogPromiseSchedulerAdder.jsx";
import ValidationMessage from "../../components/validationMessage.jsx";
import DialogAnswerPicker from "../dialogs/dialogAnswerPicker.jsx";
import {
    getFormatDateTime,
    getFormatDate,
    dateTimeWidth,
    dateWidth,
    numericWidth,
    historyAttachmentTypes,
    entitySourcesNames,
    getMoodsPath
} from
    "../../utils/systemConstants.jsx";

export default function DialogHistory({
    inline, cardEntity, cardId, clientId, projectId, stageId, stateId,
    activeTasks, phones, addresses, emails, urls,
    options, sources, clientName, amountDebtInitial, amountDebtMax, canAddSchedule,
    onClose }) {
    const emptyResult = {
        id: 0,
        dataType: 0,

        showPromiss: false,
        showDeclared: false,
        showDelayReason: false,
        showSkip: false,

        isNextContact: false,
        isNextHidden: false,
        isPaymentRequired: false,
        isDeclaredRequired: false,
        isContWithRequired: false,
        isMoodIndicatorRequired: false,

        hasNextContact: false,
        nextContactMin: 0,
        nextContactMax: 0,

        hasNextPromiss: false,
        nextPromissMin: 0,
        nextPromissMax: 0,

        hasNextPayment: false,
        nextPaymentMin: 0,
        nextPaymentMax: 0,

        hasPromissPaymentDays: false,
        promissPaymentDays: 0,

        isCommentRequired: false,
        isCommentHidden: false,
        fields: []
    };

    const [pending, setPending] = useState(window.captions.LoadingData);
    const [storage, setStorage] = useState({
        scenarioList: [],
        types: [],
        linkedPerson: [],
        resultDefault: [],
        clients: [],
        delayReasonList: [],
        moods: [],
        canAddPayment: false,
        fillEntity: []
    });

    let results = [];

    const [error, setError] = useState(null);
    const [validate, setValidate] = useState(false);
    const [fieldsList, setFieldsList] = useState(null);
    const [eventsList, setEventsList] = useState(null);
    const [nextEventId, setNextEventId] = useState(0);

    const [contactId, setContactId] = useState(0);
    const [selectedClientId, setClientId] = useState(0);
    const [resultId, setResultId] = useState(0);
    const [scenario, setScenario] = useState(null);
    const [nextContact, setNextContact] = useState(null);
    const [promissDate, setPromissDate] = useState(null);
    const [promissValue, setPromissValue] = useState(0);
    const [paymentDate, setPaymentDate] = useState(null);
    const [paymentValue, setPaymentValue] = useState(0);
    const [actualizationId, setActualizationId] = useState(0);
    const [comment, setComment] = useState("");

    const [showQuestion, setShowQuestion] = useState(false);
    const [showPromisePayment, setShowPromisePayment] = useState(false);
    const [delayReason, setDelayReason] = useState(null);
    const [promisePaymentId, setPromisePaymentId] = useState(0);
    const [moodId, setMoodId] = useState(0);
    const [showDialogAnswerPicker, setShowDialogAnswerPicker] = useState(false);
    const [accounts, setAccounts] = useState([]);
    const [selectedAccount, setSelectedAccount] = useState(null);
    const [selectedAccountLimit, setSelectedAccountLimit] = useState(null);
    const [location, setLocation] = useState(null);

    const [result, setResult] = useState(emptyResult);

    const user = useSelector(state => state.header.model);

    useEffect(() => {
        if (window.systemConfig.userHistoryGeolocation) {
            getLocation(coords => setLocation(coords), () => { });
        }

        fetchData();
    }, []);

    if (pending || error) {
        var message = <MessageMask inline text={pending} error={error} />;
        var buttons = null;
        if (inline && pending === window.captions.LoadingData) {
            message = getSkeleton();
            buttons = getSkeletonButtons();
        }

        return inline
            ? <InlineDialog title={window.captions.AddAction} buttons={buttons} onClose={() =>
                onClose(false)}>
                {message}
            </InlineDialog>
            : <Dialog title={window.captions.AddAction} onClose={() => onClose(false)} width={650}>
                {message}
            </Dialog>;
    }

    const entity = cardEntity;
    const isLoan = entity === entitySourcesNames.loan;
    const isClient = entity === entitySourcesNames.client;
    const controls = [];
    let controlsMood = "";
    let controlsScenarioAnswer = "";
    let style = { width: "100%" };

    if (result.showMoodIndicator) {
        style.margin = "0 5px 0 0";
        controlsMood = getMoodIndicators();
    }

    if (scenario !== null && scenario.id > 0 && showDialogAnswerPicker) {
        renderPair(controls,
            window.captions.ScenarioAnswers,
            "DialogAnswerPicker",
            () => <DialogAnswerPicker style={style}
                textField="name"
                dataItemKey="id"
                scenarioId={scenario.id}
                loanId={cardId}
                clientId={clientId}
                source={cardEntity}
                projectId={projectId}
                stateId={stateId}
                stageId={stageId}
                onClose={(data) => handleScenario(data)} />);
    }
    var scenarioId = 0;
    if (activeTasks && activeTasks.length > 0 && scenario === null) {
        var activeTask = activeTasks[0];
        scenarioId = storage.scenarioList.find((obj) => obj.id === activeTask.scenarioId) ?? 0;

        if (scenarioId === 0) {
            storage.scenarioList.push({ id: activeTask.scenarioId, name: activeTask.scenario });
            scenarioId = storage.scenarioList.find((obj) => obj.id === activeTask.scenarioId) ?? 0;
        }
    } else {
        scenarioId = scenario ? scenario : storage.scenarioList[0];
    }

    if ((isLoan || isClient) && storage.scenarioList.length > 1) {
        if (scenario !== null && scenario.id > 0) {
            let styleScenario = { width: "65px" };
            styleScenario.margin = "0 0 0 15px";
            controlsScenarioAnswer = <Button style={styleScenario} key="dialogAnswerPicker" onClick={() => setShowDialogAnswerPicker(true)}>? </Button>;
        }
        renderPair(controls,
            window.captions.Scenario,
            "Scenario",
            () => <div className="dm-wrapper">
                <div className="dm-wrapper-content">
                    <DropDownList style={style}
                        textField="name"
                        dataItemKey="id"
                        data={storage.scenarioList}
                        value={scenarioId}
                        onChange={changedScenario} />
                    {controlsScenarioAnswer}
                </div>
            </div>);
    }

    renderPair(controls,
        window.captions.WordType,
        "Type",
        () => <div className="dm-wrapper">
            <div className="dm-wrapper-content">
                <DropDownAlternating
                    id="HistoryDialogType"
                    treeData={storage.types}
                    selectedId={resultId}
                    selectionChanged={changeResult}
                    selectionCanceled={resultCanceled}
                    placeholder={window.captions.NotificationEnterAction}
                    style={style} />
            </div>{controlsMood}
        </div>);

    if (result.showDelayReason) {
        renderPair(controls,
            window.captions.Reason,
            "DelayReason",
            () => <DropDownList style={style}
                textField="name"
                dataItemKey="id"
                data={storage.delayReasonList}
                value={delayReason}
                onChange={changedDelayReasonId} />);
    }

    if (!result.isNextHidden) {
        renderPair(controls,
            window.captions.NextContact,
            "NextContact",
            () => <DateTimePicker
                onChange={e => setNextContact(e.value)}
                width={dateTimeWidth}
                formatPlaceholder={getFormatDateTime()}
                min={getRangeDate(result.hasNextContact,
                    result.nextContactMin,
                    storage.resultDefault.nextContactMin)}
                max={getRangeDate(result.hasNextContact,
                    result.nextContactMax,
                    storage.resultDefault.nextContactMax)}
                defaultValue={nextContact} />);
    }

    if (result.showPromiss) {
        renderPair(controls,
            window.captions.PromisePayment,
            "Promiss",
            () => <div className="dm-wrapper">
                <NumericTextBox width={numericWidth}
                    value={promissValue}
                    onChange={e => setPromissValue(e.value)} />
                <DatePicker
                    onChange={e => setPromissDate(e.value)}
                    width={dateWidth}
                    formatPlaceholder={getFormatDate()}
                    min={getRangeDate(result.hasNextPromiss,
                        result.nextPromissMin,
                        storage.resultDefault.nextPromissMin)}
                    max={getRangeDate(result.hasNextPromiss,
                        result.nextPromissMax,
                        storage.resultDefault.nextPromissMax)}
                    defaultValue={promissDate} />
            </div>);
    }

    if (result.showDeclared) {
        renderPair(controls,
            window.captions.DeclaredPayment,
            "Payment",
            () => <div className="dm-wrapper">
                <NumericTextBox width={numericWidth}
                    value={paymentValue}
                    onChange={e => setPaymentValue(e.value)} />
                <DatePicker
                    onChange={e => setPaymentDate(e.value)}
                    width={dateWidth}
                    formatPlaceholder={getFormatDate()}
                    min={getRangeDate(result.hasNextPayment,
                        -result.nextPaymentMax,
                        -storage.resultDefault.nextPaymentMax)}
                    max={getRangeDate(result.hasNextPayment,
                        -result.nextPaymentMin,
                        -storage.resultDefault.nextPaymentMin)}
                    defaultValue={paymentDate} />
            </div>);
    }

    if (result.dataType !== 0 || result.isContWithRequired) {
        const clientsList = [];
        var linkedSelected = null;
        for (let key in storage.clients) {
            var client = storage.clients[key];
            clientsList.push({ id: client.id, name: client.name });
        }
        for (let key in storage.linkedPerson) {
            var person = storage.linkedPerson[key];
            if (person.id === selectedClientId)
                linkedSelected = person;
            clientsList.push({ id: person.id, name: `${person.name} (${person.clientTypeName})` });
        }

        var selected = clientsList.find(i => i.id === selectedClientId);
        renderPair(controls,
            window.captions.ContactPerson,
            "Contact",
            () => <DropDownList style={{ width: "100%" }}
                textField="name"
                dataItemKey="id"
                data={clientsList}
                value={selected}
                onChange={changedClientId} />);

        var contactItems = null;
        var contactItem = null;
        var contactLabel = null;

        if (result.dataType === historyAttachmentTypes.phone && selectedClientId > 0) {
            var items = getPhonesList(linkedSelected ? linkedSelected.phones : phones);
            if (items.length > 0) {
                contactLabel = window.captions.Phone;
                contactItems = items;
                contactItem = items.find(i => i.id === contactId);
            }
        } else if (result.dataType === historyAttachmentTypes.address && selectedClientId > 0) {
            var items = getAddressesList(linkedSelected ? linkedSelected.addresses : addresses);
            if (items.length > 0) {
                contactLabel = window.captions.Address;
                contactItems = items;
                contactItem = items.find(i => i.id === contactId);
            }
        } else if (result.dataType === historyAttachmentTypes.email && selectedClientId > 0) {
            var items = getEmailsList(linkedSelected ? linkedSelected.emails : emails);
            if (items.length > 0) {
                contactLabel = window.captions.Email;
                contactItems = items;
                contactItem = items.find(i => i.id === contactId);
            }
        } else if (result.dataType === historyAttachmentTypes.url && selectedClientId > 0) {
            var items = getUrlsList(linkedSelected ? linkedSelected.urls : urls);
            if (items.length > 0) {
                contactLabel = window.captions.Url;
                contactItems = items;
                contactItem = items.find(i => i.id === contactId);
            }
        }

        if (contactItems && contactLabel) {
            renderPair(controls,
                contactLabel,
                "ContactItem",
                () => <DropDownList style={{ width: "100%" }}
                    textField="name"
                    dataItemKey="id"
                    data={contactItems}
                    value={contactItem}
                    onChange={changedContactId} />);
        }
    }

    let modal = null;
    if (showQuestion) {
        modal = <QuestionnaireDialogHistory
            key="questionnaireDialogHistory"
            clientId={selectedClientId}
            projectId={projectId}
            stageId={stageId}
            source={cardEntity}
            loanId={cardId}
            onClose={(data) => handleQuestion(data)} />;
    }

    if (showPromisePayment) {
        modal = <DialogPromiseSchedulerAdder
            key="dialogPromiseSchedulerAdder"
            cardId={cardId}
            projectId={projectId}
            amountDebtInitial={amountDebtInitial}
            amountDebtMax={amountDebtMax}
            delayReason={delayReason}
            onClose={(data) => handlePromisePayment(data)} />;
    }

    if (fieldsList && result.fields && result.fields.length > 0) {
        const fields = [];
        for (let key in result.fields) {
            const id = result.fields[key];
            if (id < 0) {
                if (!eventsList || eventsList.length <= 0)
                    continue;

                var eventModel = eventsList.find(i => i.id === id);
                if (!eventModel)
                    continue;

                controls.push(<div style={{ display: "block" }} key={`buttonAdd${id}`}>
                    <Button className="addEvent"
                        iconClass="dm-i dm-i-plus"
                        onClick={() => addEvent(id)}>
                        {eventModel.name}
                    </Button>
                    {getEventList(eventModel.id, eventModel.items)}
                </div>);
                continue;
            }

            var fieldId = `field${id}`;
            const fieldModel = fieldsList[fieldId];
            if (fieldModel) {
                fields[fieldId] = fieldModel;
            }
        }

        controls.push(<FieldsControl entity={"history"}
            fields={fields}
            sources={sources}
            isEditMode={true}
            key="HistoryFields"
            setFields={setEntityFields} />);
    }

    if (result.isAccountChoice) {
        renderPair(controls,
            window.captions.Accounts,
            "Accounts",
            () => <DropDownList style={{ width: "100%" }}
                textField="name"
                dataItemKey="id"
                data={accounts}
                value={selectedAccount}
                onChange={changedAccount} />);

        if (selectedAccount != null && selectedAccount.id > 0 && selectedAccount.limits.length > 0) {
            renderPair(controls,
                window.captions.AccountLimits,
                "AccountLimits",
                () => <DropDownList style={{ width: "100%" }}
                    textField="name"
                    dataItemKey="id"
                    data={selectedAccount.limits}
                    value={selectedAccountLimit}
                    onChange={changedAccountLimit} />);
        }
    }
    if (!result.isCommentHidden) {
        renderPair(controls,
            window.captions.Comment,
            "Comment",
            () => <TextArea onChange={changeComment}
                value={comment}
                rows={2}
                required={result.isCommentRequired}
                autoSize={true}
                style={{ width: "100%" }} />);
    }

    var customButtons = [];
    if (result.isPromiseScheduleAction && storage.canAddPayment && canAddSchedule)
        customButtons.push(<Button key="promiseShedulerButton"
            onClick={() => setShowPromisePayment(true)}>
            {window.captions.PromiseScheduler}
        </Button>);

    if (result.isQuestionnaireEnabled && result.fields.length > 0 && !actualizationId && (isLoan || isClient)) {
        customButtons.push(<Button key="questionButton"
            onClick={() => setShowQuestion(true)}>
            {window.captions.Questionnaire}
        </Button>);
    }
    if (validate) {
        modal = <ValidationMessage key="validateDialog" close={() => setValidate(false)} text={validate} error={true} />;
    }

    var dialogActions = <DialogActionsBar>
        <Button onClick={() => onClose(false)}>{window.captions.Cancel}</Button>
        {customButtons}
        <Button themeColor="primary" iconClass="dm-i dm-i-save" onClick={save}>{window
            .captions
            .Save
        }</Button>
    </DialogActionsBar>;

    return inline
        ? [
            <InlineDialog title={window.captions.AddAction}
                key="dialogHistory"
                buttons={dialogActions}
                onClose={() => onClose(false)}>
                <div className="dm-container dm-container-slim">
                    {controls}
                </div>
            </InlineDialog>,
            modal
        ]
        : [
            <Dialog title={window.captions.AddAction}
                key="dialogHistory"
                onClose={() => onClose(false)}
                width={650}>
                <div className="dm-container dm-no-padding">
                    {controls}
                </div>
                {dialogActions}
            </Dialog>,
            modal
        ];

    function fetchData() {
        setPending(window.captions.LoadingData);
        setError(null);
        const source = cardEntity;
        const fetchStageId = stageId ? stageId : 0;
        const fetchProjectId = projectId ? projectId : 0;
        const clients = [
            { id: 0, name: window.captions.NotDefinded },
            { id: clientId, name: clientName }
        ];

        fetchGet(`${window.constants.getHistoryModel}/${source}/${cardId}/${fetchStageId}/${fetchProjectId}`,
            (data) => {
                setStorage({
                    scenarioList: data.scenarioList,
                    types: data.types,
                    linkedPerson: data.linkedPersons,
                    resultDefault: data.resultDefault,
                    clients: clients,
                    delayReasonList: data.delayReasons,
                    delayReason: data.delayReasonDefault,
                    moods: data.moods,
                    canAddPayment: data.canAddPayment,
                    fillEntity: data.fillEntity
                });

                setDelayReason(data.delayReasonDefault);
                setPending(null);
                setError(null);

                if (options) {
                    if (options.contactResultId > 0) {
                        changeResult({ value: options.contactResultId });
                    } else if (options.contactTypeId > 0) {
                        //TODO changeType({ value: options.contactTypeId });
                    }
                    if (options.phone) {
                        const contact = phones.find(i => i.phoneNumber === options.phone.phoneNumber);
                        if (contact) {
                            setClientId(clientId);
                            setContactId(contact.id);
                        }
                    }
                }
            },
            (ex) => {
                setError(ex.stack ? ex.stack : ex);
                setPending(null);
            });
    }

    function changeResult(e) {
        const newResultId = e.value;
        if (newResultId === undefined) {
            resultCanceled();
            return;
        }            

        let result = results.find((i) => i.id === newResultId);
        if (!result) {
            fetchGet(`${window.constants.getHistoryResult}/${newResultId}`,
                (data) => {
                    if (data == null)
                        return;

                    results.push(data);
                    changeResult(e);
                },
                () => { });
            return;
        }

        result = results.find((i) => i.id === newResultId);
        if (result.isAccountChoice) {
            fetchGet(`${window.constants.getAccountsModel}/${cardId}`,
                (data) => {
                    setAccounts(data);
                    if (result.isAccountChoice && data) {
                        var account = data.find(obj => obj.id === -1);
                        setSelectedAccount(account);

                        if (account.id > 0 && account.limits.length > 0 &&
                            (selectedAccountLimit === null || selectedAccountLimit.id === 0)) {
                            setSelectedAccountLimit(account.limits.find(obj => obj.id === -1));
                        }
                    }
                },
                () => { });
        }
        const contact = options && options.phone
            ? phones.find(i => i.phoneNumber === options.phone.phoneNumber)
            : null;

        setResultId(newResultId);
        setResult(result);
        setClientId(clientId)
        setContactId(contact ? contact.id : 0);
        setFieldsList(result.designFields);
        setEventsList(result.historyEvents);
    }

    function resultCanceled() {
        setResult(emptyResult);
        setContactId(0);
        setFieldsList([]);
        setEventFields([]);
    }

    function changedClientId(e) {
        setClientId(e.target.value.id);
        setContactId(0);
    }

    function changedContactId(e) {
        setContactId(e.target.value.id);
    }

    function changedAccount(e) {
        var account = e.target.value;
        setSelectedAccount(account);
        if (account != null && account.id > 0 && account.limits.length > 0) {
            setSelectedAccountLimit(account.limits.find(obj => { return obj.id === -1; }));
        }
    }
    function changedAccountLimit(e) {
        setSelectedAccountLimit(e.target.value);
    }

    function changedDelayReasonId(e) {
        setDelayReason(e.target.value);
    }

    function changedScenario(e) {
        setScenario(e.target.value);
    }

    function changedMoodId(e) {
        setMoodId(e.target.value.id);
    }

    function changeComment(e) {
        setComment(e.value);
    }

    function isValid() {
        const errorSystem = [];
        if (!result || result.id === 0) {
            errorSystem.push(window.captions.NotificationEnterAction);
            return errorSystem;
        }

        if (result.isNextContact && !nextContact) {
            errorSystem.push(window.captions.NotificationEnterNextDate);
        } else if (nextContact && nextContact <
            getRangeDate(result.hasNextContact, result.nextContactMin, storage.resultDefault.nextContactMin))
            errorSystem.push(window.captions.NotificationEnterNextDate);

        if (result.showDelayReason && delayReason.id < 1) {
            errorSystem.push(window.captions.MustSetDelayreason);
        }

        if (result.isMoodIndicatorRequired && moodId < 1) {
            errorSystem.push(window.captions.SelectMoodIndicator);
        }

        if (result.isPaymentRequired && (!promissDate || promissValue <= 0) ||
            !promissDate && promissValue > 0 ||
            promissDate && promissValue <= 0 ||
            promissValue < 0) {
            errorSystem.push(window.captions.NotificationEnterPaymentDate);
        }

        if (result.isDeclaredRequired && (!paymentDate || paymentValue <= 0) ||
            !paymentDate && paymentValue > 0 ||
            paymentDate && paymentValue <= 0 ||
            paymentValue < 0) {
            errorSystem.push(window.captions.NotificationEnterDeclaredDate);
        }

        if (result.isCommentRequired && !comment) {
            errorSystem.push(window.captions.CommentRequired);
        }

        if (result.dataType === historyAttachmentTypes.phone && selectedClientId > 0 && !contactId) {
            errorSystem.push(window.captions.NotificationEnterPhone);
        }

        if (result.dataType === historyAttachmentTypes.address && selectedClientId > 0 && !contactId) {
            errorSystem.push(window.captions.NotificationEnterAddress);
        }

        if (result.dataType === historyAttachmentTypes.email && selectedClientId > 0 && !contactId) {
            errorSystem.push(window.captions.NotificationEnterEmail);
        }

        if (result.dataType === historyAttachmentTypes.url && selectedClientId > 0 && !contactId) {
            errorSystem.push(window.captions.NotificationEnterUrl);
        }

        if (result.isAccountRequired && (selectedAccount == null || selectedAccount.id <= 0)) {
            errorSystem.push(window.captions.NotificationEnterAccount);
        }

        return errorSystem;
    }

    function save() {
        const errorSystem = isValid();
        if (errorSystem.length > 0) {
            setPending(null);
            setValidate(errorSystem);
            return;
        }

        const errorFields = CardSaveFunctions.validateRequired(fieldsList, null, {});
        if (errorFields.length > 0) {
            setPending(null);
            setValidate(errorFields);
            return;
        }

        var saveData = CardSaveFunctions.renderValues(fieldsList);
        saveData.clientId = selectedClientId;
        saveData.contactId = contactId;
        saveData.resultId = resultId;
        saveData.scenarioId = scenario ? scenario.id : 0;
        saveData.nextContact = nextContact;
        saveData.promissDate = promissDate;
        saveData.promissValue = promissValue;
        saveData.paymentDate = paymentDate;
        saveData.paymentValue = paymentValue;
        saveData.comment = comment;
        saveData.actualizationId = actualizationId;
        saveData.delayReason = delayReason.id;
        saveData.promisePaymentId = promisePaymentId;
        saveData.moodId = moodId;
        saveData.location = location && location.latitude > 0
            ? {
                latitude: location.latitude,
                longitude: location.longitude,
                accuracy: location.accuracy
            }
            : null;

        if (result.isAccountChoice && selectedAccount != null && selectedAccount.id !== -1) {
            let saveAccounts = [];
            if (selectedAccount.id === -2) {
                var account = accounts.filter(item => item.id !== -1 && item.id !== -2);
                saveAccounts = account;
            } else {
                if (selectedAccountLimit == null || selectedAccountLimit.id === -1) {
                    var limitArray = selectedAccount.limits.filter(item => item.id !== -1);
                    selectedAccount.limits = limitArray;
                }
                else {
                    var limit = selectedAccount.limits.filter(item => item.id === selectedAccountLimit.id);
                    selectedAccount.limits = limit;
                }
                saveAccounts.push(selectedAccount);
            }
            saveData.accounts = saveAccounts;
        }

        var events = [];
        for (let key in eventsList) {
            var eventType = eventsList[key];
            if (!eventType || !eventType.items || eventType.items.length <= 0)
                continue;

            for (let eventKey in eventType.items) {
                var event = eventType.items[eventKey];
                var eventSaveModel = CardSaveFunctions.renderValues(event.fields);
                eventSaveModel.id = event.id;
                eventSaveModel.eventTypeId = eventType.id;
                events.push(eventSaveModel);
            }
        }
        saveData.events = events;

        if (options.dialId) {
            saveData.dialId = options.dialId;
        }

        if (options.workItemId) {
            saveData.workItemId = options.workItemId;
        }

        setPending(window.captions.SavingData);
        setError(null);
        fetchPost(`${window.constants.saveHistory}/${cardEntity}/${cardId}`,
            saveData,
            (data) => onClose(data),
            () => onClose(false));
    }

    function clone(obj) {
        var copy;
        if (null == obj || "object" != typeof obj)
            return obj;

        if (obj instanceof Date) {
            copy = new Date();
            copy.setTime(obj.getTime());
            return copy;
        }

        if (obj instanceof Array) {
            copy = [];
            for (var i = 0, len = obj.length; i < len; i++) {
                copy[i] = clone(obj[i]);
            }
            return copy;
        }

        if (obj instanceof Object) {
            copy = {};
            for (var attr in obj) {
                if (obj.hasOwnProperty(attr)) copy[attr] = clone(obj[attr]);
            }
            return copy;
        }

        return undefined;
    }

    function addEvent(eventTypeId) {
        var eventType = eventsList.find((i) => i.id === eventTypeId);
        const fields = [];

        for (let key in eventType.fieldIdList) {
            var fieldId = `field${eventType.fieldIdList[key]}`;
            const fieldModel = eventType.designFields[fieldId];
            if (fieldModel)
                fields[fieldId] = clone(fieldModel);
        }

        const newEvent = { id: nextEventId, fields: fields };
        if (!eventType.items)
            eventType.items = [newEvent];
        else
            eventType.items.push(newEvent);

        setEventsList(eventsList);
        setNextEventId(nextEventId - 1);
    }

    function cancelEvent(typeId, eventId) {
        var eventType = eventsList.find((i) => i.id === typeId);
        if (!eventType || !eventType.items || eventType.items.length <= 0)
            return;

        const index = eventType.items.findIndex((i) => i.id === eventId);
        if (index < 0)
            return;
        eventType.items.splice(index, 1);

        setEventsList(eventsList);
    }

    function setEventFields(fields, eventId, typeId) {
        var eventType = eventsList.find((i) => i.id === typeId);
        if (!eventType || !eventType.items || eventType.items.length <= 0)
            return;

        var event = eventType.items.find((i) => i.id === eventId);
        event.fields = fields;
        setEventsList(eventsList);
    }

    function setEntityFields(fields) {
        setFieldsList(fields);
    }

    function handleQuestion(model) {
        setShowQuestion(false);
        setActualizationId(model);
    }

    function handlePromisePayment(id) {
        setShowPromisePayment(false);
        setPromisePaymentId(id);
    }

    function handleScenario(resultId) {
        setShowDialogAnswerPicker(false);
        changeResult({ value: resultId });
    }

    function getPhonesList(items) {
        if (!items)
            return [];

        const phoneList = [{ id: 0, name: window.captions.NotChosed }];
        var types = sources.phoneTypes;
        var localCode = sources.localCode;
        var phoneCodes = sources.phoneCodes;
        var entityFields = storage.fillEntity;
        for (let key in items) {
            const item = items[key];
            const type = types.find((i) => i.id === item.type);
            const phoneNumber = user.phoneMask
                ? renderMasker(item.phoneNumber, user.phoneMask)
                : getPhoneText(item.phoneNumber, phoneCodes, localCode);
            const fields = entityFields.find((i) => i.entityId === item.id && i.source === 14);
            const viewFields = [];
            if (fields) {
                for (let tmp in fields.fields) {
                    const field = fields.fields[tmp];
                    if (field.stringValue.length <= 0)
                        continue;
                    viewFields.push(`${field.name}: ${field.stringValue}`);
                }
            }
            phoneList.push({ id: item.id, name: type ? `${phoneNumber} (${type.name}) ${viewFields}` : phoneNumber });
        }
        return phoneList;
    }

    function getAddressesList(items) {
        if (!items)
            return [];

        var entityFields = storage.fillEntity;
        const addressesList = [{ id: 0, name: window.captions.NotChosed }];
        for (let key in items) {
            const field = items[key];
            const name = getAddressText(field.country,
                field.postCode,
                field.region,
                field.district,
                field.city,
                field.typeCity,
                field.street,
                field.typeStreet,
                field.house,
                field.typeHouse,
                field.corpus,
                field.flat);

            const fields = entityFields.find((i) => i.entityId === field.id && i.source === 15);
            const viewFields = [];
            if (fields) {
                for (let tmp in fields.fields) {
                    const customField = fields.fields[tmp];
                    if (customField.stringValue.length <= 0)
                        continue;
                    viewFields.push(`${customField.name}: ${customField.stringValue}`);
                }
            }
            addressesList.push({ id: field.id, name: `${name} ${viewFields}` });
        }

        return addressesList;
    }

    function getEmailsList(items) {
        if (!items)
            return [];

        var entityFields = storage.fillEntity;
        const emailsList = [{ id: 0, name: window.captions.NotChosed }];
        var types = sources.emailTypes;
        for (let key in items) {
            const field = items[key];
            const type = types.find((i) => i.id === field.type);
            const fields = entityFields.find((i) => i.entityId === field.id && i.source === 16);
            const viewFields = [];
            if (fields) {
                for (let tmp in fields.fields) {
                    const customField = fields.fields[tmp];
                    if (customField.stringValue.length <= 0)
                        continue;
                    viewFields.push(`${customField.name}: ${customField.stringValue}`);
                }
            }
            emailsList.push({ id: field.id, name: type ? `${field.email} (${type.name}) ${viewFields}` : field.email });
        }

        return emailsList;
    }

    function getUrlsList(items) {
        if (!items)
            return [];

        var entityFields = storage.fillEntity;
        const urlsList = [{ id: 0, name: window.captions.NotChosed }];
        var types = sources.urlTypes;
        for (let key in items) {
            const field = items[key];
            const type = types.find((i) => i.id === field.type);
            const fields = entityFields.find((i) => i.entityId === field.id && i.source === 17);
            const viewFields = [];
            if (fields) {
                for (let tmp in fields.fields) {
                    const customField = fields.fields[tmp];
                    if (customField.stringValue.length <= 0)
                        continue;
                    viewFields.push(`${customField.name}: ${customField.stringValue}`);
                }
            }
            urlsList.push({ id: field.id, name: type ? `${field.url} (${type.name}) ${viewFields}` : field.url });
        }

        return urlsList;
    }

    function getMoodIndicators() {
        const renderItems = (element, value) => {
            var mood = value.dataItem;
            const render = <span key="attachmentData" className="dm-wrapper">
                <svg xmlns="http://www.w3.org/2000/svg">
                    <g fill={mood.color}>
                        <path d={getMoodsPath(mood.image)} />
                    </g>
                </svg>
                <div className="dm-wrapper-content">
                    {mood.name}<br />{mood.description}
                </div>
            </span>;
            return React.cloneElement(element, { ...element.props }, render);
        };

        const renderValue = (element, value) => {
            const render = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 35" style={{
                width: "24px",
                height: "24px"
            }}>
                {value && <g fill={value.color}>
                    <path d={getMoodsPath(value.image)} />
                </g>}
            </svg>;
            return React.cloneElement(element, { ...element.props }, render);
        };

        return <DropDownList style={{ width: "65px" }}
            className="moodIndicator"
            popupSettings={{ className: "moodIndicatorDrop", width: 300 }}
            data={storage.moods}
            textField="name"
            dataItemKey="id"
            itemRender={renderItems}
            valueRender={renderValue}
            onChange={changedMoodId} />;
    }

    function getEventList(typeId, events) {
        if (!events)
            return [];

        const controls = [];
        for (let key in events) {
            const item = events[key];
            if (!item || !item.fields)
                continue;

            controls.push(<div className="dm-wrapper" key={`HistoryEventFields${item.id}`}>
                <div className="dm-container eventContainer">
                    <FieldsControl id={item.id}
                        entity={"historyEvent"}
                        fields={item.fields}
                        sources={sources}
                        isEditMode={true}
                        setFields={(fields, eventId) => setEventFields(fields,
                            eventId,
                            typeId)} />
                </div>
                <Button className="eventClose"
                    iconClass="dm-i dm-i-close_big"
                    onClick={() => cancelEvent(typeId, item.id)} />
            </div>);
        }

        return controls;
    }

    function getRangeDate(isNext, nextValue, nextDefault) {
        return addDays(new Date(),
            isNext
                ? nextValue
                : nextDefault);
    }

    function getSkeleton() {
        return <div style={{ padding: "0 16px" }}>
            <Skeleton shape={"text"} style={{ width: "80px", margin: "10px 0" }} />
            <Skeleton shape={"rectangle"} style={{ width: "100%", height: "40px" }} />
            <Skeleton shape={"text"} style={{ width: "25px", margin: "20px 0 10px 0" }} />
            <Skeleton shape={"rectangle"} style={{ width: "100%", height: "40px" }} />
            <Skeleton shape={"text"} style={{ width: "110px", margin: "20px 0 10px 0" }} />
            <Skeleton shape={"rectangle"} style={{ width: "225px", height: "40px" }} />
            <Skeleton shape={"text"} style={{ width: "110px", margin: "20px 0 10px 0" }} />
            <Skeleton shape={"rectangle"} style={{ width: "100%", height: "60px" }} />
        </div>;
    }

    function getSkeletonButtons() {
        return <div>
            <Skeleton shape={"rectangle"} style={{
                width: "47%",
                height: "40px",
                margin: "0 10px 0 5px",
                display: "inline-block"
            }} />
            <Skeleton shape={"rectangle"} style={{
                width: "47%",
                height: "40px",
                margin: "0 5px 0 0",
                display: "inline-block"
            }} />
        </div>;
    }
}