import React from "react";
import { Button } from "@progress/kendo-react-buttons";
import TextControl from "../../../search/controls/textControl.jsx";
import FinancialControl from "../../../search/controls/financialControl.jsx";
import NumberControl from "../../../search/controls/numberControl.jsx";
import DateControl from "../../../search/controls/dateControl.jsx";
import DateTimeControl from "../../../search/controls/dateTimeControl.jsx";
import TimeControl from "../../../search/controls/timeControl.jsx";
import BoolControl from "../../../search/controls/boolControl.jsx";
import DropDownCheckedTree from "../../../../components/controls/dropDownCheckedTree.jsx";
import { filterFieldTypes, entitySourcesNames, getContainerStyle } from "../../../../utils/systemConstants.jsx";
import Reducer from "./sharedUiReducerFunctions.jsx";

export default {
    renderInput(filters, field, filter, entity, isReadonly, onEdit) {
        const value = filter ? filter.values : "";
        const comparer = filter ? filter.compareType : field.defaultComparer;
        const children = filter && filter.children ? filter.children : [];
        const canContainsPeriodFilter = entity === entitySourcesNames.collateral && field.fieldAlias === "State";
        const periodFilterValue = filter && filter.periodFilter ? filter.periodFilter.values : "";
        switch (field.type) {
            case filterFieldTypes.text:
                return <TextControl
                    disabled={isReadonly}
                    field={field}
                    value={value}
                    comparer={comparer}
                    comparerSelected={(e) => Reducer.changeFieldComparer(filters, e.fieldId, e.value)}
                    changedValue={(e) => Reducer.changeFieldValue(filters, e.field, e.value, onEdit)} />;
            case filterFieldTypes.bool:
                return <BoolControl
                    disabled={isReadonly}
                    field={field}
                    value={value}
                    changedValue={(e) => Reducer.changeFieldValue(filters, e.field, e.value, onEdit)} />;
            case filterFieldTypes.number:
            case filterFieldTypes.intNumber:
                return <NumberControl
                    disabled={isReadonly}
                    field={field}
                    value={value}
                    changedValueFrom={(e) => Reducer.changeFieldValueFrom(filters, e.field, e.value, onEdit)}
                    changedValueTo={(e) => Reducer.changeFieldValueTo(filters, e.field, e.value, onEdit)}
                    changedWaterMarkValue={(e) => Reducer.changeFieldWaterMarkValue(filters, e.field, e.value, onEdit)} />;
            case filterFieldTypes.financial:
                return <FinancialControl
                    disabled={isReadonly}
                    field={field}
                    value={value}
                    changedValueFrom={(e) => Reducer.changeFieldValueFrom(filters, e.field, e.value, onEdit)}
                    changedValueTo={(e) => Reducer.changeFieldValueTo(filters, e.field, e.value, onEdit)} />;
            case filterFieldTypes.dropArray:
                return canContainsPeriodFilter
                    ? <div className="dm-wrapper-content">
                        <div className="dm-label-padding">
                            <DropDownCheckedTree
                                disabled={isReadonly}
                                id={`DropArray${field.id}`}
                                field={field.id}
                                treeData={field.items}
                                selectionChanged={(e) => Reducer.changeFieldChildren(filters, e.field, e.value, onEdit)}
                                selectedIdList={children} />
                        </div>
                        <div className="dm-label-padding">
                            <DateControl
                                disabled={isReadonly}
                                field={field}
                                value={periodFilterValue}
                                changedValueFrom={(e) => Reducer.changePeriodFilterValueFrom(filters, e.field, e.value, onEdit)}
                                changedValueTo={(e) => Reducer.changePeriodFilterValueTo(filters, e.field, e.value, onEdit)} />
                        </div>
                    </div>
                    : <DropDownCheckedTree
                        id={`DropArray${field.id}`}
                        disabled={isReadonly}
                        field={field.id}
                        treeData={field.items}
                        className="dm-wrapper-content"
                        selectionChanged={(e) => Reducer.changeFieldChildren(filters, e.fieldId, e.value, onEdit)}
                        selectedIdList={children} />;
            case filterFieldTypes.date:
                return <DateControl
                    disabled={isReadonly}
                    field={field}
                    value={value}
                    changedValueFrom={(e) => Reducer.changeFieldValueFrom(filters, e.field, e.value, onEdit)}
                    changedValueTo={(e) => Reducer.changeFieldValueTo(filters, e.field, e.value, onEdit)} />;
            case filterFieldTypes.time:
                return <TimeControl
                    disabled={isReadonly}
                    field={field}
                    value={value}
                    changedValueFrom={(e) => Reducer.changeFieldValueFrom(filters, e.field, e.value, onEdit)}
                    changedValueTo={(e) => Reducer.changeFieldValueTo(filters, e.field, e.value, onEdit)} />;
            case filterFieldTypes.dateTime:
                return <DateTimeControl
                    disabled={isReadonly}
                    field={field}
                    value={value}
                    changedValueFrom={(e) => Reducer.changeFieldValueFrom(filters, e.field, e.value, onEdit)}
                    changedValueTo={(e) => Reducer.changeFieldValueTo(filters, e.field, e.value, onEdit)} />;
            default:
                return <div />;
        }
    },

    mapField(filters, field, i, selected, onEdit, entity, isReadonly) {
        const filter = selected.find((e) => e.id === field.id);
        const hasFilter = filter &&
            (filter.values !== field.defaultValue || (filter.children && filter.children.length > 0));
        const editor = this.renderInput(filters, field, filter, entity, isReadonly, onEdit);
        const clear = hasFilter && !isReadonly
            ? <Button icon="refresh" onClick={() => Reducer.clear(filters, field.id, onEdit)} />
            : null;
        const wrapperClass = field.type === filterFieldTypes.dateTime ||
            field.type === filterFieldTypes.date ||
            field.type === filterFieldTypes.time
            ? "dm-wrapper dm-allow-wrap"
            : "dm-wrapper";
        return [
            <div key={i + "name"} className="dm-label" title={field.name}>
                <div>{field.name}</div>
            </div>,
            <div key={i + "input"} className="dm-card-editor">
                <div className={wrapperClass}>{editor}{clear}</div>
            </div>
        ];
    },

    renderForm(filters, onEdit, entity, isReadonly) {
        var sizeMode = { size: 3, editor: 3, inlineDialog: true }
        const selected = filters.selectedFilters;
        var containerStyle = getContainerStyle(sizeMode) + " dm-filter-group";
        var groups = [];

        filters.filterGroups.map((item, g) => groups.push({
            title: item.name,
            key: g,
            content: <div className={`k-m-3 ${containerStyle}`}>
                {item.items.map(
                    (field, i) => this.mapField(filters,
                        field,
                        i,
                        selected,
                        onEdit,
                        entity,
                        isReadonly))}
            </div>
        }));
        return groups;
    }
};