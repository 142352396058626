import React, { useState } from 'react';
import DropDownTreeView from "../../../../components/controls/dropDownTreeView.jsx";
import { renderPair } from "../../../cards/cardRenderFunctions.jsx";
import { Checkbox } from "@progress/kendo-react-inputs";

export function AttachFileType({ template, types, onEdit }) {
    const [currentTemplate, setCurrentTemplate] = useState(0);

    if (currentTemplate.id !== template.id) {
        setCurrentTemplate(template)
        return;
    }

    const documentTypeHandler = (item) => {
        var template = currentTemplate;
        template.documentType = item.value;
        setCurrentTemplate(template);
        onEdit(template);
    };

    const attachToLoanHandler = (item) => {
        var template = currentTemplate;
        template.attachToLoan = item.value;
        if (!item.value)
            template.documentType = 0;

        setCurrentTemplate(template);
        onEdit(template);
    };
    var content = [];

    if ("attachToLoan" in template) {
        renderPair(content,
            "",
            "AttachToLoan",
            () => <Checkbox key="attachToLoan" value={currentTemplate.attachToLoan}
                onChange={attachToLoanHandler}
                label={window.captions.AttachCopyToLoan} />,
            true);

        var items = [{
            id: 0,
            name: window.captions.SelectDocumentType,
            fullName: window.captions.SelectDocumentType,
            hasItems: false
        }, ...types];
        if (currentTemplate.attachToLoan)
            renderPair(content,
                window.captions.MacrossAttachmentDocumentType,
                `AttachDocumentType_${currentTemplate.id}`,
                () => <DropDownTreeView key="attachDocumentType"
                    treeData={items}
                    selectedId={currentTemplate.documentType}
                    placeholder={window.captions.SelectDocumentType}
                    adaptiveTitle={window.captions.SelectDocumentType}
                    selectionChanged={documentTypeHandler}
                    style={{ width: "100%" }} />
            );
    }

    return content
}
